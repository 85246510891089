import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

const eligiblePaymentEndpoints = environment.managerApi.endpoints.sale.eligibles.payment

export async function patch(saleId, data) {
  return EffectUtility.jsonPatch(eligiblePaymentEndpoints.update.replace(':saleId', saleId), data)
}

export async function get(saleId) {
  return EffectUtility.get(eligiblePaymentEndpoints.get.replace(':saleId', saleId))
}
