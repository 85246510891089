import NotificationEnum from '../../../constants/NotificationEnum'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './SaleEligiblesEffects'

export const REQUEST_GET_PARTICIPANTS = 'SaleEligibles.REQUEST_GET_PARTICIPANTS'
export const REQUEST_GET_PARTICIPANTS_FINISHED = 'SaleEligibles.REQUEST_GET_PARTICIPANTS_FINISHED'
export const REQUEST_GET_PARTICIPATION_AUTHORIZATION = 'SaleEligibles.REQUEST_GET_PARTICIPATION_AUTHORIZATION'
export const REQUEST_GET_PARTICIPATION_AUTHORIZATION_FINISHED = 'SaleEligibles.REQUEST_GET_PARTICIPATION_AUTHORIZATION_FINISHED'
export const REQUEST_UPDATE_PARTICIPANTS = 'SaleEligibles.REQUEST_UPDATE_PARTICIPANTS'
export const REQUEST_UPDATE_PARTICIPANTS_FINISHED = 'SaleEligibles.REQUEST_UPDATE_PARTICIPANTS_FINISHED'
export const REQUEST_GET_PIN_CODE = 'SaleEligibles.REQUEST_GET_PIN_CODE'
export const REQUEST_GET_PIN_CODE_FINISHED = 'SaleEligibles.REQUEST_GET_PIN_CODE_FINISHED'
export const REQUEST_UPDATE_PIN_CODE = 'SaleEligibles.REQUEST_UPDATE_PIN_CODE'
export const REQUEST_UPDATE_PIN_CODE_FINISHED = 'SaleEligibles.REQUEST_UPDATE_PIN_CODE_FINISHED'
export const REQUEST_UPDATE_PARTICIPATION_AUTHORIZATION = 'SaleEligibles.REQUEST_UPDATE_PARTICIPATION_AUTHORIZATION'
export const REQUEST_UPDATE_PARTICIPATION_AUTHORIZATIONFINISHED = 'SaleEligibles.REQUEST_UPDATE_PARTICIPATION_AUTHORIZATIONFINISHED'

export const SET_CURRENT = 'SaleEligibles.SET_CURRENT'

export function getParticipantSegments(saleId) {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_PARTICIPANTS, Effects.getParticipantSegments, saleId)
  }
}

export function updateParticipantSegments(saleId, data) {
  return async dispatch => {
    return await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE_PARTICIPANTS,
      Effects.updateParticipantSegments,
      {
        notification: {
          description: null,
          message: 'Participantes atualizados com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      data
    )
  }
}

export function getPinCode(saleId) {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_PIN_CODE, Effects.getPinCode, saleId)
  }
}

export function updatePinCode(saleId, data) {
  return async dispatch => {
    return await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE_PIN_CODE,
      Effects.updatePinCode,
      {
        notification: {
          description: null,
          message: 'Eligíbilidade de Pin Code atualizado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      data
    )
  }
}

export function getParticipationAuthorization(saleId) {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_PARTICIPATION_AUTHORIZATION, Effects.getParticipationAuthorization, saleId)
  }
}

export function updateParticipationAuthorization(saleId, data) {
  return async dispatch => {
    return await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE_PARTICIPATION_AUTHORIZATION,
      Effects.updateParticipationAuthorization,
      {
        notification: {
          description: null,
          message: 'Eligíbilidade de nível ou categoria atualizada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      data
    )
  }
}

export function setCurrent(item) {
  return ActionUtility.createAction(SET_CURRENT, item)
}
