import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'
import * as HttpUtility from '../../../utilities/HttpUtility'

export async function get(saleId) {
  return EffectUtility.get(environment.managerApi.endpoints.sale.basicInfo.replace(':saleId', `${saleId}`))
}

export async function update(saleId, data) {
  return HttpUtility.put(environment.managerApi.endpoints.sale.basicInfo.replace(':saleId', `${saleId}`), data)
}

export async function create(data) {
  return EffectUtility.post(environment.managerApi.endpoints.sale.create, data)
}
