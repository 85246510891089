import * as NotificationActions from '../stores/notification/NotificationActions'

const notificationMiddleware = () => store => next => action => {
  if (!action.error && action.meta?.notification) {
    const { description, message, type } = action.meta.notification

    next(NotificationActions.add(description, message, type))
  }

  next(action)
}

export default notificationMiddleware
