import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

const endpoint = environment.managerApi.endpoints.sale.advertisement

export const getIdentificationInfo = async () => {
  return await EffectUtility.get(endpoint.identificationInfo)
}

export async function get(saleId) {
  return EffectUtility.get(endpoint.get.replace(':saleId', `${saleId}`))
}

export async function updateSaleAdvertisement(saleId, data) {
  return EffectUtility.put(endpoint.update.replace(':saleId', `${saleId}`), data)
}

export async function uploadMultipleImages(saleId, images) {
  // const formData = new FormData()
  // formData.append('files', images)
  return EffectUtility.post(endpoint.multipleUpload.replace(':saleId', `${saleId}`), images)
}
