import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './PromotionalCodeEffects'
import NotificationEnum from '../../constants/NotificationEnum'
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel'

export const REQUEST_IDENTIFICATION_INFO = 'PromotionalCode.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'PromotionalCode.REQUEST_IDENTIFICATION_INFO_FINISHED'
export const REQUEST_GET = 'PromotionalCode.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'PromotionalCode.REQUEST_GET_FINISHED'
export const REQUEST_GET_DETAILS = 'PromotionalCode.REQUEST_GET_DETAILS'
export const REQUEST_GET_DETAILS_FINISHED = 'PromotionalCode.REQUEST_GET_DETAILS_FINISHED'
export const REQUEST_ADD = 'PromotionalCode.REQUEST_ADD'
export const REQUEST_ADD_FINISHED = 'PromotionalCode.REQUEST_ADD_FINISHED'
export const REQUEST_UPDATE = 'PromotionalCode.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'PromotionalCode.REQUEST_UPDATE_FINISHED'
export const REQUEST_UPDATE_STATUS = 'PromotionalCode.REQUEST_UPDATE_STATUS'
export const REQUEST_UPDATE_STATUS_FINISHED = 'PromotionalCode.REQUEST_UPDATE_STATUS_FINISHED'
export const SET_CURRENT = 'PromotionalCode.SET_CURRENT'
export const REQUEST_IMPORT = 'PromotionalCode.REQUEST_IMPORT'
export const REQUEST_IMPORT_FINISHED = 'PromotionalCode.REQUEST_IMPORT_FINISHED'

export function identificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.identificationInfo)
  }
}

export function get() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get)
  }
}

export function getDetails(baseId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_DETAILS, Effects.getDetails, baseId)
  }
}

export function add(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_ADD, Effects.add, data)
  }
}

export function update(baseId, data) {
  return async (dispatch, getState) => {
    const isStatusChanged = getState().promotionalCode.currentPromotionalCode.status !== data.status
    if (isStatusChanged) await ActionUtility.createThunkEffect(dispatch, REQUEST_UPDATE_STATUS, Effects.updateStatus, baseId, data.status)
    await ActionUtility.createThunkEffect(dispatch, REQUEST_UPDATE, Effects.update, baseId, data)
  }
}

export function updateStatus(baseId, statusId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_UPDATE_STATUS, Effects.updateStatus, baseId, statusId)
  }
}

export function importPromotionalCodes(baseId, file) {
  return async (dispatch, getState) => {
    dispatch(ActionUtility.createAction(REQUEST_IMPORT))

    const model = await Effects.importPromotionalCodes(baseId, file)
    const isError = model instanceof HttpErrorResponseModel

    dispatch(
      ActionUtility.createAction(REQUEST_IMPORT_FINISHED, model, isError, {
        notification: {
          description: null,
          message: isError ? model.message : 'Arquivo importado com sucesso.',
          type: NotificationEnum.Success,
        },
      })
    )

    return model
  }
}

export function setCurrent(id) {
  return ActionUtility.createAction(SET_CURRENT, id)
}

export function setLimitTransactionsEvenWithoutBenefit(limitTransactionsEvenWithoutBenefit) {
  return ActionUtility.createAction(SET_LIMIT_TRANSACTIONS_EVEN_WITHOUT_BENEFIT, limitTransactionsEvenWithoutBenefit)
}
