import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

const importEndpoints = environment.managerApi.endpoints.product.import

export const importProduct = async file => {
  const formData = new FormData()

  formData.append('file', file)
  await EffectUtility.postFormData(importEndpoints.initializeImport, formData)
}

export const search = async (filter, pagination) => await EffectUtility.post(importEndpoints.search, { filter, pagination })
