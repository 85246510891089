import * as ActionUtility from '../../../utilities/ActionUtility'
import NotificationEnum from '../../../constants/NotificationEnum'
import * as Effects from './SaleAttributesEffects'
import { Dispatch } from 'react'
import { SaleAttribute } from '../../../models/sale-attributes/sale-attributes'

export const REQUEST_GET = 'SaleEligiblesAttributes.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'SaleEligiblesAttributes.REQUEST_GET_FINISHED'
export const REQUEST_PUT = 'SaleEligiblesAttributes.REQUEST_PUT'
export const REQUEST_PUT_FINISHED = 'SaleEligiblesAttributes.REQUEST_PUT_FINISHED'
export const UPDATE_STATE = 'SaleEligiblesAttributes.UPDATE_STATE'
export const CLEAN = 'SaleEligiblesAttributes.CLEAN'

export const clean = () => ActionUtility.createAction(CLEAN)

export const get = (saleId: string) => async (dispatch: any, getState: any) => {
  await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get, saleId)
}
export const put = (saleId: string, data?: SaleAttribute) => async (dispatch: any, getState: any) => {
  await ActionUtility.createThunkEffect(dispatch, REQUEST_PUT, Effects.update, saleId, data || getState().sale.attributes)
}

export const updateState = (data: SaleAttribute) => {
  return ActionUtility.createAction(UPDATE_STATE, data)
}
