import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

const participationAuthorizationEndpoints = environment.managerApi.endpoints.participationAuthorization

export async function create(data) {
  return EffectUtility.post(participationAuthorizationEndpoints.add, data)
}

export async function update(id, data) {
  return EffectUtility.put(participationAuthorizationEndpoints.update.replace(':id', id), data)
}

export async function search(filter, pagination) {
  return EffectUtility.post(participationAuthorizationEndpoints.search, { filter: { ...filter }, pagination: { ...pagination } })
}
