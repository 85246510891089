/* eslint-disable */
export const validateMessages = {
  required: '${label} é obrigatório.',
  string: {
    len: '${label} deve ter exatamente ${len} caracteres',
    min: '${label} deve conter no mínimo ${min} caracteres',
    max: '${label} não pode ser maior que ${max} caracteres',
    range: '${label} deve ter entre ${min} e ${max} caracteres',
  },
}
