import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './CategoryActions'

export const initialState = {
  search: {
    request: {
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
      filter: {
        name: null,
        active: null,
      },
    },
    response: {
      numberOfRows: 0,
      data: [],
    },
  },
  searchByName: {
    response: [],
  },
}

const importReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.search.response = action.payload
    })
  },
  [Actions.SET_PAGINATION](state, action) {
    return produce(state, draftState => {
      draftState.search.request.pagination = action.payload
    })
  },
  [Actions.CLEAR_PAGINATION](state) {
    return produce(state, draftState => {
      draftState.search.request.pagination = initialState.search.request.pagination
    })
  },
  [Actions.SET_FILTER](state, action) {
    return produce(state, draftState => {
      draftState.search.request.filter = action.payload
    })
  },
  [Actions.CLEAR_FILTER](state) {
    return produce(state, draftState => {
      draftState.search.request.filter = initialState.search.request.filter
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
  [Actions.REQUEST_SEARCH_BY_NAME_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.searchByName.response = action.payload
    })
  },
})

export default importReducer
