import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

export async function identificationInfo() {
  return EffectUtility.get(environment.managerApi.endpoints.participationLimit.identificationInfo)
}

export async function post(data) {
  return EffectUtility.post(environment.managerApi.endpoints.participationLimit.post, data)
}

export async function get(id) {
  return EffectUtility.get(environment.managerApi.endpoints.participationLimit.get.replace(':userId', id))
}

export async function put(id, data) {
  return EffectUtility.put(environment.managerApi.endpoints.participationLimit.put.replace(':userId', id), data)
}
