import produce from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleFeedbackMessagesActions'

export const initialState = {
  custom: null,
  success: {
    display: null,
    invoice: null,
  },
  failure: {
    display: null,
    invoice: null,
  },
  fallback: null,
}

const saleFeedbackMessagesReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    return payload || initialState
  },
  [Actions.SET](state, { payload }) {
    return produce(state, draft => {
      draft.success = payload.success
      draft.failure = payload.failure
      draft.custom = payload.custom

      if (
        payload.fallback &&
        payload.fallback.display &&
        payload.fallback.invoice &&
        payload.fallback.display.trim() !== '' &&
        payload.fallback.invoice.trim() !== ''
      )
        draft.fallback = payload.fallback
      else draft.fallback = null
    })
  },
})

export default saleFeedbackMessagesReducer
