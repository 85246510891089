import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './PayingActions'

export const initialState = {
  identificationInfo: [],
}

const payingReducer = baseReducer(initialState, {
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload
    })
  },
})

export default payingReducer
