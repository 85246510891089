import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

export async function search(filter) {
  return EffectUtility.post(environment.managerApi.endpoints.sale.search, filter)
}

export async function identificationInfo() {
  return EffectUtility.get(environment.managerApi.endpoints.sale.identificationInfo)
}
