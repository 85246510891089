import { Select, Spin } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Selectors from '../../../selectors/company/ChainSelectors'
import { selectRequesting } from '../../../selectors/requesting/RequestingSelector'
import * as Actions from '../../../stores/company/chain/ChainActions'
import { defaultDebounce } from '../../../utilities/DebounceUtility'

const ChainSelect = ({ initialChainId, ...props }) => {
  const dispatch = useDispatch()
  const options = useSelector(Selectors.chainSelect)
  const isRequesting = useSelector(state => selectRequesting(state, [Actions.REQUEST_IDENTIFICATION_INFO]))

  const searchChains = value => {
    if (value && value.length >= 4) {
      dispatch(Actions.identificationInfo(value))
    }
  }

  useEffect(
    _ => {
      if (initialChainId) {
        dispatch(Actions.identificationInfo(initialChainId))
      }
    },
    [dispatch, initialChainId]
  )

  return (
    <Spin spinning={Boolean(initialChainId && isRequesting)}>
      <Select
        showSearch
        allowClear
        notFoundContent={isRequesting ? <Spin size="small" /> : null}
        filterOption={false}
        options={options}
        loading={isRequesting}
        onSearch={defaultDebounce(searchChains)}
        {...props}
      />
    </Spin>
  )
}

ChainSelect.propTypes = {
  placeholder: PropTypes.string,
  initialChainId: PropTypes.string,
}

export default ChainSelect
