import environment from 'environment'
import * as EffectUtility from '../../../../utilities/EffectUtility'

const importEndpoints = environment.managerApi.endpoints.company.subCategory.import

export const search = async ({ payload, categoryId }) => await EffectUtility.post(importEndpoints.search.replace(':categoryId', categoryId), payload)

export const importEffect = async ({ file, categoryId }) => {
  const formData = new FormData()
  formData.append('file', file)
  return await EffectUtility.postFormData(importEndpoints.import.replace(':categoryId', categoryId), formData)
}
