import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './ForbiddenWordsEffects'

export const REQUEST_SEARCH = 'ForbiddenWords.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'ForbiddenWords.REQUEST_SEARCH_FINISHED'
export const REQUEST_DISABLE = 'ForbiddenWords.REQUEST_DISABLE'
export const REQUEST_DISABLE_FINISHED = 'ForbiddenWords.REQUEST_DISABLE_FINISHED'
export const REQUEST_ENABLE = 'ForbiddenWords.REQUEST_ENABLE'
export const REQUEST_ENABLE_FINISHED = 'ForbiddenWords.REQUEST_ENABLE_FINISHED'
export const REQUEST_EDIT = 'ForbiddenWords.REQUEST_EDIT'
export const REQUEST_EDIT_FINISHED = 'ForbiddenWords.REQUEST_EDIT_FINISHED'
export const REQUEST_CREATE = 'ForbiddenWords.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'ForbiddenWords.REQUEST_CREATE_FINISHED'
export const REQUEST_GETBYID = 'ForbiddenWords.REQUEST_GETBYID'
export const REQUEST_GETBYID_FINISHED = 'ForbiddenWords.REQUEST_GETBYID_FINISHED'

export const REQUEST_IMPORT = 'ForbiddenWords.REQUEST_IMPORT'
export const REQUEST_IMPORT_FINISHED = 'ForbiddenWords.REQUEST_IMPORT_FINISHED'

export const CLEAN_CURRENT_WORD = 'ForbiddenWords.CLEAN_CURRENT_WORD'

export const SET_PAGINATION = 'ForbiddenWords.SET_PAGINATION'

export function search() {
  return async (dispatch, getState) => {
    const {
      search: { request },
    } = getState().forbiddenWords

    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH, Effects.searchEffect, request)
  }
}

export function enable(id) {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_ENABLE,
      Effects.enableEffect,
      {
        notification: {
          description: null,
          message: 'Palavra habilitada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      id
    )
  }
}

export function disable(id) {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_DISABLE,
      Effects.disableEffect,
      {
        notification: {
          description: null,
          message: 'Palavra desabilitada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      id
    )
  }
}

export function update(id, body) {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_EDIT,
      Effects.editEffect,
      {
        notification: {
          description: null,
          message: 'Palavra atualizada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      { id, body }
    )
  }
}

export function create(body) {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      Effects.createEffect,
      {
        notification: {
          description: null,
          message: 'Palavra adicionada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      body
    )
  }
}

export function getById(id) {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GETBYID, Effects.getByIdEffect, id)
  }
}

export function importForbiddenWords(file) {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_IMPORT,
      Effects.importEffect,
      {
        notification: {
          description: null,
          message: 'Arquivo importado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      file
    )
  }
}

export function cleanCurrentWord() {
  return ActionUtility.createAction(CLEAN_CURRENT_WORD)
}

export function setPagination(pagination) {
  return ActionUtility.createAction(SET_PAGINATION, pagination)
}
