import { protectedResources } from '../contexts/AuthConfig'
import { msalInstance } from '../providers/AppProviders'

const getToken = async () => {
  const accounts = msalInstance.getAllAccounts()

  const request = {
    scopes: protectedResources.scopes,
    account: accounts[accounts.length - 1],
  }

  const authResult = await msalInstance.acquireTokenSilent(request)

  return authResult.accessToken
}

export { getToken }
