import * as Effects from './SaleBudgetEffects'
import { ActionUtility } from '@aberto/react-common'
import NotificationEnum from '../../../constants/NotificationEnum'
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel'
import * as SaleBasicInfoEffects from './../basic-info/SaleBasicInfoEffects'

export const REQUEST_IDENTIFICATION_INFO = 'SaleBudget.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'SaleBudget.REQUEST_IDENTIFICATION_INFO_FINISHED'
export const REQUEST_UPDATE = 'SaleBudget.REQUEST_UPDATE'

export function getBudgetIdentificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.getIdentificationInfo)
  }
}

export function update(saleId, delimitations) {
  return async (dispatch, getState) => {
    let { basicInfo } = getState().sale

    basicInfo.delimitations = { ...delimitations }

    const model = await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      SaleBasicInfoEffects.update,
      {
        notification: {
          description: null,
          message: 'Orçamento atualizado com sucesso',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      basicInfo
    )

    const isError = model instanceof HttpErrorResponseModel
  }
}
