import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './CompanyActions'

const initialState = {
  search: {
    request: {
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
      filter: {
        id: null,
        name: null,
        externalCode: null,
        types: [],
        status: null,
        createdDate: {
          start: null,
          end: null,
        },
      },
    },
    response: {
      numberOfRows: 0,
      data: [],
    },
  },
  currentCompany: {
    id: null,
    name: null,
    externalCode: null,
    types: [],
    status: 0,
    website: null,
    address: {},
    images: [],
    description: null,
    category: null,
    categoryInfo: {},
    managerInfo: {},
    benefitChannels: [],
    attributes: [],
    availableStatus: [],
    createdDate: null,
  },
}

const companyReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_BY_ID_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.currentCompany = action.payload
    })
  },
  [Actions.REQUEST_SEARCH_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.search.response = action.payload
    })
  },
  [Actions.SET_PAGINATION](state, action) {
    return produce(state, draftState => {
      draftState.search.request.pagination = action.payload
    })
  },
  [Actions.CLEAR_PAGINATION](state) {
    return produce(state, draftState => {
      draftState.search.request.pagination = initialState.search.request.pagination
    })
  },
  [Actions.SET_FILTER](state, action) {
    return produce(state, draftState => {
      draftState.search.request.filter = action.payload
    })
  },
  [Actions.CLEAR_FILTER](state) {
    return produce(state, draftState => {
      draftState.search.request.filter = initialState.search.request.filter
    })
  },
  [Actions.CLEAR_CURRENT_COMPANY](state) {
    return produce(state, draftState => {
      draftState.currentCompany = initialState.currentCompany
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
})

export default companyReducer
