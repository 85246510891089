import produce from 'immer'
import * as Actions from './SaleBudgetActions'
import { BaseReducer } from '@aberto/react-common'

export const initialState = {
  identificationInfo: [
    {
      id: '',
      description: '',
    },
  ],
}

const budgetReducer = BaseReducer(initialState, {
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state, payload) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload
    })
  },
})

export default budgetReducer
