import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

const notificationEndpoints = environment.managerApi.endpoints.notification
const saleNotificationEndpoints = environment.managerApi.endpoints.sale.notification

export async function getNotification(id) {
  return EffectUtility.get(saleNotificationEndpoints.get.replace(':saleId', id))
}

export async function updateNotification(id, data) {
  return EffectUtility.put(saleNotificationEndpoints.update.replace(':saleId', id), data)
}

export async function getNotificationTemplate(type) {
  return EffectUtility.get(notificationEndpoints.getTemplate.replace(':notificationType', type))
}

export async function getNotificationProvider(type) {
  return EffectUtility.get(notificationEndpoints.getProvider.replace(':notificationType', type))
}

export async function getTemplateById(id) {
  return EffectUtility.get(notificationEndpoints.getTemplateById.replace(':templateId', id))
}
