import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

export const searchEffect = async payload => await EffectUtility.post(environment.managerApi.endpoints.company.importSearch, payload)
export const getByIdEffect = async importId =>
  await EffectUtility.get(environment.managerApi.endpoints.company.getImportById.replace(':importId', importId))

export const importEffect = async (file, companyStatus) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('companyStatus', companyStatus)
  return await EffectUtility.postFormData(environment.managerApi.endpoints.company.import, formData)
}
