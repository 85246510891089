import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

export async function search(saleId, filter) {
  return EffectUtility.post(environment.managerApi.endpoints.sale.store.search.replace(':saleId', `${saleId}`), filter)
}

export async function add(saleId, data) {
  return EffectUtility.post(environment.managerApi.endpoints.sale.store.add.replace(':saleId', `${saleId}`), data)
}

export async function vinculateFromFilter(saleId, chainId, data) {
  return EffectUtility.post(
    environment.managerApi.endpoints.sale.store.vinculateFromFilter.replace(':saleId', `${saleId}`).replace(':chainId', `${chainId}`),
    data
  )
}

export async function remove(saleId, chainId, stores) {
  let endpoint = environment.managerApi.endpoints.sale.store.delete.replace(':saleId', `${saleId}`)
  endpoint = `${endpoint}?chainId=${chainId}&${stores.map(store => `storeIds=${store}`).join('&')}`

  return EffectUtility.del(endpoint)
}
