import { registerApplication, start } from 'single-spa'

import { Constants } from '@aberto/react-common'
import { getToken } from './utilities/AuthUtility'

const startMFA = (fronts, createSubMenuByMenuName, onMfeLoaded) => {
  fronts.forEach(front => {
    const getAccessToken = async () => await getToken()

    registerApplication({
      name: front.name,
      app: () => System.import(front.url),
      activeWhen: ['/', `/${front.basename}`],
      customProps: {
        createSubMenuByMenuName,
        type: Constants.abertoType,
        frontEndProps: front,
        onMfeLoaded,
        getAccessToken,
      },
    })
  })

  start({
    urlRerouteOnly: true,
  })
}

export default startMFA
