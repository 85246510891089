import { produce } from 'immer'
import baseReducer from '../../../../utilities/BaseReducer'
import * as Actions from './StampActions'

export const initialState = {
  promotionalCodeBaseId: null,
  productsPrice: [],
  redemptionBehavior: null,
}

const stampReducer = baseReducer(initialState, {
  [Actions.SET_PRODUCT_STAMP_CONDITION](state, { payload: payload }) {
    return produce(state, draft => {
      draft.productsPrice = [...payload]
    })
  },
  [Actions.SET_STAMP](state, { payload: payload }) {
    return produce(state, draft => {
      return payload
    })
  },
  [Actions.SET_STAMP_CONDITION](state, { payload: payload }) {
    return produce(state, draft => {
      draft.promotionalCodeBaseId = payload.promotionalCodeBaseId
      draft.redemptionBehavior = payload.redemptionBehavior
    })
  },
  [Actions.REMOVE_PRODUCT](state, { payload: index }) {
    return produce(state, draft => {
      const newProductsPrice = [...state.productsPrice]
      newProductsPrice.splice(index, 1)
      draft.productsPrice = newProductsPrice
    })
  },
  [Actions.CLEAR](state) {
    return produce(state, draftState => {
      return initialState
    })
  },
})

export default stampReducer
