import * as ActionUtility from '../../../utilities/ActionUtility'

export const SET_STEP = 'SaleEditor.SET_STEP'
export function setStep(step) {
  return ActionUtility.createAction(SET_STEP, { ...step })
}

export const SET_SALE_ID = 'SaleEditor.SET_SALE_ID'
export function setSaleId(id) {
  return ActionUtility.createAction(SET_SALE_ID, id)
}

export const ENABLE_ALL_STEPS = 'SaleEditor.ENABLE_ALL_STEPS'
export function enableAllSteps() {
  return ActionUtility.createAction(ENABLE_ALL_STEPS)
}

export const ENABLE_ONLY_BASIC_INFO = 'SaleEditor.ENABLE_ONLY_BASIC_INFO'
export function enableOnlyBasicInfo() {
  return ActionUtility.createAction(ENABLE_ONLY_BASIC_INFO)
}
