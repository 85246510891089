import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './WalletEffects'

export const REQUEST_IDENTIFICATION_INFO = 'Wallet.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'Wallet.REQUEST_IDENTIFICATION_INFO_FINISHED'

export function identificationInfo(features) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.identificationInfo, features)
  }
}
