import produce from 'immer'
import * as Actions from './SaleNotificationAction'
import { BaseReducer } from '@aberto/react-common'

export const initialState = {
  saleNotification: {
    templateId: '',
    providerId: '',
  },
  templateList: [
    {
      id: '',
      name: '',
      notificationType: '',
    },
  ],
  providerList: [
    {
      id: '',
      name: '',
      notificationType: '',
    },
  ],
  template: {
    id: '',
    notificationType: '',
  },
}

const SaleNotificationReducer = BaseReducer(initialState, {
  [Actions.REQUEST_GET_NOTIFICATION_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.saleNotification = payload
    })
  },
  [Actions.REQUEST_GET_NOTIFICATION_TEMPLATE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.templateList = payload
    })
  },
  [Actions.REQUEST_GET_NOTIFICATION_PROVIDER_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.providerList = payload
    })
  },
  [Actions.REQUEST_GET_TEMPLATE_BY_ID_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.template = payload
    })
  },
})

export default SaleNotificationReducer
