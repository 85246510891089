import { BaseReducer } from '@aberto/react-common'
import * as Actions from '../advertisement/SaleAdvertisementAction'
import produce from 'immer'

export const initialState = {
  identificationInfo: [
    {
      id: '',
      name: '',
    },
  ],
  advertisement: {
    types: [],
    category: '',
    publish: false,
  },
}

const advertisementReducer = BaseReducer(initialState, {
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state, payload) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload.payload
    })
  },
  [Actions.REQUEST_GET_ADVERTISEMENT_FINISHED](state, payload) {
    return produce(state, draftState => {
      draftState.advertisement = payload.payload
    })
  },
})

export default advertisementReducer
