import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './CampaignActions'

export const initialState = {
  all: [],
  identificationInfo: [],
  currentCampaign: {
    authorizerCampaignId: null,
    name: null,
    eligibles: [],
    campaignIdsToConflict: [],
    partners: [],
    activateEvenWithoutRequest: false,
    receiveWholeCart: false,
    resolveInternalSaleConflicts: false,
    hasFinanceConciliation: false,
    active: true,
    attributes: [],
  },
  search: {
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    filter: {
      name: null,
      authorizerCampaignId: undefined,
      active: true,
    },
  },
  searchResponse: {
    numberOfRows: 0,
    data: [],
  },
}

const campaignReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_IDENTIFICATION_INFO_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload
    })
  },
  [Actions.UPDATE_ATTRIBUTES](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentCampaign.attributes = payload
    })
  },
  [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.searchResponse.data = payload.data
      draftState.searchResponse.numberOfRows = payload.numberOfRows
    })
  },
  [Actions.REQUEST_GET_BY_ID_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentCampaign = payload
    })
  },
  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.filter = { ...payload, authorizerCampaignId: payload.authorizerCampaignId ?? undefined }
    })
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.pagination = payload
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
  [Actions.CLEAR_FILTER](state) {
    return produce(state, draftState => {
      draftState.search.filter = initialState.search.filter
    })
  },
})

export default campaignReducer
