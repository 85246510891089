import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

const participationAuthorizationBaseEndpoints = environment.managerApi.endpoints.participationAuthorization.base

export async function create(data) {
  return EffectUtility.post(participationAuthorizationBaseEndpoints.add, data)
}

export async function update(id, data) {
  return EffectUtility.put(participationAuthorizationBaseEndpoints.update.replace(':authorizationBaseId', id), data)
}

export async function search(filter, pagination) {
  return EffectUtility.post(participationAuthorizationBaseEndpoints.search, { filter: { ...filter }, pagination: { ...pagination } })
}

export async function identificationInfo() {
  return EffectUtility.get(participationAuthorizationBaseEndpoints.identificationInfo)
}
