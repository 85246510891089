import { CustomPageHeader, HeaderContentButtons, usePermission, SaleActivateConditionRequirements } from '@aberto/react-common'
import { Button, Layout } from 'antd'
import { push } from 'connected-react-router'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import RouteEnum from '../../../constants/RouteEnum'
import * as Actions from '../../../stores/sale/search/SaleSearchActions'
import SaleSearchFilter from './components/SaleSearchFilter'
import SaleSearchResultTable from './components/SaleSearchResultTable'

const { Content } = Layout

export default function SaleSearchPage() {
  const { permissions } = usePermission()
  const hasWritePermission = SaleActivateConditionRequirements.SaleActivateConditionWrite.isSatisfied(permissions)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.search())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(Actions.cleanResults())
    }
  }, [dispatch])

  const createSaleNew = () => {
    dispatch(push(RouteEnum.SaleNew))
  }
  return (
    <>
      <CustomPageHeader title="Buscar Ofertas" />

      <Content className="site-page-content">
        <HeaderContentButtons>
          <Button className="btn-sale-new" type="primary" onClick={() => createSaleNew()} disabled={!hasWritePermission}>
            Criar nova oferta
          </Button>
        </HeaderContentButtons>
        <SaleSearchFilter />
        <SaleSearchResultTable />
      </Content>
    </>
  )
}
