import { produce } from 'immer'

import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleStoresActions'

export const initialState = {
  selected: {
    filter: {
      storeId: null,
      chainId: null,
      merchantName: null,
      merchantCode: null,
      selected: true,
      uf: undefined,
      active: true,
      city: null,
      attributes: {
        key: null,
        value: null
      }
    },
    pagination: {
      pageSize: 15,
      pageNumber: 1,
    },
    response: {
      numberOfRows: 0,
      data: [],
    },
  },
  available: {
    filter: {
      storeId: null,
      chainId: null,
      selected: false,
      merchantName: null,
      merchantCode: null,
      uf: undefined,
      active: true,
      city: null,
      attributes: {
        key: null,
        value: null
      }
    },
    pagination: {
      pageSize: 15,
      pageNumber: 1,
    },
    response: {
      numberOfRows: 0,
      data: [],
    },
  },
}

const SaleStoreReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_SELECTED_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.selected.response = payload
    })
  },
  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draft => {
      draft.selected.filter = { ...payload, active: payload.active ?? true }
    })
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draft => {
      draft.selected.pagination = payload
    })
  },
  [Actions.REQUEST_SEARCH_AVAILABLE_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.available.response = payload
    })
  },
  [Actions.SET_AVAILABLE_FILTER](state, { payload }) {
    return produce(state, draft => {
      draft.available.filter = { ...payload, active: payload.active ?? true }
    })
  },
  [Actions.SET_AVAILABLE_PAGINATION](state, { payload }) {
    return produce(state, draft => {
      draft.available.pagination = payload
    })
  },
  [Actions.RESET_SELECTED_FILTER](state) {
    return produce(state, draft => {
      draft.selected.filter = initialState.selected.filter
      draft.selected.response = initialState.selected.response
      draft.selected.pagination = initialState.selected.pagination
    })
  },
  [Actions.RESET_AVAILABLE_FILTER](state) {
    return produce(state, draft => {
      draft.available.filter = initialState.available.filter
      draft.available.response = initialState.available.response
      draft.available.pagination = initialState.available.pagination
    })
  },
  [Actions.SET_CHAINID_FILTER](state, { payload }) {
    return produce(state, draft => {
      draft.selected.filter.chainId = payload
      draft.available.filter.chainId = payload
    })
  },
})

export default SaleStoreReducer
