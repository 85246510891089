import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

const campaignEndpoints = environment.managerApi.endpoints.campaign

export async function getIdentificationInfo() {
  return EffectUtility.get(campaignEndpoints.identificationInfo)
}

export async function getById(id) {
  return EffectUtility.get(campaignEndpoints.getById.replace(':campaignId', id))
}

export async function create(data) {
  return EffectUtility.post(campaignEndpoints.create, data)
}

export async function update(id, data) {
  return EffectUtility.put(campaignEndpoints.update.replace(':campaignId', id), data)
}

export async function search(filter, pagination) {
  return EffectUtility.post(campaignEndpoints.search, { filter: { ...filter }, pagination: { ...pagination } })
}
