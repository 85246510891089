import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import { ProductVisibility } from './models/ProductVisibility'
import * as Effects from './ProductEffects'

export const REQUEST_GET_BY_ID = 'ProductActions.REQUEST_GET_BY_ID'
export const REQUEST_GET_BY_ID_FINISHED = 'ProductActions.REQUEST_GET_BY_ID_FINISHED'

export const REQUEST_CREATE = 'ProductActions.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'ProductActions.REQUEST_CREATE_FINISHED'

export const REQUEST_UPDATE = 'ProductActions.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'ProductActions.REQUEST_UPDATE_FINISHED'

export const REQUEST_SEARCH = 'ProductActions.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'ProductActions.REQUEST_SEARCH_FINISHED'

export const SET_FILTER = 'ProductActions.SET_FILTER'
export const SET_PAGINATION = 'ProductActions.SET_PAGINATION'
export const CLEAR = 'ProductActions.CLEAR'
export const CLEAR_FILTER = 'ProductActions.CLEAR_FILTER'

const getCreateEffect = visibility => {
  if (visibility === ProductVisibility.Public) return Effects.createPublic

  return Effects.create
}

export function getById(id) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_BY_ID, Effects.getById, id)
  }
}

export function create(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      getCreateEffect(data.visibility),
      {
        notification: {
          message: 'Produto criado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      data
    )
  }
}

export function update(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effects.update,
      {
        notification: {
          message: 'Produto atualizado com sucesso',
          type: NotificationEnum.Success,
        },
      },
      getState().product.currentProduct.id,
      data
    )
  }
}

export function search() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.search,
      getState().product.search.filter,
      getState().product.search.pagination
    )
  }
}

export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)

export const clear = _ => ActionUtility.createAction(CLEAR)

export const clearFilter = _ => ActionUtility.createAction(CLEAR_FILTER)
