import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'
import * as HttpUtility from '../../../utilities/HttpUtility'

export async function get(saleId) {
  return EffectUtility.get(environment.managerApi.endpoints.sale.delimitations.get.replace(':saleId', `${saleId}`))
}

export async function patch(saleId, data) {
  return EffectUtility.jsonPatch(environment.managerApi.endpoints.sale.delimitations.patch.replace(':saleId', `${saleId}`), data)
}
