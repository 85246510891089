import NotificationEnum from '../../../constants/NotificationEnum'
import * as ActionUtility from '../../../utilities/ActionUtility'
import { importEffect, searchEffect } from './ImportEffects'

export const REQUEST_IMPORT = 'CompanyImport.REQUEST_IMPORT'
export const REQUEST_IMPORT_FINISHED = 'CompanyImport.REQUEST_IMPORT_FINISHED'

export const REQUEST_SEARCH = 'CompanyImport.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'CompanyImport.REQUEST_SEARCH_FINISHED'

export const REQUEST_APPEND_IMPORT_DETAILS_BY_ID = 'CompanyImport.REQUEST_APPEND_IMPORT_DETAILS_BY_ID'
export const REQUEST_APPEND_IMPORT_DETAILS_BY_ID_FINISHED = 'CompanyImport.REQUEST_APPEND_IMPORT_DETAILS_BY_ID_FINISHED'

export const CLEAR = 'CompanyImport.CLEAR'

export const SET_PAGINATION = 'CompanyImport.SET_PAGINATION'
export const SET_FILTER = 'CompanyImport.SET_FILTER'
export const CLEAR_FILTER = 'CompanyImport.CLEAR_FILTER'
export const CLEAR_DETAIL_IMPORT_BY_ID = 'CompanyImport.CLEAR_DETAIL_IMPORT_BY_ID'

export const importCompany = (file, companyStatus) => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_IMPORT,
      importEffect,
      {
        notification: {
          message: 'Arquivo importado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      file,
      companyStatus
    )
  }
}

export const search = () => {
  return async (dispatch, getState) => {
    const payload = getState().company.import.search.request
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH, searchEffect, payload)
  }
}

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)
export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)
export const clearFilter = () => ActionUtility.createAction(CLEAR_FILTER)
export const clear = () => ActionUtility.createAction(CLEAR)
export const clearDetailImportById = id => ActionUtility.createAction(CLEAR_DETAIL_IMPORT_BY_ID, id)
