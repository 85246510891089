import { produce } from 'immer'
import moment from 'moment-timezone'
import { dateConfig } from '../../utilities/DateUtility'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './PromotionalCodeActions'
moment.tz.setDefault(dateConfig.mommentLocalization)

export const initialState = {
  identificationInfo: [],
  promotionalCodes: [],
  currentPromotionalCode: { status: 0, expirationDate: null, type: 0, promoCodesAmount: 0 },
  currentId: null,
}

const promotionalCodeReducer = baseReducer(initialState, {
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload
    })
  },
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.promotionalCodes = payload
    })
  },
  [Actions.REQUEST_GET_DETAILS_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      if (payload.expirationDate) payload.expirationDate = moment(payload.expirationDate, dateConfig.dateFormat)
      draftState.currentPromotionalCode = payload
    })
  },
  [Actions.SET_CURRENT](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentId = payload
    })
  },
})

export default promotionalCodeReducer
