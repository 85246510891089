/* eslint-disable react/prop-types */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Actions from '../stores/sale/editor/SaleEditorActions'
import * as Selectors from '../selectors/sale/SaleEditorSelectors'

const SaleEditorContext = React.createContext()
const SaleEditorDispatchContext = React.createContext()

function SaleEditorProvider({ children }) {
  const dispatch = useDispatch()
  const { saleId, isNew, steps } = useSelector(Selectors.editor)
  const setStep = step => dispatch(Actions.setStep(step))
  const setSaleId = saleId => dispatch(Actions.setSaleId(saleId))
  const enableAllSteps = () => dispatch(Actions.enableAllSteps())
  const enableOnlyBasicInfo = () => dispatch(Actions.enableOnlyBasicInfo())

  return (
    <SaleEditorContext.Provider value={{ saleId, isNew, steps }}>
      <SaleEditorDispatchContext.Provider value={{ setStep, setSaleId, enableAllSteps, enableOnlyBasicInfo }}>
        {children}
      </SaleEditorDispatchContext.Provider>
    </SaleEditorContext.Provider>
  )
}

function useSaleEditor() {
  const context = React.useContext(SaleEditorContext)
  if (context === undefined) {
    throw new Error('useSaleEditor must be used within a SaleEditorProvider')
  }
  return context
}

function useSaleEditorDispatch() {
  const context = React.useContext(SaleEditorDispatchContext)
  if (context === undefined) {
    throw new Error('useSaleEditorDispatch must be used within a SaleEditorProvider')
  }
  return context
}

export { SaleEditorProvider, useSaleEditor, useSaleEditorDispatch }
