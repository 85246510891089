import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './ParticipationLimitEffects'
import NotificationEnum from '../../constants/NotificationEnum'

export const REQUEST_IDENTIFICATION_INFO = 'ParticipationLimit.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'ParticipationLimit.REQUEST_IDENTIFICATION_INFO_FINISHED'
export const REQUEST_GET = 'ParticipationLimit.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'ParticipationLimit.REQUEST_GET_FINISHED'
export const REQUEST_POST = 'ParticipationLimit.REQUEST_POST'
export const REQUEST_POST_FINISHED = 'ParticipationLimit.REQUEST_POST_FINISHED'
export const REQUEST_PUT = 'ParticipationLimit.REQUEST_PUT'
export const REQUEST_PUT_FINISHED = 'ParticipationLimit.REQUEST_PUT_FINISHED'
export const SET_RULE = 'ParticipationLimit.SET_RULE'
export const DELETE_RULE = 'ParticipationLimit.DELETE_RULE'
export const ADD_RULE = 'ParticipationLimit.ADD_RULE'
export const SET_DESCRIPTION = 'ParticipationLimit.SET_DESCRIPTION'
export const SET_LIMIT_TRANSACTIONS_EVEN_WITHOUT_BENEFIT = 'ParticipationLimit.SET_LIMIT_TRANSACTIONS_EVEN_WITHOUT_BENEFIT'
export const CLEAN = 'ParticipationLimit.CLEAN'

export function identificationInfo() {
  return async dispatch => await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.identificationInfo)
}

export function get(id) {
  return async dispatch => await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get, id)
}

export function create() {
  return async (dispatch, getState) => {
    const data = getState().participationLimit.current
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_POST,
      Effects.post,
      {
        notification: {
          description: null,
          message: 'Grupo limite de participação criado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      data
    )
  }
}

export function update(id) {
  return async (dispatch, getState) => {
    const data = getState().participationLimit.current
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_PUT,
      Effects.put,
      {
        notification: {
          description: null,
          message: 'Grupo limite de atualizado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      id,
      data
    )
  }
}

export function clean() {
  return ActionUtility.createAction(CLEAN)
}
export function setDescription(description) {
  return ActionUtility.createAction(SET_DESCRIPTION, description)
}
export function setLimitTransactionsEvenWithoutBenefit(limitTransactionsEvenWithoutBenefit) {
  return ActionUtility.createAction(SET_LIMIT_TRANSACTIONS_EVEN_WITHOUT_BENEFIT, limitTransactionsEvenWithoutBenefit)
}

export function addRule(data) {
  return ActionUtility.createAction(ADD_RULE, data)
}

export function setRule(index, data) {
  return ActionUtility.createAction(SET_RULE, { index, data })
}

export function deleteRule(index) {
  return ActionUtility.createAction(DELETE_RULE, index)
}
