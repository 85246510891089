import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

export async function searchByName(name, onlyPublicVisibility) {
  const queryParams = [
    {
      key: 'onlyPublicVisibility',
      value: onlyPublicVisibility,
    },
  ]

  return EffectUtility.getWithQuery(environment.managerApi.endpoints.product.category.searchByName.replace(':name', name), queryParams)
}
