export const selectOptionsFilter = (input, option) => {
  let optionName = option.label || option.children
  return optionName.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export const mapIdentificationInfoToOption = identificationInfo => {
  return { value: identificationInfo.id, label: identificationInfo.name }
}

export const mapIdentificationInfoToOptions = identificationInfo => {
  return identificationInfo.map(x => {
    return { value: x.id, label: x.name }
  })
}

export const genericActiveOptions = includeDefaultNullOption => {
  const options = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false },
  ]

  if (includeDefaultNullOption) options.splice(0, 0, { label: 'Todos', value: null })

  return options
}
