import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './LuckyNumbersEffects'

export const REQUEST_GET = 'LuckyNumbers.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'LuckyNumbers.REQUEST_GET_FINISHED'

export function identificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get)
  }
}
