import React from 'react'
import PropTypes from 'prop-types'
import { ConfigProvider } from 'antd'
import ptBr from 'antd/lib/locale/pt_BR'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'

import { msalConfig } from '../contexts/AuthConfig'
import Wrapper from '../views/Wrapper'

import { validateMessages } from '../utilities/ValidationUtility'
import SideBarProvider from '../views/components/sidebar/SideBarProvider'

export const msalInstance = new PublicClientApplication(msalConfig)

const AppProviders = ({ children }) => {
  return (
    <ConfigProvider locale={ptBr} form={{ validateMessages }}>
      <MsalProvider instance={msalInstance}>
        <SideBarProvider>
          <Wrapper>{children}</Wrapper>
        </SideBarProvider>
      </MsalProvider>
    </ConfigProvider>
  )
}

AppProviders.propTypes = {
  store: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
}

export default AppProviders
