import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

const storeEndpoints = environment.managerApi.endpoints.store

export const search = async request => await EffectUtility.post(storeEndpoints.search, request)
export const create = async body => await EffectUtility.post(storeEndpoints.create, body)
export const edit = async ({ id, payload }) => await EffectUtility.put(storeEndpoints.edit.replace(':storeId', id), payload)
export const getById = async id => await EffectUtility.get(storeEndpoints.getById.replace(':storeId', id))
export const enable = async id => await EffectUtility.post(storeEndpoints.enable.replace(':storeId', id))
export const disable = async id => await EffectUtility.post(storeEndpoints.disable.replace(':storeId', id))
export const addStore = async body => await EffectUtility.post(storeEndpoints.publish, body)

export const registerSoftExpress = async id => {
  await EffectUtility.post(storeEndpoints.registerSoftwareExpress.replace(':storeId', id))
}

export const importFile = async ({ chainId, file }) => {
  const formData = new FormData()
  formData.append('file', file)

  return await EffectUtility.postFormData(storeEndpoints.import.replace(':chainId', chainId), formData)
}

export const getAllImports = async chainId => await EffectUtility.get(storeEndpoints.getAllImport.replace(':chainId', chainId))
