import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './PartnerEffects'

export const REQUEST_GET_IDENTIFICATION_INFO = 'Partner.GET_IDENTIFICATION_INFO'

export const REQUEST_GET_IDENTIFICATION_INFO_FINISHED = 'Partner.GET_IDENTIFICATION_INFO_FINISHED'

export function identificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_IDENTIFICATION_INFO, Effects.identificationInfo)
  }
}
