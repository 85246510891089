import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

const cardBinSegmentEndpoints = environment.managerApi.endpoints.cardBinSegment

export async function search(filter, pagination) {
  return EffectUtility.post(cardBinSegmentEndpoints.search, { filter, pagination })
}

export async function create(data) {
  return EffectUtility.post(cardBinSegmentEndpoints.create, data)
}

export async function update(id, data) {
  return EffectUtility.put(cardBinSegmentEndpoints.update.replace(':segmentId', id), data)
}

export async function activate(id) {
  return EffectUtility.post(cardBinSegmentEndpoints.activate.replace(':segmentId', id))
}

export async function inactivate(id) {
  return EffectUtility.post(cardBinSegmentEndpoints.inactivate.replace(':segmentId', id))
}

export async function getById(id) {
  return EffectUtility.get(cardBinSegmentEndpoints.getById.replace(':segmentId', id))
}

export async function getIdentificationInfo() {
  return EffectUtility.get(cardBinSegmentEndpoints.identificationInfo)
}
