import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleEligiblesActions'

export const initialState = {
  participants: {
    required: true,
    segmentIds: [],
    segments: [],
  },
  pinCode: {
    required: true,
    baseIds: [],
  },
  participationAuthorization: {
    required: true,
    baseId: null,
  },
}

const saleEligiblesReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_PARTICIPANTS_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.participants = payload
    })
  },
  [Actions.REQUEST_GET_PIN_CODE_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.pinCode = payload
    })
  },
  [Actions.REQUEST_GET_PARTICIPATION_AUTHORIZATION_FINISHED](state, { payload }) {
    return produce(state, draft => {
      draft.participationAuthorization = payload
    })
  },
  [Actions.SET_CURRENT](state, { payload }) {
    return produce(state, draftState => {
      draftState.participationAuthorization = payload
    })
  },
})

export default saleEligiblesReducer
