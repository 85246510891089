import {
  AppstoreOutlined,
  BarcodeOutlined,
  DollarCircleOutlined,
  PlusOutlined,
  ScheduleOutlined,
  SearchOutlined,
  UserSwitchOutlined,
  TrademarkOutlined,
  FileTextOutlined,
  TagOutlined,
  ClusterOutlined,
  ShoppingCartOutlined,
  CreditCardOutlined,
  StarOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { push } from 'connected-react-router'
import React, { useState, useContext, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import RouteEnum from '../../../constants/RouteEnum'
import { SideBarContext } from './SideBarProvider'

import {
  SaleManagerGuard,
  LuckTimesGuard,
  PromotionalCodeGuard,
  ParticipationLimitGuard,
  CompanyGuard,
  CompanyMainMenuGuard,
  CompanyCategoryGuard,
  ForbiddenWordsGuard,
  CampaignGuard,
  ProductMenuGuard,
  ProductListMenuGuard,
  CardBinSegmentGuard,
  ProductMenuItemGuard,
  PermissionMfeProvider,
} from '@aberto/react-common'

const { Sider } = Layout
const { SubMenu } = Menu

export default function SideBar() {
  const dispatch = useDispatch()
  const [collapsedMenu, setCollapsedMenu] = useState(true)

  const sidebarMenus = [
    {
      menus: [
        {
          guard: CampaignGuard,
          name: 'Campanhas',
          key: 'campaign',
          routeEnum: RouteEnum.CampaignsPage,
          icon: ClusterOutlined,
          menus: [],
        },
      ],
    },
    {
      menus: [
        {
          guard: ProductMenuItemGuard,
          name: 'Produtos',
          key: 'product-import',
          routeEnum: null,
          icon: ShoppingCartOutlined,
          menus: [
            {
              guard: ProductMenuGuard,
              name: 'Produtos',
              key: 'product',
              routeEnum: RouteEnum.ProductsPage,
              icon: ShoppingCartOutlined,
              menus: [],
            },
            {
              guard: ProductListMenuGuard,
              name: 'Lista de Produtos',
              key: 'product-list',
              routeEnum: RouteEnum.ProductsListPage,
              icon: ShoppingCartOutlined,
              menus: [],
            },
          ],
        },
      ],
    },
    {
      menus: [
        {
          guard: SaleManagerGuard,
          name: 'Ofertas',
          key: 'sale',
          routeEnum: '',
          icon: DollarCircleOutlined,
          menus: [
            {
              guard: null,
              name: 'Buscar',
              key: 'sale-search',
              routeEnum: RouteEnum.SalesSearchPage,
              icon: SearchOutlined,
              menus: [],
            },
            {
              guard: null,
              name: 'Nova',
              key: 'new-sale',
              routeEnum: RouteEnum.SaleNew,
              icon: PlusOutlined,
              menus: [],
            },
          ],
        },
      ],
    },
    {
      menus: [
        {
          guard: LuckTimesGuard,
          name: 'Horários da Sorte',
          key: 'luck-times',
          routeEnum: RouteEnum.LuckTimesPage,
          icon: ScheduleOutlined,
          menus: [],
        },
      ],
    },
    {
      menus: [
        {
          guard: CardBinSegmentGuard,
          name: 'Segmentos',
          key: 'segments',
          routeEnum: null,
          icon: AppstoreOutlined,
          menus: [
            {
              guard: CardBinSegmentGuard,
              name: 'Bin de cartão',
              key: 'card-bin-segment',
              routeEnum: RouteEnum.CardBinSegmentsPage,
              icon: CreditCardOutlined,
              menus: [],
            },
          ],
        },
      ],
    },
    {
      menus: [
        {
          guard: ParticipationLimitGuard,
          name: 'Limite de Participação',
          key: 'participation-limit-list',
          routeEnum: RouteEnum.ParticipationLimitPage,
          icon: UserSwitchOutlined,
          menus: [],
        },
      ],
    },
    {
      menus: [
        {
          guard: CompanyMainMenuGuard,
          name: 'Empresas',
          key: 'company',
          routeEnum: null,
          icon: TrademarkOutlined,
          menus: [
            {
              guard: CompanyGuard,
              name: 'Empresas',
              key: 'company-search',
              routeEnum: RouteEnum.Company,
              icon: TrademarkOutlined,
              menus: [],
            },
            {
              guard: CompanyCategoryGuard,
              name: 'Categorias',
              key: 'company-category',
              routeEnum: RouteEnum.CompanyCategory,
              icon: TagOutlined,
              menus: [],
            },
          ],
        },
      ],
    },
    {
      menus: [
        {
          guard: ForbiddenWordsGuard,
          name: 'Palavras Proibidas',
          key: 'forbidden-words-list',
          routeEnum: RouteEnum.ForbiddenWordsPage,
          icon: FileTextOutlined,
          menus: [],
        },
      ],
    },
    {
      menus: [
        {
          guard: null,
          name: 'Níveis ou categoria',
          key: 'participation-authorization',
          routeEnum: RouteEnum.ParticipationAuthorizationPage,
          icon: StarOutlined,
          menus: [],
        },
      ],
    },
  ]

  const handleCollapseMenu = () => setCollapsedMenu(!collapsedMenu)

  const { menusWithSubMenu, menusWithoutSubMenu } = useContext(SideBarContext)

  const mountMenuUtility = menus => {
    return menus.map((menu, index) => {
      return (
        <Fragment key={`${menu.key}-${index}`}>
          {menu.guard ? (
            <menu.guard>
              {!menu.menus.length ? (
                <Menu.Item key={menu.key} icon={<menu.icon />} onClick={() => dispatch(push(menu.routeEnum))}>
                  {menu.name}
                </Menu.Item>
              ) : (
                <SubMenu key={menu.key} icon={<menu.icon />} title={menu.name}>
                  {mountMenuUtility(menu.menus)}
                </SubMenu>
              )}
            </menu.guard>
          ) : (
            <>
              {!menu.menus.length ? (
                <Menu.Item key={menu.key} icon={<menu.icon />} onClick={() => dispatch(push(menu.routeEnum))}>
                  {menu.name}
                </Menu.Item>
              ) : (
                <SubMenu key={menu.key} icon={<menu.icon />} title={menu.name}>
                  {mountMenuUtility(menu.menus)}
                </SubMenu>
              )}
            </>
          )}
        </Fragment>
      )
    })
  }

  return (
    <PermissionMfeProvider>
      <Sider trigger={null} collapsible collapsed={collapsedMenu} width={250} collapsedWidth={40}>
        <div className={collapsedMenu ? 'menu-button-box' : 'menu-button-box opened'}>
          <button id="menu-button" className={collapsedMenu ? '' : 'opened'} onClick={handleCollapseMenu}>
            <span className="bar bar1" />
            <span className="bar bar2" />
            <span className="bar bar3" />
            <span className="label">{collapsedMenu ? 'menu' : 'recolher'} </span>
          </button>
        </div>

        <Menu defaultSelectedKeys={['sale']} mode="inline">
          {sidebarMenus.map(menus => {
            return mountMenuUtility(menus.menus)
          })}
          {menusWithoutSubMenu?.map(menu => (
            <Fragment key={`${menu}`}>
              {menu.guard ? (
                <menu.guard>
                  <>
                    <SubMenu title={menu.name} key={menu.name} icon={menu.icon && <menu.icon />}>
                      <Menu.Item key={menu.name}>Carregando...</Menu.Item>
                    </SubMenu>
                  </>
                </menu.guard>
              ) : (
                <SubMenu title={menu.name} key={menu.name} icon={menu.icon && <menu.icon />}>
                  <Menu.Item key={menu.name}>Carregando...</Menu.Item>
                </SubMenu>
              )}
            </Fragment>
          ))}
          {menusWithSubMenu?.map(menu => (
            <Fragment key={`${menu}`}>
              {menu.guard ? (
                <menu.guard>
                  <SubMenu title={menu.name} key={menu.name} icon={menu.icon && <menu.icon />}>
                    {menu.subMenus?.map(subMenu => (
                      <Menu.Item
                        key={subMenu.name}
                        icon={subMenu.icon && <menu.icon />}
                        onClick={() => dispatch(push(`${menu.path}${subMenu.path}`))}
                      >
                        {subMenu.name}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                </menu.guard>
              ) : (
                <SubMenu title={menu.name} key={menu.name} icon={menu.icon && <menu.icon />}>
                  {menu.subMenus?.map(subMenu => (
                    <Menu.Item key={subMenu.name} icon={subMenu.icon && <menu.icon />} onClick={() => dispatch(push(`${menu.path}${subMenu.path}`))}>
                      {subMenu.name}
                    </Menu.Item>
                  ))}
                </SubMenu>
              )}
            </Fragment>
          ))}
        </Menu>
      </Sider>
    </PermissionMfeProvider>
  )
}
