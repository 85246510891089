import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './PinCodeEffects'

export const REQUEST_IDENTIFICATION_INFO = 'PinCode.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'PinCode.REQUEST_IDENTIFICATION_INFO_FINISHED'
export const SET_CURRENT = 'PromotionalCode.SET_CURRENT'

export function identificationInfo() {
  return async (dispatch, getStatus) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.identificationInfo)
  }
}

export function setCurrentId(id) {
  return ActionUtility.createAction(SET_CURRENT, id)
}
