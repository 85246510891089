import NotificationEnum from '../../../constants/NotificationEnum'
import * as ActionUtility from '../../../utilities/ActionUtility'
import { addOperationToAllAttribute, createJsonPatch } from '../../../utilities/JsonPatchUtility'
import { removeEmpty } from '../../../utilities/ObjectUtility'
import * as Effects from './SaleActivateConditionEffects'

import { SaleActivateConditionRequirements } from '@aberto/react-common'
import { ActivateConditionsMap } from './models/ActivateConditionsModel'

export const REQUEST_GET = 'SaleActivateCondition.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'SaleActivateCondition.REQUEST_GET_FINISHED'
export const REQUEST_PATCH = 'SaleActivateCondition.REQUEST_PATCH'
export const REQUEST_PATCH_FINISHED = 'SaleActivateCondition.REQUEST_PATCH_FINISHED'
export const SET_CONDITION = 'SaleActivateCondition.SET_CONDITION'
export const REMOVE_CONDITION = 'SaleActivateCondition.REMOVE_CONDITION'
export const CLEAN = 'SaleActivateCondition.CLEAN'

const deleteNonPermissionProps = (activationCondition, permissions) => {
  const hasPermissionToWriteSpentValue = SaleActivateConditionRequirements.SaleActivateConditionFirstLevelSpentValueWrite.isSatisfied(permissions)
  const hasPermissionToWriteProductQuantity =
    SaleActivateConditionRequirements.SaleActivateConditionFirstLevelProductQuantityWrite.isSatisfied(permissions)

  const hasPermissionToWriteVisitBehavior =
    SaleActivateConditionRequirements.SaleActivateConditionSecondLevelVisitBehaviorWrite.isSatisfied(permissions)

  const hasPermissionToWriteLuckTimes = SaleActivateConditionRequirements.SaleActivateConditionThirdLevelLuckTimesWrite.isSatisfied(permissions)

  if (!hasPermissionToWriteSpentValue) delete activationCondition?.firstLevelCondition?.spentValue
  if (!hasPermissionToWriteProductQuantity) delete activationCondition?.firstLevelCondition?.productQuantity
  if (!hasPermissionToWriteVisitBehavior) delete activationCondition?.secondLevelCondition?.visitBehavior
  if (!hasPermissionToWriteLuckTimes) delete activationCondition?.thirdLevelCondition?.luckTimes

  if (!activationCondition.firstLevelCondition) delete activationCondition?.firstLevelCondition
  if (!activationCondition.secondLevelCondition) delete activationCondition?.secondLevelCondition
  if (!activationCondition.thirdLevelCondition) delete activationCondition?.thirdLevelCondition

  return activationCondition
}

const mapActions = activationConditions => {
  let removes = {}
  const adds = { ...activationConditions }

  for (const [key, value] of Object.entries(ActivateConditionsMap)) {
    const { level, type } = value

    if (activationConditions[level] && activationConditions[level][type]) {
      const activationCondition = activationConditions[level][type]

      if (activationCondition.removed) {
        removes = { ...removes, [`${level}/${type}`]: { operation: 'remove' } }

        delete adds[level][type]

        const shouldDeleteEntireLevel = Object.keys(adds[level]).length <= 0

        if (shouldDeleteEntireLevel) {
          delete removes[`${level}/${type}`]
          delete adds[level]

          removes = { ...removes, [level]: { operation: 'remove' } }
        }
      }
    }
  }

  return { removes, adds }
}

export function get(saleId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get, saleId)
  }
}

export function patch(saleId, permissions) {
  return async (dispatch, getState) => {
    let { activateConditions } = getState().sale
    activateConditions = removeEmpty(activateConditions)

    const checkLevel = level => {
      if (level) {
        return Object.keys(level).length > 0 ? level : undefined
      } else {
        return undefined
      }
    }

    const firstLevelCondition = checkLevel(activateConditions.firstLevelCondition)
    const secondLevelCondition = checkLevel(activateConditions.secondLevelCondition)
    const thirdLevelCondition = checkLevel(activateConditions.thirdLevelCondition)

    const data = deleteNonPermissionProps(
      {
        firstLevelCondition,
        secondLevelCondition,
        thirdLevelCondition,
      },
      permissions
    )

    const { removes, adds } = mapActions(data)

    const dataWithOperations = { ...addOperationToAllAttribute(adds, 'add'), ...addOperationToAllAttribute(removes, 'remove') }

    var jsonPatchRequest = createJsonPatch(dataWithOperations)

    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_PATCH,
      Effects.patch,
      {
        notification: {
          description: null,
          message: 'Regras de ativação atualizadas com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      jsonPatchRequest
    )
  }
}

export function clean() {
  return ActionUtility.createAction(CLEAN)
}

export function setCondition({ level, type }, value) {
  return ActionUtility.createAction(SET_CONDITION, { level, type, value })
}

export function removeCondition({ level, type }, value) {
  return ActionUtility.createAction(REMOVE_CONDITION, { level, type })
}
