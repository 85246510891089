import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './CategoryEffects'
import { ProductVisibility } from '../models/ProductVisibility'

export const REQUEST_SEARCH_BY_NAME = 'ProductCategory.REQUEST_SEARCH_BY_NAME'
export const REQUEST_SEARCH_BY_NAME_FINISHED = 'ProductCategory.REQUEST_SEARCH_BY_NAME_FINISHED'

export const CLEAR_SEARCH_BY_NAME = 'ProductCategory.CLEAR_SEARCH_BY_NAME'

export function searchByName(name, visibility = ProductVisibility.Private) {
  return async (dispatch, getState) => {
    const onlyPublicVisibility = visibility === ProductVisibility.Public
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH_BY_NAME, Effects.searchByName, name, onlyPublicVisibility)
  }
}

export const clearSearchByName = () => ActionUtility.createAction(CLEAR_SEARCH_BY_NAME)
