import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './LuckTimesEffects'

export const REQUEST_GET = 'LuckTimes.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'LuckTimes.REQUEST_GET_FINISHED'
export const REQUEST_ADD = 'LuckTimes.REQUEST_ADD'
export const REQUEST_ADD_FINISHED = 'LuckTimes.REQUEST_ADD_FINISHED'
export const REQUEST_ADD_TIMES = 'LuckTimes.REQUEST_ADD_TIMES'
export const REQUEST_ADD_TIMES_FINISHED = 'LuckTimes.REQUEST_ADD_TIMES_FINISHED'
export const SET_CURRENT = 'LuckTimes.SET_CURRENT'
export const SET_CURRENT_ID = 'LuckTimes.SET_CURRENT_ID'
export const CLEAR = 'LuckTimes.CLEAR'

export function get() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get)
  }
}

export function add() {
  return async (dispatch, getState) => {
    const data = getState().luckTimes.new

    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_ADD,
      Effects.add,
      {
        notification: {
          description: null,
          message: 'Base adicionada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      data
    )
  }
}

export function addTimes(id, times) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_ADD_TIMES,
      Effects.addTimes,
      {
        notification: {
          description: null,
          message: 'Horários Adicionados com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      id,
      times
    )
  }
}

export function setCurrent(data) {
  return ActionUtility.createAction(SET_CURRENT, data)
}

export function setCurrentId(id) {
  return ActionUtility.createAction(SET_CURRENT_ID, id)
}

export function clear() {
  return ActionUtility.createAction(CLEAR)
}
