import { produce } from 'immer'
import moment from 'moment-timezone'
import baseReducer from '../../../utilities/BaseReducer'
import { dateConfig } from '../../../utilities/DateUtility'
import * as Actions from './SaleBasicInfoActions'
moment.tz.setDefault(dateConfig.mommentLocalization)

export const initialState = {
  name: '',
  description: '',
  campaignId: undefined,
  transactionDeliveryConfigurationIds: [],
  payingCompanyId: undefined,
  promoterId: undefined,
  authorizeBenefit: true,
  externalCode: '',
  status: 1,
  duration: {
    initialDateUTC: '',
    finalDateUTC: '',
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
  },
  delimitations: {
    participantBlacklistBaseIds: [],
    participationLimitGroupId: '',
    budgetId: null,
  },
  advertisement: {
    publish: true,
  },
}

const saleBasicInfoReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    payload.duration.initialDateUTC = moment(payload.duration.initialDateUTC)
    payload.duration.finalDateUTC = moment(payload.duration.finalDateUTC)
    return payload
  },
  [Actions.SET](state, { payload }) {
    return produce(state, draft => {
      draft.name = payload.name
      draft.description = payload.description
      draft.campaignId = payload.campaignId
      draft.payingCompanyId = payload.payingCompanyId
      draft.promoterId = payload.promoterId
      draft.delimitations = { ...payload.delimitations }
      draft.duration.initialDateUTC = payload.duration.initialDateUTC
      draft.duration.finalDateUTC = payload.duration.finalDateUTC
      draft.duration.daysOfWeek = payload.duration.daysOfWeek
      draft.advertisement = initialState.advertisement
      draft.transactionDeliveryConfigurationIds = [...payload.transactionDeliveryConfigurationIds]
    })
  },
  [Actions.CLEAN](state, action) {
    return initialState
  },
  [Actions.SET_PROMOTERID](state, { payload }) {
    return produce(state, draft => {
      draft.promoterId = payload
    })
  },
  [Actions.SET_PAYINGCOMPANYID](state, { payload }) {
    return produce(state, draft => {
      draft.payingCompanyId = payload
    })
  },
})

export default saleBasicInfoReducer
