import { produce } from 'immer'

import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleSearchActions'

export const initialState = {
  pagination: {
    pageSize: 15,
    pageNumber: 1,
  },
  filter: {
    name: null,
    chainId: undefined,
    statusList: [],
    initialPeriodDate: {
      start: null,
      end: null,
    },
    finalPeriodDate: {
      start: null,
      end: null,
    },
    campaignId: undefined,
  },
  response: {
    numberOfRows: 0,
    data: [],
  },
  identificationInfo: {
    data: [],
  },
}

const saleSearchReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.response = action.payload
    })
  },
  [Actions.SET_FILTER](state, action) {
    return produce(state, draftState => {
      draftState.filter = action.payload
    })
  },
  [Actions.SET_PAGINATION](state, action) {
    return produce(state, draftState => {
      draftState.pagination = action.payload
    })
  },
  [Actions.CLEAN_RESULTS](state, action) {
    return produce(state, draftState => {
      draftState.response = initialState.response
    })
  },
  [Actions.CLEAR_FILTER](state, action) {
    return produce(state, draftState => {
      draftState.filter = initialState.filter
    })
  },
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.identificationInfo.data = payload
    })
  },
})

export default saleSearchReducer
