/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import environment from 'environment'
import { MenuType, MfeType } from '../../../environments/environment-types'

interface ContextType {
  menus: MenuType[]
  menusWithSubMenu?: MenuType[]
  menusWithoutSubMenu?: MenuType[]
  createSubMenuByMenuName?(menuName: string, subMenus: MenuType[]): void
}

const initialState = environment.mfe.map((frontProps: MfeType) => frontProps.sideBar) as MenuType[]

const SideBarContext = React.createContext<ContextType>({ menus: initialState })

const SideBarProvider: React.FC = ({ children }) => {
  const [menus, setMenus] = useState<MenuType[]>(initialState)
  const [menusWithSubMenu, setMenusWithSubMenu] = useState<MenuType[]>([])
  const [menusWithoutSubMenu, setMenusWithoutSubMenu] = useState<MenuType[]>([])

  const createSubMenuByMenuName = (menuName: string, subMenus: MenuType[]) => {
    if (!menus.some(menu => menu.name === menuName)) return

    const menuWithSubMenus = menus.find(menu => menu.name === menuName) as MenuType

    const hasSomeChangeInSubMenu = JSON.stringify(menuWithSubMenus.subMenus) !== JSON.stringify(subMenus)

    if (!hasSomeChangeInSubMenu) return

    menuWithSubMenus.subMenus = subMenus

    setMenus(oldMenus => [...oldMenus.filter(menu => menu.name !== menuName), menuWithSubMenus])
  }

  useEffect(() => {
    const menusWithSubMenu = menus.filter(menu => menu.subMenus)
    const menusWithoutSubMenu = menus.filter(menu => !menu.subMenus)

    setMenusWithSubMenu(menusWithSubMenu)
    setMenusWithoutSubMenu(menusWithoutSubMenu)
  }, [menus])

  return (
    <SideBarContext.Provider
      value={{
        menus,
        menusWithSubMenu,
        menusWithoutSubMenu,
        createSubMenuByMenuName,
      }}
    >
      {children}
    </SideBarContext.Provider>
  )
}

export default SideBarProvider
export { SideBarContext }
