import { ActionUtility } from '@aberto/react-common'
import * as Effects from './SaleAdvertisementEffects'
import NotificationEnum from '../../../constants/NotificationEnum'

export const REQUEST_GET_ADVERTISEMENT = 'SaleAdvertisement.REQUEST_GET_ADVERTISEMENT'
export const REQUEST_GET_ADVERTISEMENT_FINISHED = 'SaleAdvertisement.REQUEST_GET_ADVERTISEMENT_FINISHED'
export const REQUEST_IDENTIFICATION_INFO = 'SaleAdvertisement.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'SaleAdvertisement.REQUEST_IDENTIFICATION_INFO_FINISHED'
export const REQUEST_UPDATE_ADVERTISEMENT = 'SaleAdvertisement.REQUEST_UPDATE_ADVERTISEMENT'
export const REQUEST_UPLOAD_IMAGES = 'SaleAdvertisement.REQUEST_UPLOAD_IMAGES'

const getImageAsBlob = async image => {
  if (!image) return

  return new Promise(resolve => {
    fetch(image.url)
      .then(res => res.blob())
      .then(blob => {
        blob.name = image.name
        blob.filename = image.name
        resolve(new File([blob], image.name))
      })
  })
}

export function get(saleId) {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_ADVERTISEMENT, Effects.get, saleId)
  }
}

export function getIdentificationInfo() {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.getIdentificationInfo)
  }
}

export function updateSaleAdvertisement(saleId, data) {
  return async dispatch => {
    return await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE_ADVERTISEMENT,
      Effects.updateSaleAdvertisement,
      {
        notification: {
          description: null,
          message: 'Anúncio atualizado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      data
    )
  }
}

export const uploadImages = (saleId, images) => {
  return async dispatch => {
    const multipleImages = await Promise.all(
      images.map(async image => {
        return await getImageAsBlob(image)
      })
    )
    await ActionUtility.createThunkEffect(dispatch, REQUEST_UPLOAD_IMAGES, Effects.uploadMultipleImages, saleId, multipleImages)
  }
}
