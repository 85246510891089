// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-switch-inner .anticon {
  color: #fff;
}

.ant-switch-checked {
  background-color: var(--tb-blue) !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/forms/switch.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":[".ant-switch-inner .anticon {\n  color: #fff;\n}\n\n.ant-switch-checked {\n  background-color: var(--tb-blue) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
