import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './StoreEffects'

export const REQUEST_SEARCH = 'Store.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'Store.REQUEST_SEARCH_FINISHED'
export const REQUEST_ENABLE = 'Store.REQUEST_ENABLE'
export const REQUEST_ENABLE_FINISHED = 'Store.REQUEST_ENABLE_FINISHED'
export const REQUEST_DISABLE = 'Store.REQUEST_DISABLE'
export const REQUEST_DISABLE_FINISHED = 'Store.REQUEST_DISABLE_FINISHED'
export const REQUEST_CREATE = 'Store.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'Store.REQUEST.CREATE_FINISHED'
export const REQUEST_EDIT = 'Store.REQUEST_EDIT'
export const REQUEST_EDIT_FINISHED = 'Store.REQUEST.EDIT_FINISHED'
export const REQUEST_GET_BY_ID = 'Store.REQUEST_GET_BY_ID'
export const REQUEST_GET_BY_ID_FINISHED = 'Store.REQUEST_GET_BY_ID_FINISHED'

export const REQUEST_ADD_STORE = 'SaleProducts.REQUEST_ADD_STORE'
export const REQUEST_ADD_STORE_FINISHED = 'SaleProducts.REQUEST_ADD_STORE_FINISHED'

export const REGISTER_SOFTWARE_EXPRESS = 'Store.REGISTER_SOFTWARE_EXPRESS'
export const SET_PAGINATION = 'Store.SET_PAGINATION'
export const CLEAR_FILTER = 'Store.CLEAR_FILTER'
export const SET_FILTER = 'Store.SET_FILTER'
export const CLEAR = 'Store.CLEAR'

export const REQUEST_IMPORT = 'Store.REQUEST_IMPORT'
export const REQUEST_IMPORT_FINISHED = 'Store.REQUEST_IMPORT_FINISHED'

export const REQUEST_IMPORT_GET_ALL = 'Store.REQUEST_IMPORT_GET_ALL'
export const REQUEST_IMPORT_GET_ALL_FINISHED = 'Store.REQUEST_IMPORT_GET_ALL_FINISHED'

export const search = () => {
  return async (dispatch, getState) => {
    const payload = getState().store.search.request
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH, Effects.search, payload)
  }
}

export const create = payload => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      Effects.create,
      {
        notification: {
          message: 'Loja criada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      payload
    )
  }
}

export const edit = (payload, id) => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_EDIT,
      Effects.edit,
      {
        notification: {
          message: 'Loja alterada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      { payload, id }
    )
  }
}

export function publishStores(payload) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_ADD_STORE,
      Effects.addStore,
      {
        notification: {
          message: 'Loja(s) publicada(s) com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      payload
    )
  }
}

export const getById = id => {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_BY_ID, Effects.getById, id)
  }
}

export const register = id => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REGISTER_SOFTWARE_EXPRESS,
      Effects.registerSoftExpress,
      {
        notification: {
          message: 'Loja cadastrada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      id
    )
  }
}

export function enable(id) {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_ENABLE, Effects.enable, id)
  }
}

export function disable(id) {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_DISABLE, Effects.disable, id)
  }
}

export const storeImport = (file, chainId) => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_IMPORT,
      Effects.importFile,
      {
        notification: {
          message: 'Lojas enviadas para importação.',
          type: NotificationEnum.Success,
        },
      },
      { file, chainId }
    )
  }
}

export const getAllImports = chainId => {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IMPORT_GET_ALL, Effects.getAllImports, chainId)
  }
}

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)
export const clearFilter = () => ActionUtility.createAction(CLEAR_FILTER)
export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)
export const clear = () => ActionUtility.createAction(CLEAR)
