import { Constants } from '@aberto/react-common'
import { Layout } from 'antd'
import { ConnectedRouter } from 'connected-react-router'
import environment from 'environment'
import PropTypes from 'prop-types'
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import WebFont from 'webfontloader'
import startMFA from '../Aberto-root-config'
import RouteEnum from '../constants/RouteEnum'
import './App.css'
import { Header } from './components/header/Header'
import Notification from './components/notification/Notification'
import PageFallback from './components/page-fallback/PageFallback'
import SideBar from './components/sidebar/SideBar'
import { SideBarContext } from './components/sidebar/SideBarProvider'
import SaleSearchPage from './sale/search/SaleSearchPage'

const HomePage = lazy(() => import('./home/HomePage'))
const CompanyCategoryPage = lazy(() => import('./company/category/CategoryPage'))
const CompanyCategoryEditPage = lazy(() => import('./company/category/CategoryDetailPage'))
const CompanyPage = lazy(() => import('./company/CompanyPage'))
const CompanyDetail = lazy(() => import('./company/CompanyDetailPage'))
const CampaignsPage = lazy(() => import('./campaign/CampaignsPage'))
const CampaignsEdit = lazy(() => import('./campaign/CampaignCreatePage'))
const CampaignsCreate = lazy(() => import('./campaign/CampaignCreatePage'))
const ProductsPage = lazy(() => import('./product/ProductsPage'))
const ProductsListPage = lazy(() => import('./product-list/ProductsListPage'))
const ProductCreate = lazy(() => import('./product/ProductCreatePage'))
const ProductDetail = lazy(() => import('./product/ProductDetailPage'))
const CompanyCreate = lazy(() => import('./company/CompanyCreatePage'))
const SalePage = lazy(() => import('./sale/SalePage'))
const LuckTimesPage = lazy(() => import('./luck-times/LuckTimesPage'))
const ParticipantSegmentPage = lazy(() => import('./participant-segment/ParticipantSegmentPage'))
const ParticipationAuthorization = lazy(() => import('./participation-authorization/ParticipationAuthorizationPage'))
const CardBinSegmentsPage = lazy(() => import('./card-bin-segment/CardBinSegmentsPage'))
const NotFoundPage = lazy(() => import('./not-found/NotFoundPage'))
const LimitParticipationListPage = lazy(() => import('./participation-limit/ParticipationLimitListPage'))
const LimitParticipationPage = lazy(() => import('./participation-limit/ParticipationLimitPage'))
const ForbiddenWordsPage = lazy(() => import('./forbidden-words/ForbiddenWordsPage'))
const StoreCreate = lazy(() => import('./store/StoreCreate'))
const StoreEdit = lazy(() => import('./store/StoreEdit'))
const SignOutPage = lazy(() => import('./signout/SignOutPage'))

WebFont.load({
  google: { families: ['Poppins:700', 'Rubik: 400, 500', 'sans-serif'] },
})

const App = props => {
  const [mfeIsLoaded, setMfeIsLoaded] = useState(false)
  const { createSubMenuByMenuName } = useContext(SideBarContext)
  const onMfeLoaded = () => setMfeIsLoaded(true)

  useEffect(() => {
    startMFA(environment.mfe, createSubMenuByMenuName, onMfeLoaded)
  }, [])

  const { Content } = Layout

  return (
    <ConnectedRouter history={props.history}>
      <Layout id="site">
        <SideBar />
        <Layout>
          <Header />
          <Layout className="site-layout main-content">
            <Content>
              {Constants.parcelNames.map(name => (
                <Content id={`${Constants.abertoMfeDiv}-${name}`} key={name} />
              ))}
              <Suspense fallback={<PageFallback />}>
                <Switch>
                  {environment.mfe.map(frontProps => (
                    <Route path={`/${frontProps.basename}`} render={() => (mfeIsLoaded ? <></> : <PageFallback />)} key={frontProps.name} />
                  ))}
                  <Route exact={true} path={RouteEnum.Home} component={HomePage} />
                  <Route path={RouteEnum.SalesSearchPage} component={SaleSearchPage} />
                  <Route path={RouteEnum.LuckTimesPage} component={LuckTimesPage} />
                  <Route path={RouteEnum.ParticipantSegmentPage} component={ParticipantSegmentPage} />
                  <Route path={RouteEnum.SalePage} component={SalePage} />
                  <Route path={RouteEnum.ParticipationLimitPage} component={LimitParticipationListPage} />
                  <Route path={RouteEnum.ParticipationLimitNewPage} component={LimitParticipationPage} />
                  <Route path={RouteEnum.ParticipationLimitDetailsPage} component={LimitParticipationPage} />
                  <Route path={RouteEnum.ForbiddenWordsPage} component={ForbiddenWordsPage} />
                  <Route exact={true} path={RouteEnum.Company} component={CompanyPage} />
                  <Route exact={true} path={RouteEnum.CompanyCategory} component={CompanyCategoryPage} />
                  <Route exact={true} path={RouteEnum.CompanyCategoryInfo} component={CompanyCategoryEditPage} />
                  <Route exact={true} path={RouteEnum.CompanyEdit} component={CompanyDetail} />
                  <Route exact={true} path={RouteEnum.CompanyCreate} component={CompanyCreate} />
                  <Route exact={true} path={RouteEnum.StoreCreate} component={StoreCreate} />
                  <Route exact={true} path={RouteEnum.StoreEdit} component={StoreEdit} />
                  <Route exact={true} path={RouteEnum.SignOut} component={SignOutPage} />
                  <Route exact={true} path={RouteEnum.CampaignsPage} component={CampaignsPage} />
                  <Route exact={true} path={RouteEnum.CampaignCreate} component={CampaignsCreate} />
                  <Route exact={true} path={RouteEnum.CampaignsEdit} component={CampaignsEdit} />
                  <Route exact={true} path={RouteEnum.ProductsPage} component={ProductsPage} />
                  <Route exact={true} path={RouteEnum.ProductsListPage} component={ProductsListPage} />
                  <Route exact={true} path={RouteEnum.ProductCreate} component={ProductCreate} />
                  <Route exact={true} path={RouteEnum.ProductDetail} component={ProductDetail} />
                  <Route exact={true} path={RouteEnum.CardBinSegmentsPage} component={CardBinSegmentsPage} />
                  <Route exact={true} path={RouteEnum.ParticipationAuthorization} component={ParticipationAuthorization} />
                  <Route component={NotFoundPage} />
                </Switch>
              </Suspense>
            </Content>
            <Notification />
          </Layout>
        </Layout>
      </Layout>
    </ConnectedRouter>
  )
}

App.propTypes = {
  history: PropTypes.any.isRequired,
}

export default App
