import NotificationEnum from '../../../constants/NotificationEnum'
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './SaleProductsEffects'

export const REQUEST_SEARCH_SELECTED = 'SaleProducts.REQUEST_SEARCH_SELECTED'
export const REQUEST_SEARCH_SELECTED_FINISHED = 'SaleProducts.REQUEST_SEARCH_SELECTED_FINISHED'
export const REQUEST_SEARCH_AVAILABLE = 'SaleProducts.REQUEST_SEARCH_AVAILABLE'
export const REQUEST_SEARCH_AVAILABLE_FINISHED = 'SaleProducts.REQUEST_SEARCH_AVAILABLE_FINISHED'
export const REQUEST_ADD_PRODUCT = 'SaleProducts.REQUEST_ADD_PRODUCT'
export const REQUEST_ADD_PRODUCT_FINISHED = 'SaleProducts.REQUEST_ADD_PRODUCT_FINISHED'
export const SET_FILTER = 'SaleProducts.SET_FILTER'
export const SET_FILTER_AVAILABLE = 'SaleProducts.SET_FILTER_AVAILABLE'
export const SET_PAGINATION_AVAILABLE = 'SaleProducts.SET_PAGINATION_AVAILABLE'
export const SET_PAGINATION_SELECTED = 'SaleProducts.SET_PAGINATION_SELECTED'
export const SET_ALLOW_ANY_PRODUCT = 'SaleProducts.SET_ALLOW_ANY_PRODUCT'
export const SET_ALLOW_ANY_PRODUCT_FINISHED = 'SaleProducts.SET_ALLOW_ANY_PRODUCT_FINISHED'
export const RESET_SELECTED_FILTER_AVAILABLE = 'SaleProducts.RESET_SELECTED_FILTER_AVAILABLE'
export const RESET_SELECTED_FILTER = 'SaleProducts.RESET_SELECTED_FILTER'
export const RESET_AVAILABLE_PAGINATION = 'SaleProducts.RESET_AVAILABLE_PAGINATION'
export const RESET_SELECTED_PAGINATION = 'SaleProducts.RESET_SELECTED_PAGINATION'
export const REQUEST_VINCULATE_BY_FILTER = 'SaleProducts.REQUEST_VINCULATE_BY_FILTER'
export const REQUEST_REMOVE_SELECTED = 'SaleProducts.REQUEST_REMOVE_SELECTED'
export const REQUEST_ADD_SELECTED = 'SaleProducts.REQUEST_ADD_SELECTED'
export const SET_SELECTED_FILTER = 'SaleProducts.SET_SELECTED_FILTER'

export const REQUEST_SEARCH_PRODUCT_NON_ELIGIBLE = 'SaleProducts.REQUEST_SEARCH_PRODUCT_NON_ELIGIBLE'
export const REQUEST_SEARCH_PRODUCT_NON_ELIGIBLE_FINISHED = 'SaleProducts.REQUEST_SEARCH_PRODUCT_NON_ELIGIBLE_FINISHED'

export function searchSelected(saleId, productNonEligible = false) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH_SELECTED, productNonEligible ? Effects.searchProductsNonEligible : Effects.searchSelected, saleId, {
      filter: getState().sale.products.selected.filter,
      pagination: getState().sale.products.selected.pagination,
    })
  }
}

export function searchAvailable(saleId, productNonEligible = false) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH_AVAILABLE, productNonEligible ? Effects.searchProductsNonEligible : Effects.searchAvailable, saleId, {
      filter: getState().sale.products.available.filter,
      pagination: getState().sale.products.available.pagination,
    })
  }
}

export function searchSelectedNonEligible(saleId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH_PRODUCT_NON_ELIGIBLE, Effects.searchProductsNonEligible, saleId, {
      filter: getState().sale.products.selected.filter,
      pagination: getState().sale.products.selected.pagination,
    })
  }
}

export function update(saleId, products, allowAnyProduct) {
  return async (dispatch, getState) => {
    const data = {
      allowAnyProduct,
      products,
    }

    dispatch(ActionUtility.createAction(REQUEST_ADD_PRODUCT))

    let anyError = false

    const response = await Effects.add(saleId, data)
    const isError = response instanceof HttpErrorResponseModel

    if (isError) {
      anyError = true

      dispatch(
        ActionUtility.createAction(
          REQUEST_ADD_PRODUCT_FINISHED,
          {
            description: 'Erro ao remover produtos',
            message: response.message,
            type: NotificationEnum.Error,
          },
          true
        )
      )
    }

    if (!anyError) {
      dispatch(
        ActionUtility.createAction(REQUEST_ADD_PRODUCT_FINISHED, null, false, {
          notification: {
            description: null,
            message: 'Produtos atualizados com sucesso.',
            type: NotificationEnum.Success,
          },
        })
      )
    }
  }
}

export function changeAllowAnyProduct(saleId, allowAnyProduct) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, SET_ALLOW_ANY_PRODUCT, Effects.changeAllowAnyProduct, saleId, allowAnyProduct)
  }
}

export function resetSelectedFilter() {
  return ActionUtility.createAction(RESET_SELECTED_FILTER)
}

export function setSelectedFilter(filter) {
  return ActionUtility.createAction(SET_SELECTED_FILTER, filter)
}

export function resetAvailableFilter() {
  return ActionUtility.createAction(RESET_SELECTED_FILTER_AVAILABLE)
}

export function setAvailableFilter(filter) {
  return ActionUtility.createAction(SET_FILTER_AVAILABLE, filter)
}

export function setAvailablePagination(pagination) {
  return ActionUtility.createAction(SET_PAGINATION_AVAILABLE, pagination)
}

export function resetAvailablePagination() {
  return ActionUtility.createAction(RESET_AVAILABLE_PAGINATION)
}

export function resetSelectedPagination() {
  return ActionUtility.createAction(RESET_SELECTED_PAGINATION)
}

export function setSelectedPagination(pagination) {
  return ActionUtility.createAction(SET_PAGINATION_SELECTED, pagination)
}

export function vinculateByFilter(saleId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_VINCULATE_BY_FILTER,
      Effects.vinculateByFilter,
      {
        notification: {
          message: 'Produtos do filtro vinculados com sucesso',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      getState().sale.products.available.filter
    )
  }
}

export function removeProducts(saleId, products, productNonEligible = false) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_REMOVE_SELECTED,
      productNonEligible ? Effects.removeProductsNonEligible : Effects.removeProducts,
      {
        notification: {
          message: 'Produto(s) desvinculado(s) com sucesso',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      products
    )
  }
}

export function addProducts(saleId, products, productNonEligible = false) {
  return async (dispatch, getState) =>
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_ADD_SELECTED,
      productNonEligible ? Effects.addProductsNonEligible : Effects.addProducts,
      {
        notification: {
          message: 'Produto(s) vinculado(s) com sucesso',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      products
    )
}
