import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

export async function get() {
  return EffectUtility.get(environment.managerApi.endpoints.luckTimes.identificationInfo)
}

export async function add(data) {
  return EffectUtility.post(environment.managerApi.endpoints.luckTimes.new, data)
}

export async function addTimes(baseId, times) {
  return EffectUtility.post(environment.managerApi.endpoints.luckTimes.addTimes.replace(':baseId', baseId), times)
}
