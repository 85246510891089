// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-mask,
.ant-image-preview-mask {
  background-color: rgb(37 25 62 / 70%);
  filter: alpha(opacity=70);
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: var(--tb-green);
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: bold;
  font-size: 20px;
}

.ant-popover-buttons .ant-btn-primary {
  margin-bottom: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/modal.css"],"names":[],"mappings":"AAAA;;EAEE,qCAAqC;EACrC,yBAAyB;AAC3B;AACA;;EAEE,sBAAsB;AACxB;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".ant-modal-mask,\n.ant-image-preview-mask {\n  background-color: rgb(37 25 62 / 70%);\n  filter: alpha(opacity=70);\n}\n.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,\n.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {\n  color: var(--tb-green);\n}\n.ant-modal-confirm-body .ant-modal-confirm-title {\n  font-weight: bold;\n  font-size: 20px;\n}\n\n.ant-popover-buttons .ant-btn-primary {\n  margin-bottom: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
