import environment from 'environment'
import { createBrowserHistory } from 'history'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import AppProviders from './providers/AppProviders'
import rootStore from './stores/rootStore'
import App from './views/App'

const initialState = {}
const history = createBrowserHistory({ basename: environment.route.baseRoute })
const store = rootStore(initialState, history)

const rootEl = document.getElementById('root')
render(
  <Provider store={store}>
    <AppProviders store={store}>
      <App history={history} dispatch={store.dispatch} />
    </AppProviders>
  </Provider>,
  rootEl
)
