import { Layout, Spin } from 'antd'
import React from 'react'

const { Content } = Layout

export default function PageFallback() {
  return (
    <Content
      className="site-layout-background"
      style={{
        padding: 16,
        minHeight: window.innerHeight,
      }}
    >
      <div style={{ paddingTop: 100, textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    </Content>
  )
}
