import NotificationEnum from '../../../constants/NotificationEnum'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './CardBinSegmentImportEffects'

export const REQUEST_IMPORT = 'CardBinSegmentImportActions.REQUEST_IMPORT'
export const REQUEST_IMPORT_FINISHED = 'CardBinSegmentImportActions.REQUEST_IMPORT_FINISHED'

export const REQUEST_SEARCH = 'CardBinSegmentImportActions.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'CardBinSegmentImportActions.REQUEST_SEARCH_FINISHED'

export const SET_FILTER = 'CardBinSegmentImportActions.SET_FILTER'
export const SET_PAGINATION = 'CardBinSegmentImportActions.SET_PAGINATION'

export const CLEAR = 'CardBinSegmentImportActions.CLEAR'
export const CLEAR_FILTER = 'CardBinSegmentImportActions.CLEAR_FILTER'

export function importBins(file) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_IMPORT,
      Effects.importBins,
      {
        notification: {
          message: `Importação de bins iniciada com sucesso`,
          type: NotificationEnum.Success,
        },
      },
      getState().cardBinSegment?.currentSegment?.id,
      file
    )
  }
}

export function search() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.search,
      getState().cardBinSegmentImport.search.filter,
      getState().cardBinSegmentImport.search.pagination
    )
  }
}

export const setFilter = filter => {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(SET_FILTER, { ...filter, segmentId: getState().cardBinSegment.currentSegment.id }))
  }
}

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)

export const clear = _ => ActionUtility.createAction(CLEAR)

export const clearFilter = _ => ActionUtility.createAction(CLEAR_FILTER)
