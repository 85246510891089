import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import budgetReducer from './sale/budget/SaleBudgetReducer'
import { AuthorizationReducer } from '@aberto/react-common'
import campaign from './campaign/CampaignReducer'
import productReducer from './product/ProductReducer'
import productListReducer from './product-list/productListReducer'
import productCategoryReducer from './product/category/CategoryReducer'
import productImportReducer from './product/import/ProductImportReducer'
import companyRootReducer from './company/CompanyRootReducer'
import error from './error/ErrorReducer'
import luckTimesReducer from './luck-times/LuckTimesReducer'
import notification from './notification/NotificationReducer'
import participantSegmentReducer from './participant-segment/ParticipantSegmentReducer'
import participantSegmentImportReducer from './participant-segment-import/ParticipantSegmentImportReducer'
import participationLimitReducer from './participation-limit/ParticipationLimitReducer'
import pinCodeReducer from './pinCode/PinCodeReducer'
import promotionalCodeReducer from './promotional-code/PromotionalCodeReducer'
import requesting from './requesting/RequestingReducer'
import saleRoot from './sale/SaleRootReducer'
import userInterface from './user-interface/UserInterfaceReducer'
import walletReducer from './wallet/WalletReducer'
import forbiddenWordsReducer from './forbidden-words/ForbiddenWordsReducer'
import addressReducer from './address/AddressReducer'
import storeReducer from './store/StoreReducer'
import partnerReducer from './partner/PartnerReducer'
import cardBinSegmentReducer from './card-bin-segment/CardBinSegmentReducer'
import cardBinSegmentImportReducer from './card-bin-segment/import/CardBinSegmentImportReducer'
import transactionDeliveryReducer from './transaction-delivery/TransactionDeliveryReducer'
import ParticipationAuthorizationBaseReducer from './participation-authorization-base/ParticipationAuthorizationBaseReducer'
import ParticipationAuthorizationReducer from './participation-authorization/ParticipationAuthorizationReducer'
import luckyNumbersReducer from './lucky-numbers/LuckyNumbersReducer'

export default history => {
  const reducerMap = {
    router: connectRouter(history),
    ui: userInterface,
    authorization: AuthorizationReducer,
    requesting: requesting,
    error: error,
    notification: notification,
    sale: saleRoot,
    campaign: campaign,
    product: productReducer,
    productList: productListReducer,
    productCategory: productCategoryReducer,
    productImport: productImportReducer,
    luckTimes: luckTimesReducer,
    company: companyRootReducer,
    promotionalCode: promotionalCodeReducer,
    participationLimit: participationLimitReducer,
    participantSegment: participantSegmentReducer,
    participantSegmentImport: participantSegmentImportReducer,
    wallet: walletReducer,
    pinCode: pinCodeReducer,
    forbiddenWords: forbiddenWordsReducer,
    address: addressReducer,
    store: storeReducer,
    partner: partnerReducer,
    cardBinSegment: cardBinSegmentReducer,
    cardBinSegmentImport: cardBinSegmentImportReducer,
    budgetIdentificationInfo: budgetReducer,
    transactionDelivery: transactionDeliveryReducer,
    ParticipationAuthorization: ParticipationAuthorizationReducer,
    ParticipationAuthorizationBase: ParticipationAuthorizationBaseReducer,
    luckyNumbers: luckyNumbersReducer,
  }

  return combineReducers(reducerMap)
}
