import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SubCategoryActions'

export const initialState = {
  search: {
    request: {
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
      filter: {
        name: null,
        active: null,
      },
    },
    response: {
      numberOfRows: 0,
      data: [],
    },
  },
  currentSubCategory: {
    name: null,
  },
}

const importReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.response = payload
    })
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.request.pagination = payload
    })
  },
  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.request.filter = payload
    })
  },
  [Actions.CLEAR_FILTER](state) {
    return produce(state, draftState => {
      draftState.search.request.filter = initialState.search.request.filter
    })
  },
  [Actions.REQUEST_GET_BY_ID_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentSubCategory = payload
    })
  },
  [Actions.CLEAN_CURRENT_SUB_CATEGORY](state) {
    return produce(state, draftState => {
      draftState.currentSubCategory = initialState.currentSubCategory
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
})

export default importReducer
