import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './SaleSummaryEffects'

export const REQUEST_GET = 'SaleSummary.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'SaleSummary.REQUEST_GET_FINISHED'
export const REQUEST_CHANGE_STATUS = 'SaleSummary.REQUEST_CHANGE_STATUS'
export const REQUEST_CHANGE_STATUS_FINISHED = 'SaleSummary.REQUEST_CHANGE_STATUS_FINISHED'

export function get(saleId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get, saleId)
  }
}

export function changeStatus(saleId, status) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_CHANGE_STATUS, Effects.changeStatus, saleId, status)
  }
}
