import { combineReducers } from 'redux'
import chainReducer from './chain/ChainReducer'
import payingReducer from './paying/PayingReducer'
import promoterReducer from './promoter/PromoterReducer'
import companyReducer from './CompanyReducer'
import importReducer from './import/ImportReducer'
import categoryReducer from './category/CategoryReducer'
import subCategoryReducer from './sub-category/SubCategoryReducer'
import subCategoryImportReducer from './sub-category/import/ImportReducer'

const companyRootReducer = combineReducers({
  chain: chainReducer,
  paying: payingReducer,
  promoter: promoterReducer,
  company: companyReducer,
  import: importReducer,
  category: categoryReducer,
  subCategory: subCategoryReducer,
  subCategoryImport: subCategoryImportReducer,
})

export default companyRootReducer
