import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './LuckTimesActions'

export const initialState = {
  identificationInfo: [],
  new: {
    id: null,
    description: '',
    behaviors: [],
  },
}

const luckTimesReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload
    })
  },
  [Actions.REQUEST_ADD_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.new.id = payload
    })
  },
  [Actions.SET_CURRENT](state, { payload }) {
    return produce(state, draftState => {
      draftState.new.description = payload.description
    })
  },
  [Actions.SET_CURRENT_ID](state, { payload }) {
    return produce(state, draftState => {
      draftState.new.id = payload
    })
  },
  [Actions.CLEAR](state, { payload }) {
    return produce(state, draftState => {
      draftState.new = initialState.new
    })
  },
})

export default luckTimesReducer
