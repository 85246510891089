import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './CampaignEffects'

export const REQUEST_GET_IDENTIFICATION_INFO = 'CampaignActions.REQUEST_GET_IDENTIFICATION_INFO'
export const REQUEST_GET_IDENTIFICATION_INFO_FINISHED = 'CampaignActions.REQUEST_GET_IDENTIFICATION_INFO_FINISHED'

export const REQUEST_GET_BY_ID = 'CampaignActions.REQUEST_GET_BY_ID'
export const REQUEST_GET_BY_ID_FINISHED = 'CampaignActions.REQUEST_GET_BY_ID_FINISHED'

export const REQUEST_CREATE = 'CampaignActions.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'CampaignActions.REQUEST_CREATE_FINISHED'

export const REQUEST_UPDATE = 'CampaignActions.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'CampaignActions.REQUEST_UPDATE_FINISHED'

export const REQUEST_SEARCH = 'CampaignActions.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'CampaignActions.REQUEST_SEARCH_FINISHED'

export const UPDATE_ATTRIBUTES = 'CampaignActions.UPDATE_ATTRIBUTES'

export const SET_FILTER = 'CampaignActions.SET_FILTER'
export const SET_PAGINATION = 'CampaignActions.SET_PAGINATION'
export const CLEAR = 'CampaignActions.CLEAR'
export const CLEAR_FILTER = 'CampaignActions.CLEAR_FILTER'

export function identificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_IDENTIFICATION_INFO, Effects.getIdentificationInfo)
  }
}

export function getById(id) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_BY_ID, Effects.getById, id)
  }
}

export function create(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      Effects.create,
      {
        notification: {
          message: 'Campanha criada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      {
        ...data,
        attributes: getState().campaign.currentCampaign.attributes,
      }
    )
  }
}

export function update(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effects.update,
      {
        notification: {
          message: 'Campanha atualizada com sucesso',
          type: NotificationEnum.Success,
        },
      },
      getState().campaign.currentCampaign.id,
      { ...data, attributes: getState().campaign.currentCampaign.attributes }
    )
  }
}

export function search() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.search,
      getState().campaign.search.filter,
      getState().campaign.search.pagination
    )
  }
}

export const updateAttributesFromCurrentCampaign = attributes =>
  ActionUtility.createAction(UPDATE_ATTRIBUTES, attributes, null, {
    notification: {
      message: 'Atributos alterados/adicionados com sucesso',
      type: NotificationEnum.Success,
    },
  })

export const updateAttributesFromCurrentCampaignWithRemoveNotification = attributes =>
  ActionUtility.createAction(UPDATE_ATTRIBUTES, attributes, null, {
    notification: {
      message: 'Atributo removido com sucesso',
      type: NotificationEnum.Success,
    },
  })

export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)

export const clear = _ => ActionUtility.createAction(CLEAR)

export const clearFilter = _ => ActionUtility.createAction(CLEAR_FILTER)
