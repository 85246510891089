import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './SaleSearchEffects'

export const REQUEST_SEARCH = 'SaleSearch.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'SaleSearch.REQUEST_SEARCH_FINISHED'
export const SET_FILTER = 'SaleSearch.SET_FILTER'
export const SET_PAGINATION = 'SaleSearch.SET_PAGINATION'
export const CLEAN_RESULTS = 'SaleSearch.CLEAN_RESULTS'
export const CLEAR_FILTER = 'SaleSearch.CLEAR_FILTER'
export const REQUEST_IDENTIFICATION_INFO = 'SaleSearch.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'SaleSearch.REQUEST_IDENTIFICATION_INFO_FINISHED'

export function search() {
  return async (dispatch, getState) => {
    const { pagination, filter } = getState().sale.search

    const params = {
      pagination,
      filter,
    }

    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH, Effects.search, params)
  }
}

export function identificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.identificationInfo)
  }
}

export function setFilter(filter) {
  return ActionUtility.createAction(SET_FILTER, filter)
}

export function setPagination(pagination) {
  return ActionUtility.createAction(SET_PAGINATION, pagination)
}

export function cleanResults(filter) {
  return ActionUtility.createAction(CLEAN_RESULTS, filter)
}

export function clearFilter(filter) {
  return ActionUtility.createAction(CLEAR_FILTER, filter)
}
