// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --tb-blue: #001d42;
  --tb-middle-blue: #405571;
  --tb-green: #21cc99;
  --tb-light-green: #27f3b5;
  --tb-gray-1: #707070;
  --tb-gray-2: #b3b3b3;
  --tb-gray-3: #d9d9d9;
  --tb-gray-4: #f2f2f2;

  --dark-blue: var(--tb-blue);
  --light-blue: #00ceff;
  --bg-light-blue: #7fe6fe;
  --orange: #f2662e;
  --bg-orange: #fbe0d5;
  --red: tomato;

  /* tag colors */
  --tag-darkblue: hsl(240, 55%, 30%);
  --tag-darkblue-bg: hsl(240, 55%, 88%);
  --tag-lightblue: hsl(212, 100%, 60%);
  --tag-lightblue-bg: hsl(212, 100%, 95%);

  --tag-red: hsl(0, 68%, 40%);
  --tag-red-bg: hsl(0, 68%, 88%);
  --tag-pink: hsl(340, 86%, 65%);
  --tag-pink-bg: hsl(340, 86%, 95%);

  --tag-darkgreen: hsl(120, 70%, 30%);
  --tag-darkgreen-bg: hsl(120, 70%, 88%);
  --tag-lightgreen: hsl(120, 66%, 40%);
  --tag-lightgreen-bg: hsl(120, 66%, 95%);

  --tag-brown: hsl(19, 56%, 30%);
  --tag-brown-bg: hsl(19, 56%, 88%);
  --tag-orange: hsl(17, 88%, 50%);
  --tag-orange-bg: hsl(17, 88%, 88%);

  --tag-purple: hsl(271, 76%, 40%);
  --tag-purple-bg: hsl(271, 76%, 88%);
  --tag-lightpurple: hsl(271, 100%, 70%);
  --tag-lightpurple-bg: hsl(271, 100%, 95%);

  --tag-black: hsl(0, 0%, 10%);
  --tag-black-bg: hsl(0, 0%, 88%);
  --tag-gray: hsl(0, 0%, 50%);
  --tag-gray-bg: hsl(0, 0%, 88%);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/_variables.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;;EAEpB,2BAA2B;EAC3B,qBAAqB;EACrB,wBAAwB;EACxB,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;;EAEb,eAAe;EACf,kCAAkC;EAClC,qCAAqC;EACrC,oCAAoC;EACpC,uCAAuC;;EAEvC,2BAA2B;EAC3B,8BAA8B;EAC9B,8BAA8B;EAC9B,iCAAiC;;EAEjC,mCAAmC;EACnC,sCAAsC;EACtC,oCAAoC;EACpC,uCAAuC;;EAEvC,8BAA8B;EAC9B,iCAAiC;EACjC,+BAA+B;EAC/B,kCAAkC;;EAElC,gCAAgC;EAChC,mCAAmC;EACnC,sCAAsC;EACtC,yCAAyC;;EAEzC,4BAA4B;EAC5B,+BAA+B;EAC/B,2BAA2B;EAC3B,8BAA8B;AAChC","sourcesContent":[":root {\n  --tb-blue: #001d42;\n  --tb-middle-blue: #405571;\n  --tb-green: #21cc99;\n  --tb-light-green: #27f3b5;\n  --tb-gray-1: #707070;\n  --tb-gray-2: #b3b3b3;\n  --tb-gray-3: #d9d9d9;\n  --tb-gray-4: #f2f2f2;\n\n  --dark-blue: var(--tb-blue);\n  --light-blue: #00ceff;\n  --bg-light-blue: #7fe6fe;\n  --orange: #f2662e;\n  --bg-orange: #fbe0d5;\n  --red: tomato;\n\n  /* tag colors */\n  --tag-darkblue: hsl(240, 55%, 30%);\n  --tag-darkblue-bg: hsl(240, 55%, 88%);\n  --tag-lightblue: hsl(212, 100%, 60%);\n  --tag-lightblue-bg: hsl(212, 100%, 95%);\n\n  --tag-red: hsl(0, 68%, 40%);\n  --tag-red-bg: hsl(0, 68%, 88%);\n  --tag-pink: hsl(340, 86%, 65%);\n  --tag-pink-bg: hsl(340, 86%, 95%);\n\n  --tag-darkgreen: hsl(120, 70%, 30%);\n  --tag-darkgreen-bg: hsl(120, 70%, 88%);\n  --tag-lightgreen: hsl(120, 66%, 40%);\n  --tag-lightgreen-bg: hsl(120, 66%, 95%);\n\n  --tag-brown: hsl(19, 56%, 30%);\n  --tag-brown-bg: hsl(19, 56%, 88%);\n  --tag-orange: hsl(17, 88%, 50%);\n  --tag-orange-bg: hsl(17, 88%, 88%);\n\n  --tag-purple: hsl(271, 76%, 40%);\n  --tag-purple-bg: hsl(271, 76%, 88%);\n  --tag-lightpurple: hsl(271, 100%, 70%);\n  --tag-lightpurple-bg: hsl(271, 100%, 95%);\n\n  --tag-black: hsl(0, 0%, 10%);\n  --tag-black-bg: hsl(0, 0%, 88%);\n  --tag-gray: hsl(0, 0%, 50%);\n  --tag-gray-bg: hsl(0, 0%, 88%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
