// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: rgba(0, 0, 0, 10%);
  padding: 15px 30px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/structure/footer.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,kBAAkB;AACpB","sourcesContent":["footer {\n  background-color: rgba(0, 0, 0, 10%);\n  padding: 15px 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
