import { createSelector } from 'reselect'

const _editor = ({ saleId, ...rest }) => {
  return {
    saleId,
    isNew: saleId ? false : true,
    ...rest,
  }
}

export const editor = createSelector(state => state.sale.editor, _editor)
