import * as ActionUtility from '../../../../utilities/ActionUtility'
import NotificationEnum from '../../../../constants/NotificationEnum'

export const SET_PRODUCT_STAMP_CONDITION = 'SaleActivateCondition.SET_PRODUCT_STAMP_CONDITION'
export const SET_STAMP_CONDITION = 'SaleActivateCondition.SET_STAMP_CONDITION'
export const SET_STAMP = 'SaleActivateCondition.SET_STAMP'
export const CLEAR = 'Stamp.CLEAR'
export const REMOVE_PRODUCT = 'Stamp.REMOVE_PRODUCT'

export function setProductStampCondition(data) {
  return ActionUtility.createAction(SET_PRODUCT_STAMP_CONDITION, data)
}
export function setStamp(data) {
  return ActionUtility.createAction(SET_STAMP, data)
}
export function setStampCondition(data) {
  return ActionUtility.createAction(SET_STAMP_CONDITION, data, false, {
    notification: {
      message: 'Configuração de selos alterada com sucesso. Agora salve para confirmar a condição de ativação!',
      type: NotificationEnum.Success,
    },
  })
}
export function removeProduct(index) {
  return ActionUtility.createAction(REMOVE_PRODUCT, index)
}
export function clear() {
  return ActionUtility.createAction(CLEAR)
}
