import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

export async function get(saleId) {
  return EffectUtility.get(environment.managerApi.endpoints.sale.summary.replace(':saleId', `${saleId}`))
}

export async function changeStatus(saleId, status) {
  return EffectUtility.post(environment.managerApi.endpoints.sale.changeStatus.replace(':saleId', `${saleId}`).replace(':status', `${status}`), null)
}
