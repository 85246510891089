import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './PromoterEffects'

export const REQUEST_IDENTIFICATION_INFO = 'PromoterCompany.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'PromoterCompany.REQUEST_IDENTIFICATION_INFO_FINISHED'

export function identificationInfo(nameSearch) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.identificationInfo, nameSearch)
  }
}
