import NotificationEnum from '../../../constants/NotificationEnum'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './ProductImportEffects'

export const REQUEST_IMPORT = 'ProductImportActions.REQUEST_IMPORT'
export const REQUEST_IMPORT_FINISHED = 'ProductImportActions.REQUEST_IMPORT_FINISHED'

export const REQUEST_SEARCH = 'ProductImportActions.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'ProductImportActions.REQUEST_SEARCH_FINISHED'

export const SET_FILTER = 'ProductImportActions.SET_FILTER'
export const SET_PAGINATION = 'ProductImportActions.SET_PAGINATION'

export const CLEAR_FILTER = 'ProductImportActions.CLEAR_FILTER'
export const CLEAR = 'ProductImportActions.CLEAR'

export const importProduct = file => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_IMPORT,
      Effects.importProduct,
      {
        notification: {
          message: 'Importação iniciada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      file
    )
  }
}

export const search = _ => {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.search,
      getState().productImport.search.filter,
      getState().productImport.search.pagination
    )
  }
}

export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)

export const clearFilter = _ => ActionUtility.createAction(CLEAR_FILTER)

export const clear = _ => ActionUtility.createAction(CLEAR)
