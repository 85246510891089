import { getAddressEffect } from './AddressEffects'
import * as ActionUtility from '../../utilities/ActionUtility'

export const REQUEST_GET_ADDRESS = 'Address.REQUEST_GET_ADDRESS'
export const REQUEST_GET_ADDRESS_FINISHED = 'Address.REQUEST_GET_ADDRESS_FINISHED'

export const CLEAR = 'Address.CLEAR'

export const getAddress = zipCode => {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_ADDRESS, getAddressEffect, zipCode)
  }
}

export const clearAddress = () => ActionUtility.createAction(CLEAR)
