import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from '../store/StoreActions'

export const initialState = {
  search: {
    request: {
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
      filter: {
        ids: null,
        name: null,
        chainId: null,
        active: null,
      },
    },
    response: {
      numberOfRows: 0,
      data: [],
    },
  },
  import: {
    response: [],
  },
  currentStore: {
    id: null,
    name: null,
    chainId: null,
    externalCode: null,
    place: {},
    tags: [],
    workingPeriods: [],
    attributes: [],
    isCreatedOnSoftwareExpress: false,
  },
}

const storeReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.response = payload
    })
  },
  [Actions.REQUEST_EDIT_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.edit = payload
    })
  },
  [Actions.REQUEST_GET_BY_ID_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentStore = payload
    })
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.request.pagination = payload
    })
  },
  [Actions.REQUEST_ENABLE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.enable = payload
    })
  },
  [Actions.REQUEST_DISABLE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.disable = payload
    })
  },
  [Actions.REQUEST_IMPORT_GET_ALL_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.import.response = payload
    })
  },
  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.request.filter = {
        ...initialState.search.request.filter,
        ...payload,
      }
    })
  },
  [Actions.CLEAR_FILTER](state) {
    return produce(state, draftState => {
      draftState.search.request.filter = initialState.search.request.filter
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
})

export default storeReducer
