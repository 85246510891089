import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './UserInterfaceActions'

export const initialState = {
  sidebar: {
    visible: true,
  },
}

const userInterfaceReducer = baseReducer(initialState, {
  [Actions.SET_SIDEBAR_VISIBLE](state, action) {
    return produce(state, draftState => {
      draftState.sidebar.visible = action.payload
    })
  },
})

export default userInterfaceReducer
