import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

export async function searchSelected(saleId, filters) {
  return EffectUtility.post(environment.managerApi.endpoints.sale.eligibles.products.search.replace(':saleId', `${saleId}`), filters)
}

export async function searchAvailable(saleId, filters) {
  return EffectUtility.post(environment.managerApi.endpoints.sale.eligibles.products.search.replace(':saleId', `${saleId}`), filters)
}

export async function searchProductsNonEligible(saleId, filter) {
  var queryArray = []

  if (filter.filter.name !== undefined && filter.filter.name !== null) queryArray = [...queryArray, `filter.name=${filter.filter.name}`]

  if (filter.filter.type !== undefined && filter.filter.type !== null) queryArray = [...queryArray, `filter.type=${filter.filter.type}`]

  if (filter.filter.code !== undefined && filter.filter.code !== null) queryArray = [...queryArray, `filter.code=${filter.filter.code}`]

  if (filter.filter.chainId !== undefined && filter.filter.chainId !== null) queryArray = [...queryArray, `filter.chainId=${filter.filter.chainId}`]

  if (filter.filter.category !== undefined && filter.filter.category !== null)
    queryArray = [...queryArray, `filter.category=${filter.filter.category}`]

  if (filter.filter.manufacturerName !== undefined && filter.filter.manufacturerName !== null)
    queryArray = [...queryArray, `filter.manufacturerName=${filter.filter.manufacturerName}`]

  if (filter.filter.brandName !== undefined && filter.filter.brandName !== null)
    queryArray = [...queryArray, `filter.brandName=${filter.filter.brandName}`]

  if (filter.filter.selected !== undefined && filter.filter.selected !== null)
    queryArray = [...queryArray, `filter.selected=${filter.filter.selected}`]

  if (filter.filter.visibility !== undefined && filter.filter.visibility !== null)
    queryArray = [...queryArray, `filter.visibility=${filter.filter.visibility}`]

  if (filter.pagination.pageSize !== undefined) queryArray = [...queryArray, `pagination.pageSize=${filter.pagination.pageSize}`]

  if (filter.pagination.pageNumber !== undefined) queryArray = [...queryArray, `pagination.pageNumber=${filter.pagination.pageNumber}`]

  var queryString = queryArray.join('&')

  return EffectUtility.get(`${environment.managerApi.endpoints.sale.nonEligibles.products.search.replace(':saleId', `${saleId}`)}?${queryString}`)
}

export async function add(saleId, data) {
  return EffectUtility.put(environment.managerApi.endpoints.sale.eligibles.products.update.replace(':saleId', `${saleId}`), data)
}

export async function changeAllowAnyProduct(saleId, allowAnyProduct) {
  const filter = {
    allowAnyProduct: !!allowAnyProduct,
    products: [],
    nonEligibleProducts: [],
  }
  return EffectUtility.put(environment.managerApi.endpoints.sale.eligibles.products.update.replace(':saleId', `${saleId}`), filter)
}

export async function vinculateByFilter(saleId, filters) {
  return EffectUtility.post(environment.managerApi.endpoints.sale.eligibles.products.vinculateByFilter.replace(':saleId', `${saleId}`), filters)
}

export async function removeProducts(saleId, products) {
  if (!(products instanceof Array)) {
    products = [products]
  }

  return EffectUtility.postDataWithoutTransformation(
    environment.managerApi.endpoints.sale.eligibles.products.remove.replace(':saleId', `${saleId}`),
    products
  )
}

export async function removeProductsNonEligible(saleId, products) {
  if (!(products instanceof Array)) {
    products = [products]
  }

  return EffectUtility.postDataWithoutTransformation(
    environment.managerApi.endpoints.sale.nonEligibles.products.remove.replace(':saleId', `${saleId}`),
    products
  )
}

export async function addProducts(saleId, products) {
  if (!(products instanceof Array)) {
    products = [products]
  }

  return EffectUtility.postDataWithoutTransformation(
    environment.managerApi.endpoints.sale.eligibles.products.add.replace(':saleId', `${saleId}`),
    products
  )
}

export async function addProductsNonEligible(saleId, products) {
  if (!(products instanceof Array)) {
    products = [products]
  }

  return EffectUtility.postDataWithoutTransformation(
    environment.managerApi.endpoints.sale.nonEligibles.products.add.replace(':saleId', `${saleId}`),
    products
  )
}
