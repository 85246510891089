import * as ActionUtility from '../../../utilities/ActionUtility'
import { searchEffect, searchByNameEffect } from './CategoryEffects'

export const REQUEST_SEARCH = 'CompanyCategory.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'CompanyCategory.REQUEST_SEARCH_FINISHED'

export const REQUEST_SEARCH_BY_NAME = 'CompanyCategory.REQUEST_SEARCH_BY_NAME'
export const REQUEST_SEARCH_BY_NAME_FINISHED = 'CompanyCategory.REQUEST_SEARCH_BY_NAME_FINISHED'

export const CLEAR = 'CompanyCategory.CLEAR'

export const SET_PAGINATION = 'CompanyCategory.SET_PAGINATION'
export const CLEAR_PAGINATION = 'CompanyCategory.CLEAR_PAGINATION'

export const SET_FILTER = 'CompanyCategory.SET_FILTER'
export const CLEAR_FILTER = 'CompanyCategory.CLEAR_FILTER'

export const searchByName = categoryName => {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH_BY_NAME, searchByNameEffect, categoryName)
  }
}
export const search = () => {
  return async (dispatch, getState) => {
    const payload = getState().company.category.search.request
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH, searchEffect, payload)
  }
}

export const clear = () => ActionUtility.createAction(CLEAR)

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)
export const clearPagination = () => ActionUtility.createAction(CLEAR_PAGINATION)

export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)
export const clearFilter = () => ActionUtility.createAction(CLEAR_FILTER)
