import { chain, groupBy } from 'lodash'
import NotificationEnum from '../../../constants/NotificationEnum'
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as NotificationActions from '../../notification/NotificationActions'
import * as Effects from './SaleStoreEffects'
import { ProfileTypeKeys } from '../../../constants/ProfileTypeEnum'
import { identificationInfo as chainIdentificationInfo } from '../../company/chain/ChainActions'

const _ = { chain, groupBy }

export const REQUEST_SEARCH_SELECTED = 'SaleStoreActions.REQUEST_SEARCH_SELECTED'
export const REQUEST_SEARCH_SELECTED_FINISHED = 'SaleStoreActions.REQUEST_SEARCH_SELECTED_FINISHED'
export const REQUEST_SEARCH_AVAILABLE = 'SaleStoreActions.REQUEST_SEARCH_AVAILABLE'
export const REQUEST_SEARCH_AVAILABLE_FINISHED = 'SaleStoreActions.REQUEST_SEARCH_AVAILABLE_FINISHED'
export const SET_FILTER = 'SaleStoreActions.SET_FILTER'
export const SET_PAGINATION = 'SaleStoreActions.SET_PAGINATION'
export const SET_AVAILABLE_FILTER = 'SaleStoreActions.SET_AVAILABLE_FILTER'
export const SET_AVAILABLE_PAGINATION = 'SaleStoreActions.SET_AVAILABLE_PAGINATION'

export const REQUEST_ADD_STORE = 'SaleStoreActions.REQUEST_ADD_STORE'
export const REQUEST_ADD_STORE_FINISHED = 'SaleStoreActions.REQUEST_ADD_STORE_FINISHED'
export const REQUEST_VINCULATE_FROM_FILTER = 'REQUEST_VINCULATE_FROM_FILTER.REQUEST_ADD_STORE_FROM_FILTER'
export const REQUEST_VINCULATE_FROM_FILTER_FINISHED = 'SaleStoreActions.REQUEST_VINCULATE_FROM_FILTER_FINISHED'
export const REQUEST_REMOVE_STORE = 'SaleStoreActions.REQUEST_REMOVE_STORE'
export const REQUEST_REMOVE_STORE_FINISHED = 'SaleStoreActions.REQUEST_REMOVE_STORE_FINISHED'
export const RESET_SELECTED_FILTER = 'SaleStoreActions.RESET_SELECTED_FILTER'
export const RESET_AVAILABLE_FILTER = 'SaleStoreActions.RESET_AVAILABLE_FILTER'

export const SET_CHAINID_FILTER = 'SaleStoreActions.SET_CHAINID_FILTER'

export function searchSelected(saleId) {
  return async (dispatch, getState) => {
    const { pagination, filter } = getState().sale.stores.selected
    const params = {
      pagination,
      filter: {
        selected: true,
        ...filter,
      },
    }
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH_SELECTED, Effects.search, saleId, params)
  }
}

export function searchAvailable(saleId) {
  return async (dispatch, getState) => {
    const { pagination, filter } = getState().sale.stores.available

    const params = {
      pagination,
      filter: {
        selected: false,
        ...filter,
      },
    }
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH_AVAILABLE, Effects.search, saleId, params)
  }
}

export function addStores(saleId, stores) {
  return async (dispatch, getState) => {
    const groupedStores = _.chain(stores)
      .groupBy('chainId')
      .map((value, key) => ({ chainId: key, stores: value.map(item => item.storeId) }))
      .value()

    dispatch(ActionUtility.createAction(REQUEST_ADD_STORE))

    let anyError = false
    for (const group of groupedStores) {
      const response = await Effects.add(saleId, group)
      const isError = response instanceof HttpErrorResponseModel

      if (isError) {
        anyError = true
        dispatch(NotificationActions.add(response.message, 'Ops !', NotificationEnum.Error))
      }
    }

    if (!anyError) {
      dispatch(
        ActionUtility.createAction(REQUEST_ADD_STORE_FINISHED, null, false, {
          notification: {
            description: null,
            message: 'Lojas adicionadas com sucesso.',
            type: NotificationEnum.Success,
          },
        })
      )
    } else {
      dispatch(ActionUtility.createAction(REQUEST_ADD_STORE_FINISHED, null, false))
    }
  }
}

export function vinculateFromFilter(saleId, chainId, filter) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_VINCULATE_FROM_FILTER,
      Effects.vinculateFromFilter,
      {
        notification: {
          description: null,
          message: 'Lojas adicionadas com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      chainId,
      filter
    )
  }
}

export function removeStores(saleId, stores) {
  return async (dispatch, getState) => {
    const groupedStores = _.chain(stores)
      .groupBy('chainId')
      .map((value, key) => ({ chainId: key, stores: value.map(item => item.storeId) }))
      .value()

    dispatch(ActionUtility.createAction(REQUEST_REMOVE_STORE))

    let anyError = false

    for (const group of groupedStores) {
      const response = await Effects.remove(saleId, group.chainId, group.stores)
      const isError = response instanceof HttpErrorResponseModel

      if (isError) {
        anyError = true
        dispatch(NotificationActions.add(response.message, 'Ops !', NotificationEnum.Error))
      }
    }

    if (!anyError) {
      dispatch(
        ActionUtility.createAction(REQUEST_REMOVE_STORE_FINISHED, null, false, {
          notification: {
            description: null,
            message: 'Lojas removidas com sucesso.',
            type: NotificationEnum.Success,
          },
        })
      )
    } else {
      dispatch(ActionUtility.createAction(REQUEST_REMOVE_STORE_FINISHED, null, false))
    }
  }
}

export function setSelectedFilter(filter) {
  return ActionUtility.createAction(SET_FILTER, filter)
}

export function setSelectedPagination(pagination) {
  return ActionUtility.createAction(SET_PAGINATION, pagination)
}

export function setAvailableFilter(filter) {
  return ActionUtility.createAction(SET_AVAILABLE_FILTER, filter)
}

export function setAvailablePagination(pagination) {
  return ActionUtility.createAction(SET_AVAILABLE_PAGINATION, pagination)
}

export function resetSelectedFilter() {
  return ActionUtility.createAction(RESET_SELECTED_FILTER)
}

export function resetAvailableFilter() {
  return ActionUtility.createAction(RESET_AVAILABLE_FILTER)
}

export function bindChainIdFilter(idTokenClaims) {
  return (dispatch, getState) => {
    const companyId = idTokenClaims['extension_companyId']
    const isChannelProfile = idTokenClaims['extension_profile'] === ProfileTypeKeys.Channel

    if (companyId && isChannelProfile) {
      dispatch(chainIdentificationInfo(companyId))
      dispatch(ActionUtility.createAction(SET_CHAINID_FILTER, companyId))
    }
  }
}
