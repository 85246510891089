import { createSelector } from 'reselect'

export const campaigns = state => state.campaign.all

export const identificationInfo = state => state.campaign.identificationInfo

export const filter = state => state.campaign.search.filter

export const pagination = state => state.campaign.search.pagination

export const searchResponse = state => state.campaign.searchResponse

export const activeFilter = state => state.campaign.search.filter.active

export const identificationInfoWithoutIdSpecified = createSelector(
  state => state.campaign.identificationInfo,
  (state, id) => id,
  _campaignSelectWithoutIdSpecified
)

export const currentCampaign = state => state.campaign.currentCampaign

export const currentCampaignAttributes = state => state.campaign.currentCampaign.attributes

function _campaignSelectWithoutIdSpecified(campaigns, id) {
  return campaigns.filter(item => item.id !== id)
}
