import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './CardBinSegmentActions'

export const initialState = {
  currentSegment: {
    id: null,
    name: null,
    status: true,
  },
  identificationInfo: [],
  search: {
    filter: {
      name: null,
      active: null,
    },
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
  },
  searchResponse: {
    numberOfRows: 0,
    data: [],
  },
}

const cardBinSegmentReducer = baseReducer(initialState, {
  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.filter = payload
    })
  },
  [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.searchResponse.data = payload.data
      draftState.searchResponse.numberOfRows = payload.numberOfRows
    })
  },
  [Actions.REQUEST_CREATE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentSegment.id = payload
    })
  },
  [Actions.REQUEST_GET_BY_ID_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentSegment = payload
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.pagination = payload
    })
  },
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload
    })
  },
})

export default cardBinSegmentReducer
