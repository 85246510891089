const SaleEditorSteps = {
  BasicInfo: 'basic-info',
  ActivateCondition: 'activate-condition',
  Benefit: 'benefit',
  Stores: 'stores',
  Products: 'products',
  Participants: 'participants',
  FeedbackMessages: 'feedback-messages',
  Summary: 'summary',
  Payment: 'payment',
}

export default SaleEditorSteps
