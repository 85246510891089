import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './ParticipationAuthorizationEffects'

export const REQUEST_GET_BY_ID = 'ParticipationAuthorizationActions.REQUEST_GET_BY_ID'
export const REQUEST_GET_BY_ID_FINISHED = 'ParticipationAuthorizationActions.REQUEST_GET_BY_ID_FINISHED'

export const REQUEST_CREATE = 'ParticipationAuthorizationActions.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'ParticipationAuthorizationActions.REQUEST_CREATE_FINISHED'

export const REQUEST_UPDATE = 'ParticipationAuthorizationActions.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'ParticipationAuthorizationActions.REQUEST_UPDATE_FINISHED'

export const REQUEST_SEARCH = 'ParticipationAuthorizationActions.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'ParticipationAuthorizationActions.REQUEST_SEARCH_FINISHED'

export const SET_FILTER = 'ParticipationAuthorizationActions.SET_FILTER'
export const SET_PAGINATION = 'ParticipationAuthorizationActions.SET_PAGINATION'
export const CLEAR = 'ParticipationAuthorizationActions.CLEAR'
export const CLEAR_FILTER = 'ParticipationAuthorizationActions.CLEAR_FILTER'

export const SET_CURRENT = 'ParticipationAuthorizationActions.SET_CURRENT'

export function search() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.search,
      getState().ParticipationAuthorization.search.filter,
      getState().ParticipationAuthorization.search.pagination
    )
  }
}

export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)

export const clear = _ => ActionUtility.createAction(CLEAR)

export const clearFilter = _ => ActionUtility.createAction(CLEAR_FILTER)

export function setCurrent(item) {
  return ActionUtility.createAction(SET_CURRENT, item)
}
