// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-layout .site-layout-background {
  background: #fff;
}
#root section {
  overflow: initial;
}
.ant-layout {
  background-color: transparent !important;
}
.ant-layout-content {
  min-height: auto;
}
main.site-page-content {
  background-color: white;
  padding: 20px;
}
.ant-spin-nested-loading .ant-spin-container li button:hover {
  background-color: var(--light-blue);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/structure/page-content.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,mCAAmC;AACrC","sourcesContent":[".site-layout .site-layout-background {\n  background: #fff;\n}\n#root section {\n  overflow: initial;\n}\n.ant-layout {\n  background-color: transparent !important;\n}\n.ant-layout-content {\n  min-height: auto;\n}\nmain.site-page-content {\n  background-color: white;\n  padding: 20px;\n}\n.ant-spin-nested-loading .ant-spin-container li button:hover {\n  background-color: var(--light-blue);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
