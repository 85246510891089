import NotificationEnum from '../../../constants/NotificationEnum'
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as Effects from './SaleFeedbackMessagesEffects'
import { push } from 'connected-react-router'

export const REQUEST_GET = 'SaleFeedbackMessages.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'SaleFeedbackMessages.REQUEST_GET_FINISHED'
export const REQUEST_UPDATE = 'SaleFeedbackMessages.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'SaleFeedbackMessages.REQUEST_UPDATE_FINISHED'
export const SET = 'SaleFeedbackMessages.SET'

export function get(saleId) {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get, saleId)
  }
}

export function update(saleId) {
  return async (dispatch, getState) => {
    let { feedbackMessages } = getState().sale

    const model = await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effects.update,
      {
        notification: {
          description: null,
          message: 'Mensagens atualizadas com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      feedbackMessages
    )
    const isError = model instanceof HttpErrorResponseModel
    if (!isError) {
      return model
    }
  }
}

export function set(feedbackMessages) {
  return ActionUtility.createAction(SET, feedbackMessages)
}
