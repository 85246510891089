import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

export async function get(saleId) {
  return EffectUtility.get(environment.managerApi.endpoints.sale.activateCondition.replace(':saleId', `${saleId}`))
}

export async function patch(saleId, activateConditions) {
  return EffectUtility.jsonPatch(environment.managerApi.endpoints.sale.activateCondition.replace(':saleId', `${saleId}`), activateConditions)
}
