import * as ActionUtility from '../../../utilities/ActionUtility'
import { addOperationToAllAttribute, createJsonPatch } from '../../../utilities/JsonPatchUtility'
import * as DelimitationsEffects from './DelimitationsEffects'
import NotificationEnum from '../../../constants/NotificationEnum'

export const CLEAN = 'SaleDelimitations.CLEAN'

export const REQUEST_GET = 'SaleDelimitations.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'SaleDelimitations.REQUEST_GET_FINISHED'

export const REQUEST_UPDATE = 'SaleDelimitations.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'SaleDelimitations.REQUEST_UPDATE_FINISHED'

export function get(saleId) {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, DelimitationsEffects.get, saleId)
  }
}

export function patch(saleId, delimitations) {
  const delimitationsWithOperation = addOperationToAllAttribute(delimitations, 'add')

  var jsonPatchRequest = createJsonPatch(delimitationsWithOperation)

  return async dispatch => await ActionUtility.createThunkEffect(dispatch, REQUEST_UPDATE, DelimitationsEffects.patch, saleId, jsonPatchRequest)
}

export function patchWithMeta(saleId, delimitations, message) {
  const delimitationsWithOperation = addOperationToAllAttribute(delimitations, 'add')

  var jsonPatchRequest = createJsonPatch(delimitationsWithOperation)

  return async dispatch =>
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      DelimitationsEffects.patch,
      {
        notification: {
          description: null,
          message: message,
          type: NotificationEnum.Success,
        },
      },
      saleId,
      jsonPatchRequest
    )
}

export function clean() {
  return ActionUtility.createAction(CLEAN)
}
