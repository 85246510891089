import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './DelimitationsActions'

export const initialState = {
  participantBlacklistBaseIds: [],
  participationLimitGroupId: '',
  budgetId: null,
}

const saleBasicInfoReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_FINISHED](_, { payload }) {
    return payload
  },
  [Actions.CLEAN]() {
    return initialState
  },
})

export default saleBasicInfoReducer
