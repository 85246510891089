export const dateConfig = {
  dateFormat: 'YYYY-MM-DDTHH:mm:ss',
  displayFormat: 'DD/MM/YYYY HH:mm',
  displayFormatWithoutHour: 'DD/MM/YYYY',
  displayFormatWithSeconds: 'DD/MM/YYYY HH:mm:ss',
  displayLocalization: 'pt-Br',
  mommentLocalization: 'America/Sao_Paulo',
}

export const daysOfWeek = [
  { label: 'Segunda-Feira', value: 0 },
  { label: 'Terça-Feira', value: 1 },
  { label: 'Quarta-Feira', value: 2 },
  { label: 'Quinta-Feira', value: 3 },
  { label: 'Sexta-Feira', value: 4 },
  { label: 'Sábado', value: 5 },
  { label: 'Domingo', value: 6 },
]

export const daysOfWeekEnum = {
  0: 'Segunda-Feira',
  1: 'Terça-Feira',
  2: 'Quarta-Feira',
  3: 'Quinta-Feira',
  4: 'Sexta-Feira',
  5: 'Sábado',
  6: 'Domingo',
}
