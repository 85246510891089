import { Select } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { identificationInfo } from '../../../selectors/campaign/CampaignSelector'
import { selectRequesting } from '../../../selectors/requesting/RequestingSelector'
import * as CampaignActions from '../../../stores/campaign/CampaignActions'
import { selectOptionsFilter } from '../../../utilities/SelectUtility'

const CampaignSelect = props => {
  const dispatch = useDispatch()
  const options = useSelector(state => identificationInfo(state))
  const isRequesting = useSelector(state => selectRequesting(state, [CampaignActions.REQUEST_GET_IDENTIFICATION_INFO]))

  useEffect(() => {
    dispatch(CampaignActions.identificationInfo())
  }, [dispatch])

  return (
    <>
      <Select showSearch allowClear loading={isRequesting} filterOption={selectOptionsFilter} {...props}>
        {options &&
          options.map(x => (
            <Select.Option key={x.id} value={x.id} label={x.name}>
              {x.name}
            </Select.Option>
          ))}
      </Select>
    </>
  )
}

CampaignSelect.propTypes = {
  placeholder: PropTypes.string,
}

export default CampaignSelect
