import React, { useEffect, useCallback, useState } from 'react'
import { Input, Form, Select, Button, Row, Col } from 'antd'
import { add as addNotification } from '../../../../stores/notification/NotificationActions'
import { useDispatch } from 'react-redux'
import NotificationEnum from '../../../../constants/NotificationEnum'
import { ASSIGNED_TENANT_STORAGE_KEY, ASSIGNED_AVAILABLE_TENANTS_STORAGE_KEY } from '../../../../constants/AssignedTenantConstants'
import PropTypes from 'prop-types'

const ChangeAssignedTenantForm = ({ setIsVisible }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [previousTenants, setPreviousTenants] = useState([])
  const [showNewAssignedTenantInputs, setShowNewAssignedTenantInputs] = useState(false)
  const ADD_NEW_TENANT_SELECT_VALUE = 'addNewTenant'

  useEffect(() => {
    setPreviousTenants(JSON.parse(localStorage.getItem(ASSIGNED_AVAILABLE_TENANTS_STORAGE_KEY) ?? '[]'))
  }, [])

  const handleOnFinish = formResult => {
    if (!formResult.previousTenant && !formResult.tenantId && !formResult.tenantName) {
      dispatch(addNotification(null, 'É necessario especificar um tenant prévio ou definir um novo!', NotificationEnum.Error))
      return
    }

    const { tenantId, tenantName } = formResult.previousTenant ? JSON.parse(formResult.previousTenant) : formResult

    localStorage.setItem(ASSIGNED_TENANT_STORAGE_KEY, JSON.stringify({ tenantId, tenantName }))
    let availableTenants = JSON.parse(localStorage.getItem(ASSIGNED_AVAILABLE_TENANTS_STORAGE_KEY) ?? '[]')

    availableTenants = availableTenants.filter(x => x.tenantId !== tenantId)
    availableTenants.push({ tenantName, tenantId })

    localStorage.setItem(ASSIGNED_AVAILABLE_TENANTS_STORAGE_KEY, JSON.stringify(availableTenants))
    window.location.reload()
  }

  const handleOnSelect = optionValue => {
    optionValue === ADD_NEW_TENANT_SELECT_VALUE && setShowNewAssignedTenantInputs(true)
  }

  const handleOnCancel = () => {
    setShowNewAssignedTenantInputs(false)
    form.resetFields()
    setIsVisible(false)
  }

  const handleOnClickBack = () => {
    setShowNewAssignedTenantInputs(false)
    form.resetFields()
  }

  const getPreviousTenantOptions = useCallback(() => {
    const previousTenantsOptions = previousTenants?.map(item => {
      return { label: `${item.tenantName}-${item.tenantId}`, value: JSON.stringify(item) }
    })
    previousTenantsOptions.unshift({ label: 'Adicionar novo +', value: ADD_NEW_TENANT_SELECT_VALUE })

    return previousTenantsOptions
  }, [previousTenants])

  return (
    <Form onFinish={handleOnFinish} form={form} layout="vertical">
      {!showNewAssignedTenantInputs ? (
        <Form.Item name="previousTenant" label="Escolher tenant previamente selecionado" required={true}>
          <Select options={getPreviousTenantOptions()} onSelect={handleOnSelect} size="large" />
        </Form.Item>
      ) : (
        <>
          <Form.Item name="tenantName" label="Nome do Tenant" required>
            <Input placeholder="Nome do Tenant" />
          </Form.Item>
          <Form.Item name="tenantId" label="ID do Tenant" required>
            <Input placeholder="ID do Tenant" />
          </Form.Item>
        </>
      )}
      <Row>
        <Col span={8}>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Col>
        {showNewAssignedTenantInputs && (
          <Col span={8}>
            <Button onClick={handleOnClickBack}>Voltar</Button>
          </Col>
        )}
        <Col span={8}>
          <Button onClick={handleOnCancel}>Cancelar</Button>
        </Col>
      </Row>
    </Form>
  )
}
export default ChangeAssignedTenantForm

ChangeAssignedTenantForm.propTypes = {
  setIsVisible: PropTypes.func,
}
