import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

const productEndpoints = environment.managerApi.endpoints.product

export async function getById(id) {
  return EffectUtility.get(productEndpoints.getById.replace(':productId', id))
}

export async function create(data) {
  return EffectUtility.post(productEndpoints.create, data)
}

export async function createPublic(data) {
  return EffectUtility.post(productEndpoints.createPublic, data)
}

export async function update(id, data) {
  return EffectUtility.put(productEndpoints.update.replace(':productId', id), data)
}

export async function search(filter, pagination) {
  return EffectUtility.post(productEndpoints.search, { filter: { ...filter }, pagination: { ...pagination } })
}
