import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

const companyEndpoints = environment.managerApi.endpoints.company

export const search = payload => EffectUtility.post(companyEndpoints.search, payload)
export const getById = id => EffectUtility.get(companyEndpoints.getById.replace(':companyId', id))
export const update = ({ payload, id }) => EffectUtility.put(companyEndpoints.update.replace(':companyId', id), payload)
export const create = payload => EffectUtility.post(companyEndpoints.create, payload)
export const changeStatus = ({ id, status, payload }) =>
  EffectUtility.post(companyEndpoints.changeStatus.replace(':companyId', id).replace(':status', status), payload)
export const getReport = filter => EffectUtility.postReturningHeader(companyEndpoints.report, filter)

export const createImage = ({ id, image, tag }) => {
  const formData = new FormData()
  formData.append('file', image)
  formData.append('tag', tag)
  EffectUtility.postFormData(companyEndpoints.createImage.replace(':companyId', id), formData)
}

export const updateImage = ({ id, image, tag, imageId }) => {
  const formData = new FormData()
  formData.append('file', image)
  formData.append('tag', tag)
  EffectUtility.putFormData(companyEndpoints.updateImage.replace(':companyId', id).replace(':imageId', imageId), formData)
}

export const deleteImage = ({ id, imageId }) => EffectUtility.del(companyEndpoints.deleteImage.replace(':companyId', id).replace(':imageId', imageId))
