import { push } from 'connected-react-router'
import NotificationEnum from '../../../constants/NotificationEnum'
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as PayingActions from '../../company/paying/PayingActions'
import * as PromoterActions from '../../company/promoter/PromoterActions'
import * as Effects from './SaleBasicInfoEffects'

export const REQUEST_GET = 'SaleBasicInfo.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'SaleBasicInfo.REQUEST_GET_FINISHED'
export const REQUEST_UPDATE = 'SaleBasicInfo.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'SaleBasicInfo.REQUEST_UPDATE_FINISHED'
export const REQUEST_CREATE = 'SaleBasicInfo.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'SaleBasicInfo.REQUEST_CREATE_FINISHED'
export const REQUEST_NEW_SALE = 'SaleBasicInfo.REQUEST_NEW_SALE'
export const REQUEST_NEW_SALE_FINISHED = 'SaleBasicInfo.REQUEST_NEW_SALE_FINISHED'
export const CLEAN = 'SaleBasicInfo.CLEAN'
export const SET = 'SaleBasicInfo.SET'
export const SET_PROMOTERID = 'SaleBasicInfo.SET_PROMOTERID'
export const SET_PAYINGCOMPANYID = 'SaleBasicInfo.SET_PAYINGCOMPANYID'

export function get(saleId) {
  return async (dispatch, getState) => {
    // await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get, saleId)

    dispatch(ActionUtility.createAction(REQUEST_GET))

    const model = await Effects.get(saleId)
    const isError = model instanceof HttpErrorResponseModel

    //TODO: Provisório, aguardar backend disponibilizar um endpoint que retorna os dados certos
    if (!isError) {
      if (model.promoterId !== '') {
        await dispatch(PromoterActions.identificationInfo(model.promoterId))
      }

      if (model.payingCompanyId !== '') {
        await dispatch(PayingActions.identificationInfo(model.payingCompanyId))
      }

      dispatch(ActionUtility.createAction(REQUEST_GET_FINISHED, model, isError))
    }

    return model
  }
}

export function update(saleId) {
  return async (dispatch, getState) => {
    const { basicInfo } = getState().sale

    const model = await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effects.update,
      {
        notification: {
          description: null,
          message: 'Dados gerais atualizados com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      basicInfo
    )

    const isError = model instanceof HttpErrorResponseModel
  }
}

export function create() {
  return async (dispatch, getState) => {
    const { basicInfo } = getState().sale

    const model = await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      Effects.create,
      {
        notification: {
          description: null,
          message: 'Oferta criada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      basicInfo
    )

    const isError = model instanceof HttpErrorResponseModel
    if (!isError) {
      return model
    }
  }
}

export function clean() {
  return ActionUtility.createAction(CLEAN)
}

export function set(basicInfo) {
  return ActionUtility.createAction(SET, basicInfo)
}

export function newSale(companyId) {
  return async (dispatch, getState) => {
    dispatch(ActionUtility.createAction(REQUEST_NEW_SALE))
    dispatch(ActionUtility.createAction(CLEAN))

    if (companyId) {
      await dispatch(PromoterActions.identificationInfo(companyId))
      const promoterIdenficationInfo = getState().company.promoter.identificationInfo
      promoterIdenficationInfo.find(item => item.id === companyId)
      dispatch(ActionUtility.createAction(SET_PROMOTERID, companyId))

      await dispatch(PayingActions.identificationInfo(companyId))
      const payingCompanyIdentificationInfo = getState().company.paying.identificationInfo
      payingCompanyIdentificationInfo.find(item => item.id === companyId)
      dispatch(ActionUtility.createAction(SET_PAYINGCOMPANYID, companyId))
    }

    dispatch(ActionUtility.createAction(REQUEST_NEW_SALE_FINISHED))
  }
}
