const DEFAULT_DEBOUNCE_TIME = 500
export const defaultDebounce = func => debounce(func, DEFAULT_DEBOUNCE_TIME)

export const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}
