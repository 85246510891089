import React from 'react'
import PropTypes from 'prop-types'

const CustomTooltipButton = ({ text, color, icon, onClick }) => {
  return (
    <button className={'custom-tooltip ' + color} onClick={onClick}>
      {icon}
      <span className="text">{text}</span>
    </button>
  )
}

CustomTooltipButton.displayName = 'CustomTooltipButton'

CustomTooltipButton.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
}

export default CustomTooltipButton
