import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

export async function identificationInfo() {
  return EffectUtility.get(environment.managerApi.endpoints.promotionalCode.identificationInfo)
}

export async function get() {
  return EffectUtility.get(environment.managerApi.endpoints.promotionalCode.get)
}

export async function getDetails(baseId) {
  return EffectUtility.get(environment.managerApi.endpoints.promotionalCode.getDetails.replace(':baseId', baseId))
}

export async function add(data) {
  return EffectUtility.post(environment.managerApi.endpoints.promotionalCode.new, data)
}

export async function update(baseId, data) {
  return EffectUtility.put(environment.managerApi.endpoints.promotionalCode.update.replace(':baseId', baseId), data)
}

export async function updateStatus(baseId, statusId) {
  return EffectUtility.post(
    environment.managerApi.endpoints.promotionalCode.updateStatus.replace(':baseId', baseId).replace(':statusId', statusId),
    null
  )
}

export async function importPromotionalCodes(baseId, file) {
  const formData = new FormData()
  formData.append('file', file)
  return EffectUtility.postFormData(environment.managerApi.endpoints.promotionalCode.import.replace(':baseId', baseId), formData)
}
