import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

export async function searchEffect(request) {
  return EffectUtility.post(environment.managerApi.endpoints.forbiddenWords.search, request)
}

export async function enableEffect(id) {
  return EffectUtility.post(environment.managerApi.endpoints.forbiddenWords.enable.replace(':forbiddenWordId', id))
}

export async function disableEffect(id) {
  return EffectUtility.post(environment.managerApi.endpoints.forbiddenWords.disable.replace(':forbiddenWordId', id))
}

export async function editEffect({ id, body }) {
  return EffectUtility.put(environment.managerApi.endpoints.forbiddenWords.edit.replace(':forbiddenWordId', id), body)
}

export async function createEffect(body) {
  return EffectUtility.post(environment.managerApi.endpoints.forbiddenWords.create, body)
}

export async function getByIdEffect(id) {
  return EffectUtility.get(environment.managerApi.endpoints.forbiddenWords.getById.replace(':forbiddenWordId', id))
}

export async function importEffect(file) {
  const formData = new FormData()
  formData.append('file', file)
  return EffectUtility.postFormData(environment.managerApi.endpoints.forbiddenWords.import, formData)
}
