import React from 'react'
import PropTypes from 'prop-types'

const CustomTooltip = ({ text, color, icon }) => {
  return (
    <div className={'custom-tooltip ' + color}>
      {icon}
      <span className="text">{text}</span>
    </div>
  )
}

CustomTooltip.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.object,
}

export default CustomTooltip
