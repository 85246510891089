import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './ProductActions'

export const initialState = {
  currentProduct: {
    active: true,
    attributes: [],
  },
  search: {
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    filter: {
      name: null,
      type: null,
      code: null,
      chainId: null,
      category: null,
      manufacturerName: null,
      brandName: null,
      visibility: null,
    },
  },
  searchResponse: {
    numberOfRows: 0,
    data: [],
  },
}

const productReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.searchResponse.data = payload.data
      draftState.searchResponse.numberOfRows = payload.numberOfRows
    })
  },
  [Actions.REQUEST_GET_BY_ID_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentProduct = payload
    })
  },
  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.filter = payload
    })
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.pagination = payload
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
  [Actions.CLEAR_FILTER](state) {
    return produce(state, draftState => {
      draftState.search.filter = initialState.search.filter
    })
  },
})

export default productReducer
