import { CheckCircleOutlined, CheckOutlined, ClockCircleOutlined, CloseCircleOutlined, WarningOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import React from 'react'
import { SaleStatus, SaleStatusInfinitive } from '../stores/sale/models/SaleEnums'

const getStatusTagOptions = status => {
  switch (status) {
    case 1:
      return { icon: <WarningOutlined />, color: 'orange' }
    case 2:
      return { icon: <ClockCircleOutlined />, color: 'lightblue' }
    case 3:
      return { icon: <CloseCircleOutlined />, color: 'red' }
    case 5:
      return { icon: <CheckCircleOutlined />, color: 'darkgreen' }
    case 6:
      return { icon: <MinusCircleOutlined />, color: 'gray' }
    case 8:
      return { icon: <CheckOutlined />, color: 'purple' }
  }
}

const getStatusTag = status => {
  return (
    <>
      <Tag {...getStatusTagOptions(status)} key={status} style={{ cursor: 'pointer' }}>
        {SaleStatus[status]}
      </Tag>
    </>
  )
}

const getStatusTagInfinit = status => {
  return (
    <Tag {...getStatusTagOptions(status)} key={status} style={{ cursor: 'pointer' }}>
      {SaleStatusInfinitive[status]}
    </Tag>
  )
}

const getStatusImportTagOptions = status => {
  switch (status) {
    case 0:
      return { icon: <CheckOutlined />, color: 'purple' }
    case 1:
      return { icon: <ClockCircleOutlined />, color: 'lightblue' }
    case 2:
      return { icon: <CheckCircleOutlined />, color: 'darkgreen' }
    case 3:
      return { icon: <ClockCircleOutlined />, color: 'lightblue' }
    case 4:
      return { icon: <CloseCircleOutlined />, color: 'red' }
  }
}
export { getStatusTagOptions, getStatusImportTagOptions, getStatusTag, getStatusTagInfinit }
