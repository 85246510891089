import { PermissionProvider } from '@aberto/react-common'
import { EventType, InteractionStatus, InteractionType } from '@azure/msal-browser'
import { AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react'
import environment from 'environment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RouteEnum from '../constants/RouteEnum'
import { loginRequest } from '../contexts/AuthConfig'
import { SaleEditorProvider } from '../contexts/SaleEditorContext'
import axiosInstance from '../utilities/HttpUtility'

const ROLES_CLAIM = 'extension_roles'
const POLICIES_CLAIM = 'extension_policies'
export default function Wrapper({ children }) {
  const isAuthenticated = useIsAuthenticated()
  const { instance, inProgress, accounts } = useMsal()
  const [forgotPassword, setForgotPassword] = useState(false)

  const account = useAccount(accounts[accounts.length - 1] || {}) || {}
  const { idTokenClaims } = account

  const roles = idTokenClaims?.[ROLES_CLAIM] ? idTokenClaims?.[ROLES_CLAIM].split(',') : []
  const policies = idTokenClaims?.[POLICIES_CLAIM] ? idTokenClaims?.[POLICIES_CLAIM].split(',') : []

  const checkAndSetActiveAccount = account => {
    instance.setActiveAccount(account)
  }

  useEffect(() => {
    const callbackId = instance.addEventCallback(event => {
      // console.info('[Event]', event)
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            setForgotPassword(true)
            instance.loginRedirect(environment.managerApi.authentication.authorities.forgotPassword)
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(environment.managerApi.authentication.authorities.forgotPassword).catch(e => {
              return
            })
          }
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (event?.payload) {
          if (event.payload.idTokenClaims['acr'] === environment.managerApi.authentication.names.forgotPassword) {
            return instance.logout()
          } else if (event.payload.idTokenClaims['acr'] === environment.managerApi.authentication.names.editProfile) {
            checkAndSetActiveAccount(event.payload.account)
          }
        }
      }
    })

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [])

  if (inProgress === InteractionStatus.None && !isAuthenticated && !forgotPassword) {
    instance.loginRedirect(loginRequest)
  }

  return (
    <AuthenticatedTemplate>
      <PermissionProvider
        axiosInstance={axiosInstance}
        useDispatch={useDispatch}
        useSelector={useSelector}
        roles={roles}
        policies={policies}
        signOut={RouteEnum.SignOut}
        authorizationPolicyRolesRoute={environment.managerApi.endpoints.authorization.policy.roles}
      >
        <SaleEditorProvider>{children}</SaleEditorProvider>
      </PermissionProvider>
    </AuthenticatedTemplate>
  )
}

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
}
