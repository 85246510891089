import NotificationEnum from '../../../constants/NotificationEnum'
import * as ActionUtility from '../../../utilities/ActionUtility'
import * as SubCategoryEffects from './SubCategoryEffects'

export const REQUEST_SEARCH = 'CompanySubCategory.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'CompanySubCategory.REQUEST_SEARCH_FINISHED'

export const REQUEST_GET_BY_ID = 'CompanySubCategory.REQUEST_GET_BY_ID'
export const REQUEST_GET_BY_ID_FINISHED = 'CompanySubCategory.REQUEST_GET_BY_ID_FINISHED'

export const REQUEST_CREATE = 'CompanySubCategory.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'CompanySubCategory.REQUEST_CREATE_FINISHED'

export const REQUEST_UPDATE = 'CompanySubCategory.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'CompanySubCategory.REQUEST_UPDATE_FINISHED'

export const CLEAR = 'CompanySubCategory.CLEAR'
export const SET_PAGINATION = 'CompanySubCategory.SET_PAGINATION'
export const SET_FILTER = 'CompanySubCategory.SET_FILTER'
export const CLEAR_FILTER = 'CompanySubCategory.CLEAR_FILTER'
export const CLEAN_CURRENT_SUB_CATEGORY = 'CompanySubCategory.CLEAN_CURRENT_SUB_CATEGORY'

export const search = categoryId => {
  return async (dispatch, getState) => {
    const payload = getState().company.subCategory.search.request
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH, SubCategoryEffects.search, { payload, categoryId })
  }
}

export const getById = (categoryId, subCategoryId) => {
  return async dispatch =>
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_BY_ID, SubCategoryEffects.getById, { categoryId, subCategoryId })
}

export const create = (categoryId, payload) => {
  return async dispatch =>
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      SubCategoryEffects.create,
      {
        notification: {
          message: 'Adicionado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      { payload, categoryId }
    )
}

export const update = (categoryId, subCategoryId, payload) => {
  return async dispatch =>
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      SubCategoryEffects.update,
      {
        notification: {
          message: 'Alterado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      { payload, categoryId, subCategoryId }
    )
}

export const disable = (categoryId, subCategoryId) => {
  return async dispatch =>
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      SubCategoryEffects.disable,
      {
        notification: {
          message: 'Desativada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      { categoryId, subCategoryId }
    )
}

export const enable = (categoryId, subCategoryId) => {
  return async dispatch =>
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      SubCategoryEffects.enable,
      {
        notification: {
          message: 'Ativada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      { categoryId, subCategoryId }
    )
}

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)
export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)
export const clearFilter = () => ActionUtility.createAction(CLEAR_FILTER)
export const clear = () => ActionUtility.createAction(CLEAR)
export const cleanCurrentSubCategory = () => ActionUtility.createAction(CLEAN_CURRENT_SUB_CATEGORY)
