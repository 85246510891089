export const SaleStatus = {
  1: 'Alterações Pendentes',
  2: 'Aprovação Solicitada',
  3: 'Alterações Reprovadas',
  5: 'Ativa',
  6: 'Inativa',
  8: 'Finalizada',
}

export const SaleStatusInfinitive = {
  1: 'Alterações Pendentes',
  2: 'Solicitar Aprovação',
  3: 'Reprovar',
  5: 'Ativar',
  6: 'Inativar',
  8: 'Finalizar',
}

export const SaleStatusInfinitiveByName = {
  PendingChanges: 1,
  RequestedApprovals: 2,
  ReprovedChanges: 3,
  Active: 5,
  Disabled: 6,
  Finished: 8,
}
