import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

const cardBinSegmentImportEndpoints = environment.managerApi.endpoints.cardBinSegment.import

export async function importBins(segmentId, file) {
  const formData = new FormData()

  formData.append('file', file)
  return await EffectUtility.postFormData(cardBinSegmentImportEndpoints.import.replace(':segmentId', segmentId), formData)
}

export async function search(filter, pagination) {
  return EffectUtility.post(cardBinSegmentImportEndpoints.search, { filter, pagination })
}
