import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './ChainActions'

export const initialState = {
  identificationInfo: [],
}

const chainReducer = baseReducer(initialState, {
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload
    })
  },
})

export default chainReducer
