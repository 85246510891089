import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

const subCategoryEndpoints = environment.managerApi.endpoints.company.subCategory

export const search = async ({ payload, categoryId }) =>
  await EffectUtility.post(subCategoryEndpoints.search.replace(':categoryId', categoryId), payload)

export const getById = async ({ categoryId, subCategoryId }) =>
  await EffectUtility.get(subCategoryEndpoints.getById.replace(':categoryId', categoryId).replace(':subcategoryId', subCategoryId))

export const create = async ({ payload, categoryId }) =>
  await EffectUtility.post(subCategoryEndpoints.create.replace(':categoryId', categoryId), payload)

export const update = async ({ payload, categoryId, subCategoryId }) =>
  await EffectUtility.put(subCategoryEndpoints.update.replace(':categoryId', categoryId).replace(':subcategoryId', subCategoryId), payload)

export const disable = async ({ categoryId, subCategoryId }) =>
  await EffectUtility.post(subCategoryEndpoints.disable.replace(':categoryId', categoryId).replace(':subcategoryId', subCategoryId))

export const enable = async ({ categoryId, subCategoryId }) =>
  await EffectUtility.post(subCategoryEndpoints.enable.replace(':categoryId', categoryId).replace(':subcategoryId', subCategoryId))
