import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { notification } from 'antd'
import * as NotificationActions from '../../../stores/notification/NotificationActions'

export default function Notification() {
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notification.items)

  useEffect(() => {
    if (notifications.length) {
      notifications.forEach(({ id, message, description, type }) => {
        dispatch(NotificationActions.removeById(id))
        const notificationParams = {
          message,
          description,
        }
        switch (type) {
          case 'error':
            notification.error(notificationParams)
            break
          case 'warning':
            notification.warning(notificationParams)
            break
          default:
            notification.success(notificationParams)
            break
        }
      })
    }
  }, [dispatch, notifications])

  if (notifications.length === 0) {
    return null
  }

  return <></>
}
