// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./structure/header.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./structure/menu.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./structure/footer.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../node_modules/@aberto/react-common/dist/page-header.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./structure/page-content.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: 'Rubik';
  font-weight: 400;
}
strong,
b {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/structure.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;AACA;;EAEE,gBAAgB;AAClB","sourcesContent":["* {\n  font-family: 'Rubik';\n  font-weight: 400;\n}\nstrong,\nb {\n  font-weight: 500;\n}\n\n@import url('./structure/header.css');\n@import url('./structure/menu.css');\n@import url('./structure/footer.css');\n@import '~@aberto/react-common/dist/page-header.css';\n@import url('./structure/page-content.css');\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
