import './Header.css'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Layout, Menu, Modal, Typography } from 'antd'
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'
import { useMsal, useAccount } from '@azure/msal-react'
import useCurrentAccount from '../../../hooks/use-current-account'
import { ProfileTypeKeys } from '../../../constants/ProfileTypeEnum'
import ChangeAssignedTenantForm from './components/change-assigned-tenant-form'
import { ASSIGNED_TENANT_STORAGE_KEY } from '../../../constants/AssignedTenantConstants'

import RouteEnum from '../../../constants/RouteEnum'
import { push } from 'connected-react-router'
import environment from 'environment'

import Logo from './logo-tradeback.svg'

const { Header } = Layout

function HeaderComponent() {
  const dispatch = useDispatch()
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[accounts.length - 1] || {})
  const { name } = account
  const currentAccount = useCurrentAccount()
  const [isTenantModalVisible, setIsTenantModalVisible] = useState(false)
  const [assignedTenantDescription, setAssignedTenantDescription] = useState('')

  useEffect(() => {
    if (isAbertoProfile()) {
      const tenantFromToken = currentAccount?.idTokenClaims['extension_companyId']
      const tenantFromLocalStorageSerialized = localStorage.getItem(ASSIGNED_TENANT_STORAGE_KEY)
      const tenantFromLocalStorage = tenantFromLocalStorageSerialized ? JSON.parse(tenantFromLocalStorageSerialized) : null
      const assignedTenantDescription = tenantFromLocalStorage
        ? `${tenantFromLocalStorage.tenantName}(${tenantFromLocalStorage.tenantId})`
        : tenantFromToken ?? 'Indefinido'
      setAssignedTenantDescription(assignedTenantDescription)
    }
  }, [])

  const onLogout = () => {
    instance.logout()
  }

  const onEditProfile = () => {
    instance.loginRedirect(environment.managerApi.authentication.authorities.editProfile)
  }

  const onChangePassword = () => {
    instance.loginRedirect(environment.managerApi.authentication.authorities.forgotPassword)
  }

  const handleOnClick = _ => dispatch(push(RouteEnum.Home))

  const isAbertoProfile = () => {
    return currentAccount?.idTokenClaims['extension_profile'] === ProfileTypeKeys.Aberto
  }

  const handleSetAssignedTenantVisible = () => {
    setIsTenantModalVisible(previousState => !previousState)
  }

  return (
    <>
      <Header className="site-layout-background">
        <img className="logo" src={Logo} onClick={handleOnClick} alt="logo" />
        <div>
          {isAbertoProfile() && (
            <Typography.Title className="assigned-tenant-description" level={5}>
              Tenant: {assignedTenantDescription}
            </Typography.Title>
          )}
          <Menu mode="horizontal">
            <Menu.SubMenu icon={<UserOutlined />} title={name}>
              <Menu.Item key="profile" onClick={onEditProfile}>
                Editar Perfil
              </Menu.Item>
              <Menu.Item key="change-password" onClick={onChangePassword}>
                Alterar Senha
              </Menu.Item>
              {isAbertoProfile() && (
                <Menu.Item key="set-assigned-tenant" onClick={handleSetAssignedTenantVisible}>
                  Definir tenant
                </Menu.Item>
              )}
            </Menu.SubMenu>
          </Menu>
          <a className="logout" onClick={onLogout} title="Sair">
            <LogoutOutlined />
          </a>
        </div>
      </Header>
      <Modal visible={isTenantModalVisible} title="Definir tenant" footer={null} onCancel={handleSetAssignedTenantVisible}>
        <ChangeAssignedTenantForm setIsVisible={setIsTenantModalVisible} />
      </Modal>
    </>
  )
}
export { HeaderComponent as Header }
