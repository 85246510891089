// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-list-bordered {
  border-radius: 4px;
  border: 1px solid var(--tb-green) !important;
}
.ant-list-bordered .ant-list-header {
  padding: 6px 10px !important;
  background-color: var(--tb-light-green) !important;
  font-weight: bold;
  color: var(--tb-blue);
}
.ant-list-bordered .ant-list-footer {
  padding: 6px 10px !important;
  border-top: 1px solid var(--tb-green) !important;
  background-color: var(--tb-light-green) !important;
  font-weight: bold;
  color: var(--tb-blue);
}
.ant-list-bordered .ant-list-item {
  padding-right: 15px;
  padding-left: 15px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 15px;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid var(--tb-green) !important;
}
.ant-list-item-meta-avatar .anticon {
  color: var(--tb-blue);
  font-size: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/listbox.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,4CAA4C;AAC9C;AACA;EACE,4BAA4B;EAC5B,kDAAkD;EAClD,iBAAiB;EACjB,qBAAqB;AACvB;AACA;EACE,4BAA4B;EAC5B,gDAAgD;EAChD,kDAAkD;EAClD,iBAAiB;EACjB,qBAAqB;AACvB;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,mDAAmD;AACrD;AACA;EACE,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".ant-list-bordered {\n  border-radius: 4px;\n  border: 1px solid var(--tb-green) !important;\n}\n.ant-list-bordered .ant-list-header {\n  padding: 6px 10px !important;\n  background-color: var(--tb-light-green) !important;\n  font-weight: bold;\n  color: var(--tb-blue);\n}\n.ant-list-bordered .ant-list-footer {\n  padding: 6px 10px !important;\n  border-top: 1px solid var(--tb-green) !important;\n  background-color: var(--tb-light-green) !important;\n  font-weight: bold;\n  color: var(--tb-blue);\n}\n.ant-list-bordered .ant-list-item {\n  padding-right: 15px;\n  padding-left: 15px;\n}\n.ant-list-bordered .ant-list-pagination {\n  margin: 16px 15px;\n}\n.ant-list-split .ant-list-header {\n  border-bottom: 1px solid var(--tb-green) !important;\n}\n.ant-list-item-meta-avatar .anticon {\n  color: var(--tb-blue);\n  font-size: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
