import HttpErrorResponseModel from '../models/HttpErrorResponseModel'
import * as HttpUtility from './HttpUtility'

export async function get(endpoint, config) {
  const response = await HttpUtility.get(endpoint, config)
  return checkResponseErrors(response)
}

/**
 * Performs a GET operation along with queryString parameters.
 *
 * @param {endpoint}     endpoint     Endpoint.
 * @param {queryParams}  queryParams  QueryString parameters: [{"key": "value"}].
 * @param {config}       config  Configurations.
 */
export async function getWithQuery(endpoint, queryParams, config) {
  const response = await HttpUtility.getWithQuery(endpoint, config, queryParams)
  return checkResponseErrors(response)
}

export async function post(endpoint, data) {
  const response = await HttpUtility.post(endpoint, data)
  return checkResponseErrors(response)
}

export async function postReturningHeader(endpoint, data) {
  const response = await HttpUtility.post(endpoint, data)
  return checkResponseErrorsAndReturnWithHeaders(response)
}

export async function postDataWithoutTransformation(endpoint, data) {
  const response = await HttpUtility.postDataWithoutTransformation(endpoint, data)
  return checkResponseErrors(response)
}

export async function put(endpoint, data) {
  const response = await HttpUtility.put(endpoint, data)
  return checkResponseErrors(response)
}

export async function patch(endpoint, data) {
  const response = await HttpUtility.patch(endpoint, data)
  return checkResponseErrors(response)
}

export async function jsonPatch(endpoint, data) {
  const response = await HttpUtility.jsonPatch(endpoint, data)
  return checkResponseErrors(response)
}

export async function del(endpoint) {
  const response = await HttpUtility.del(endpoint)
  return checkResponseErrors(response)
}

export async function postFormData(endpoint, formData) {
  const response = await HttpUtility.postFormData(endpoint, formData)
  return checkResponseErrors(response)
}

export async function putFormData(endpoint, formData) {
  const response = await HttpUtility.putFormData(endpoint, formData)
  return checkResponseErrors(response)
}

export async function getToModel(Model, endpoint) {
  const response = await HttpUtility.get(endpoint)

  return restModelCreator(Model, response)
}

export async function postToModel(Model, endpoint, data) {
  const response = await HttpUtility.post(endpoint, data)

  return restModelCreator(Model, response)
}

export async function putToModel(Model, endpoint, data) {
  const response = await HttpUtility.put(endpoint, data)

  return restModelCreator(Model, response)
}

function checkResponseErrors(response) {
  if (response instanceof HttpErrorResponseModel) {
    return response
  }

  return response.data
}

function checkResponseErrorsAndReturnWithHeaders(response) {
  if (response instanceof HttpErrorResponseModel) {
    return response
  }

  return { data: response.data, headers: response.headers }
}

function restModelCreator(Model, response) {
  if (response instanceof HttpErrorResponseModel) {
    return response
  }

  return !Array.isArray(response.data) ? new Model(response.data) : response.data.map(json => new Model(json))
}
