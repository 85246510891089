import NotificationEnum from '../../constants/NotificationEnum'
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel'
import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './ParticipantSegmentEffects'

export const REQUEST_LIST = 'ParticipantSegment.REQUEST_LIST'
export const REQUEST_LIST_FINISHED = 'ParticipantSegment.REQUEST_LIST_FINISHED'
export const REQUEST_SEARCH = 'ParticipantSegment.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'ParticipantSegment.REQUEST_SEARCH_FINISHED'
export const REQUEST_GET = 'ParticipantSegment.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'ParticipantSegment.REQUEST_GET_FINISHED'
export const REQUEST_CREATE = 'ParticipantSegment.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'ParticipantSegment.REQUEST_CREATE_FINISHED'
export const REQUEST_UPDATE = 'ParticipantSegment.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'ParticipantSegment.REQUEST_UPDATE_FINISHED'
export const REQUEST_IMPORT = 'ParticipantSegment.REQUEST_IMPORT'
export const REQUEST_IMPORT_FINISHED = 'ParticipantSegment.REQUEST_IMPORT_FINISHED'
export const RESET_CURRRENT = 'ParticipantSegment.RESET_CURRRENT'
export const SET_CURRENT_DATA = 'ParticipantSegment.SET_CURRENT_DATA'
export const SET_PAGINATION = 'ParticipantSegment.SET_PAGINATION'

export function list() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_LIST, Effects.list)
  }
}

export function search(name) {
  return async (dispatch, getState) => {
    const pagination = getState().participantSegment.search.pagination
    const filter = { ...getState().participantSegment.search.filter, name }

    const params = {
      pagination,
      filter,
    }

    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH, Effects.search, params)
  }
}

export function setPagination(pagination) {
  return ActionUtility.createAction(SET_PAGINATION, pagination)
}

export function get(segmentId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get, segmentId)
  }
}

export function setCurrentData(data) {
  return ActionUtility.createAction(SET_CURRENT_DATA, data)
}

export function create() {
  return async (dispatch, getState) => {
    const data = getState().participantSegment.segment

    ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      Effects.create,
      {
        notification: {
          description: null,
          message: 'Segmento criado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      data
    )
  }
}

export function update(segmentId) {
  return async (dispatch, getState) => {
    const data = getState().participantSegment.segment

    ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effects.update,
      {
        notification: {
          description: null,
          message: 'Segmento atualizado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      segmentId,
      data
    )
  }
}

export function importParticipants(segmentId, file) {
  return async (dispatch, getState) => {
    dispatch(ActionUtility.createAction(REQUEST_IMPORT))

    const model = await Effects.importParticipants(segmentId, file)
    const isError = model instanceof HttpErrorResponseModel

    dispatch(
      ActionUtility.createAction(REQUEST_IMPORT_FINISHED, model, isError, {
        notification: {
          description: null,
          message: isError ? model.message : 'Arquivo enviado para importação.',
          type: NotificationEnum.Success,
        },
      })
    )

    return model
  }
}
export function importParticipantsMerge(file) {
  return async (dispatch, getState) => {
    dispatch(ActionUtility.createAction(REQUEST_IMPORT))

    const model = await Effects.importParticipantsMerge(file)
    const isError = model instanceof HttpErrorResponseModel

    dispatch(
      ActionUtility.createAction(REQUEST_IMPORT_FINISHED, model, isError, {
        notification: {
          description: null,
          message: isError ? model.message : 'Arquivo enviado para importação.',
          type: NotificationEnum.Success,
        },
      })
    )

    return model
  }
}
export function importParticipantsOverride(file) {
  return async (dispatch, getState) => {
    dispatch(ActionUtility.createAction(REQUEST_IMPORT))

    const model = await Effects.importParticipantsOverride(file)
    const isError = model instanceof HttpErrorResponseModel

    dispatch(
      ActionUtility.createAction(REQUEST_IMPORT_FINISHED, model, isError, {
        notification: {
          description: null,
          message: isError ? model.message : 'Arquivo enviado para importação.',
          type: NotificationEnum.Success,
        },
      })
    )

    return model
  }
}

export function reset() {
  return ActionUtility.createAction(RESET_CURRRENT)
}
