// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-upload.ant-upload-select-picture-card {
  border-color: var(--tb-blue);
  border-radius: 4px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: var(--tb-green);
}

.ant-upload.ant-upload-drag {
  border-color: var(--tb-blue);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/forms/upload.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,kBAAkB;AACpB;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".ant-upload.ant-upload-select-picture-card {\n  border-color: var(--tb-blue);\n  border-radius: 4px;\n}\n.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {\n  color: var(--tb-green);\n}\n\n.ant-upload.ant-upload-drag {\n  border-color: var(--tb-blue);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
