/**
 * Documentation
 * @param {{propertyName: { value: string, operation: string, from?:string }}} obj
 */
const createJsonPatch = obj => {
  const jsonPatch = []
  for (const key in obj) {
    const currentObj = obj[key]

    jsonPatch.push({
      path: `/${key}`,
      value: currentObj.value,
      from: currentObj.from,
      op: currentObj.operation,
    })
  }

  return jsonPatch
}

const addOperationToAllAttribute = (obj, operation) => {
  const objWithOperation = {}

  for (const key in obj) {
    objWithOperation[key] = {
      value: obj[key],
      operation,
    }
  }

  return objWithOperation
}

const addOperationWithNestedProperty = (obj, operation, nestedLevel = 1) => {
  const objWithOperation = {}

  const allNestedProperties = []

  for (const key in obj) {
    const value = obj[key]

    if (hasNestedProperty(value)) {
      allNestedProperties.push(...addToNestedProperty(value, key, 1, nestedLevel))
      continue
    }

    objWithOperation[key] = {
      value,
      operation,
    }
  }

  for (const { nestedKey, nestedValue } of allNestedProperties) {
    objWithOperation[nestedKey] = {
      value: nestedValue,
      operation,
    }
  }

  return objWithOperation
}

const addToNestedProperty = (obj, fatherName, currentNestedLevel, maxNestedLevel) => {
  const canIBrowseTheNestedObject = currentNestedLevel < maxNestedLevel
  if (!canIBrowseTheNestedObject || !hasNestedProperty(obj)) return [{ nestedKey: fatherName, nestedValue: obj }]

  const allNestedProperties = []

  const nestedKeys = Object.keys(obj)

  for (const nextKey of nestedKeys) {
    const nextObj = obj[nextKey]
    allNestedProperties.push(...addToNestedProperty(nextObj, `${fatherName}/${nextKey}`, currentNestedLevel + 1, maxNestedLevel))
  }

  return allNestedProperties
}

const hasNestedProperty = value => value?.constructor === Object

export { createJsonPatch, addOperationToAllAttribute, addOperationWithNestedProperty }
