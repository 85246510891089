export const download = async (fileUrl, fileNameWithExtension) => {
  const file = await fetch(fileUrl)
  const fileAsBlob = await file.blob()
  downloadFromBlob(fileAsBlob, fileNameWithExtension)
}

export const downloadFromBlob = async (blob, fileNameWithExtension) => {
  if (!blob) return
  const downloadUrl = URL.createObjectURL(blob)

  const linkElement = document.createElement('a')
  linkElement.style.display = 'none'
  linkElement.href = downloadUrl
  linkElement.download = fileNameWithExtension

  document.body.append(linkElement)
  linkElement.click()

  document.body.removeChild(linkElement)
  window.URL.revokeObjectURL(downloadUrl)
}

export const getFileNameFromContentDisposition = headers => {
  if (!headers) return
  const contentDisposition = headers['content-disposition'] || headers['Content-Disposition']
  const fileNamesOnString = contentDisposition?.split('filename="')[1]
  const firstFileNameOnString = fileNamesOnString?.split('";')[0]
  return firstFileNameOnString
}
