import baseReducer from '../../utilities/BaseReducer'
import * as NotificationActions from './NotificationActions'

export const initialState = {
  items: [],
}

const notificationReducer = baseReducer(initialState, {
  [NotificationActions.ADD_NOTIFICATION](state, action) {
    return {
      ...state,
      items: [...state.items, action.payload],
    }
  },

  [NotificationActions.REMOVE_NOTIFICATION](state, action) {
    const toastId = action.payload

    return {
      ...state,
      items: state.items.filter(model => model.id !== toastId),
    }
  },
})

export default notificationReducer
