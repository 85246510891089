import * as ActionUtility from '../../utilities/ActionUtility'
import * as TransactionDeliveryEffects from './TransactionDeliveryEffects'

export const REQUEST_IDENTIFICATION_INFO = 'TransactionDeliveryConfig.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'TransactionDeliveryConfig.REQUEST_IDENTIFICATION_INFO_FINISHED'

export function getIdentificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, TransactionDeliveryEffects.getIdentificationInfo)
  }
}
