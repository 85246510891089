import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, DatePicker, Form, Input, Row, Select, Tag } from 'antd'

import { dateConfig } from '../../../../utilities/DateUtility'
import { toSelectOptions } from '../../../../utilities/EnumUtility'
import { selectOptionsFilter } from '../../../../utilities/SelectUtility'
import { getStatusTagOptions } from '../../../../utilities/TagUtility'

import * as Selectors from '../../../../selectors/sale/SaleSearchSelectors'
import { SaleStatus } from '../../../../stores/sale/models/SaleEnums'
import * as SaleSearchActions from '../../../../stores/sale/search/SaleSearchActions'

import CampaignSelect from '../../../components/campaign-select/CampaignSelect'
import ChainSelect from '../../../components/chain-select/ChainSelect'
import { Filter, FooterContentButtons } from '@aberto/react-common'
import moment from 'moment-timezone'

const { RangePicker } = DatePicker
const statusOptions = toSelectOptions(SaleStatus)

const defaultHourStart = '00:00:00'
const defaultHourEnd = '23:59:59'

const tagRender = params => {
  const { label, value, closable, onClose } = params
  return (
    <Tag
      className={'custom-color ' + getStatusTagOptions(value).color}
      {...getStatusTagOptions(value)}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  )
}

export default function SaleSearchFilter() {
  const dispatch = useDispatch()
  const filter = useSelector(Selectors.filter)
  const pagination = useSelector(Selectors.pagination)
  const [form] = Form.useForm()

  const onFinish = ({ initialPeriodDate, finalPeriodDate, ...rest }) => {
    const extractValue = list => {
      if (!list) return null
      let [start, end] = list
      start = start?.format(dateConfig.dateFormat.replace('HH:mm:ss', defaultHourStart))
      end = end?.format(dateConfig.dateFormat.replace('HH:mm:ss', defaultHourEnd))
      return { start, end }
    }

    const updatedFilter = {
      initialPeriodDate: extractValue(initialPeriodDate),
      finalPeriodDate: extractValue(finalPeriodDate),
      ...rest,
    }

    dispatch(SaleSearchActions.setFilter(updatedFilter))
    dispatch(SaleSearchActions.setPagination({ ...pagination, pageNumber: 1 }))
    dispatch(SaleSearchActions.search())
  }

  const formParams = {
    form,
    initialValues: { ...filter },
    onFinish,
  }

  const nameValidationRules = [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value && !getFieldValue('campaignId')) {
          return Promise.reject('É necessário selecionar uma campanha.')
        }
        return Promise.resolve()
      },
    }),
  ]

  const bindForm = useCallback(() => {
    form.setFieldsValue(filter)
  }, [filter, form])

  useEffect(() => {
    bindForm()
  }, [bindForm])

  const onClear = () => {
    dispatch(SaleSearchActions.clearFilter())
    dispatch(SaleSearchActions.setPagination({ ...pagination, pageNumber: 1 }))
    dispatch(SaleSearchActions.search())
  }

  return (
    <Filter>
      <Form {...formParams} layout="vertical" requiredMark>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item id="campaignId" name="campaignId" label="Campanha">
              <CampaignSelect />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item id="name" name="name" label="Nome" rules={nameValidationRules}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={6}>
            <Form.Item id="chainId" name="chainId" label="Rede">
              <ChainSelect />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item id="statusList" name="statusList" label="Status">
              <Select allowClear mode="multiple" options={statusOptions} filterOption={selectOptionsFilter} tagRender={tagRender} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item id="initialPeriodDate" name="initialPeriodDate" label="Data Inicial">
              <RangePicker
                ranges={{
                  Agora: [moment(), moment()],
                }}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}
                placeholder={['Inicio', 'Fim']}
                format={dateConfig.displayFormat}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item id="finalPeriodDate" name="finalPeriodDate" label="Data Final">
              <RangePicker
                ranges={{
                  Agora: [moment(), moment()],
                }}
                showTime={{ defaultValue: moment('23:59:59', 'HH:mm') }}
                placeholder={['Inicio', 'Fim']}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <FooterContentButtons>
          <Button id="cleanForm" onClick={onClear}>
            Limpar Filtros
          </Button>
          <Button id="filterButton" type="primary" htmlType="submit">
            Filtrar
          </Button>
        </FooterContentButtons>
      </Form>
    </Filter>
  )
}
