import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './ParticipantSegmentActions'

export const initialState = {
  search: {
    pagination: {
      pageSize: 100,
      pageNumber: 1,
    },
    filter: {
      rewardProgramId: null,
      name: null,
      active: true,
    },
    response: {
      numberOfRows: 0,
      data: [],
    },
  },
  segment: {
    id: null,
    name: '',
    active: true,
    rewardProgramId: '69db778c-fa21-429b-a1cc-6167bd1fda56',
    identifierType: 0,
    externalCode: '',
    participantsCount: 0,
  },
  list: [],
}

const participantSegmentReducer = baseReducer(initialState, {
  [Actions.REQUEST_LIST_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.list = payload
    })
  },
  [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.response = payload
    })
  },
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.segment = payload
    })
  },
  [Actions.SET_CURRENT_DATA](state, { payload: { name, rewardProgramId, identifierType, externalCode } }) {
    return produce(state, draftState => {
      draftState.segment.name = name
      // draftState.segment.rewardProgramId = rewardProgramId
      draftState.segment.identifierType = identifierType
      draftState.segment.externalCode = externalCode
    })
  },
  [Actions.REQUEST_CREATE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.segment.id = payload
    })
  },
  [Actions.RESET_CURRRENT](state) {
    return produce(state, draftState => {
      draftState.segment = initialState.segment
    })
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.pagination = payload
    })
  },
})

export default participantSegmentReducer
