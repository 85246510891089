import produce from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './PartnerActions'

export const initialState = {
  identificationInfo: [],
}

const PartnerReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_IDENTIFICATION_INFO_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.identificationInfo = action.payload
    })
  },
})

export default PartnerReducer
