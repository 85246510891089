import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './ParticipationLimitActions'

export const initialState = {
  identificationInfo: [],
  current: {
    limitTransactionsEvenWithoutBenefit: true,
    description: '',
    rules: [],
  },
}

const participationLimitReducer = baseReducer(initialState, {
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.identificationInfo = payload
    })
  },
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.current = payload
    })
  },
  [Actions.SET_DESCRIPTION](state, { payload }) {
    return produce(state, draftState => {
      draftState.current.description = payload
    })
  },
  [Actions.SET_LIMIT_TRANSACTIONS_EVEN_WITHOUT_BENEFIT](state, { payload }) {
    return produce(state, draftState => {
      draftState.current.limitTransactionsEvenWithoutBenefit = payload
    })
  },
  [Actions.ADD_RULE](state, { payload }) {
    return produce(state, draftState => {
      draftState.current.rules.push(payload)
    })
  },
  [Actions.SET_RULE](state, { payload: { index, data } }) {
    return produce(state, draftState => {
      draftState.current.rules[index] = data
    })
  },
  [Actions.DELETE_RULE](state, { payload }) {
    return produce(state, draftState => {
      draftState.current.rules.splice(payload, 1)
    })
  },
  [Actions.CLEAN](state) {
    return produce(state, draftState => {
      draftState.current = initialState.current
    })
  },
})

export default participationLimitReducer
