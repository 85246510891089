import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './CardBinSegmentEffects'

export const SET_FILTER = 'CardBinSegmentActions.SET_FILTER'
export const SET_PAGINATION = 'CardBinSegmentActions.SET_PAGINATION'
export const CLEAR = 'CardBinSegmentActions.CLEAR'
export const CLEAR_FILTER = 'CardBinSegmentActions.CLEAR_FILTER'
export const REQUEST_SEARCH = 'CardBinSegmentActions.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'CardBinSegmentActions.REQUEST_SEARCH_FINISHED'

export const REQUEST_ACTIVATE = 'CardBinSegmentActions.REQUEST_ACTIVATE'
export const REQUEST_INACTIVATE = 'CardBinSegmentActions.REQUEST_INACTIVATE'

export const REQUEST_GET_BY_ID = 'CardBinSegmentActions.REQUEST_GET_BY_ID'
export const REQUEST_GET_BY_ID_FINISHED = 'CardBinSegmentActions.REQUEST_GET_BY_ID_FINISHED'

export const REQUEST_CREATE = 'CardBinSegmentActions.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'CardBinSegmentActions.REQUEST_CREATE_FINISHED'

export const REQUEST_UPDATE = 'CardBinSegmentActions.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'CardBinSegmentActions.REQUEST_UPDATE_FINISHED'

export const REQUEST_IDENTIFICATION_INFO = 'CardBinSegmentActions.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'CardBinSegmentActions.REQUEST_IDENTIFICATION_INFO_FINISHED'

export function search() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.search,
      getState().cardBinSegment.search.filter,
      getState().cardBinSegment.search.pagination
    )
  }
}

export function activate(id, name) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_ACTIVATE,
      Effects.activate,
      {
        notification: {
          message: `Segmento '${name}' ativado com sucesso`,
          type: NotificationEnum.Success,
        },
      },
      id
    )
  }
}

export function inactivate(id, name) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_INACTIVATE,
      Effects.inactivate,
      {
        notification: {
          message: `Segmento '${name}' desativado com sucesso`,
          type: NotificationEnum.Success,
        },
      },
      id
    )
  }
}

export function getById(id) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_BY_ID, Effects.getById, id)
  }
}

export function create(segment) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      Effects.create,
      {
        notification: {
          message: `Segmento criado com sucesso`,
          type: NotificationEnum.Success,
        },
      },
      segment
    )
  }
}

export function update(segment) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effects.update,
      {
        notification: {
          message: `Segmento editado com sucesso`,
          type: NotificationEnum.Success,
        },
      },
      getState().cardBinSegment.currentSegment.id,
      segment
    )
  }
}

export function getIdentificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.getIdentificationInfo)
  }
}

export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)

export const clear = _ => ActionUtility.createAction(CLEAR)
