import { createSelector } from 'reselect'

const _chainSelect = campaigns => {
  return campaigns.map(item => {
    return { value: item.id, label: item.name }
  })
}

export const identificationInfo = state => state.company.chain.identificationInfo
export const chainSelect = createSelector(state => state.company.chain.identificationInfo, _chainSelect)
