// TODO: Mudar para objeto e aninhar rotas
enum RouteEnum {
  Home = '/',
  SignOut = '/signout',
  SalesSearchPage = '/sale/search',
  LuckTimesPage = '/luck-times',
  ParticipantSegmentPage = '/participant-segment',
  ParticipationAuthorizationPage = '/participation-authorization',
  PromotionalCodePage = '/promotional-code',
  ForbiddenWordsPage = '/forbidden-words',
  CampaignsPage = '/campaigns',
  CampaignCreate = '/campaigns/create',
  CampaignsEdit = '/campaigns/:campaignId',
  SaleNew = '/sale/new',
  SalePage = '/sale/:saleId?/:step',
  SaleBasicInfo = '/sale/:saleId?/basic-info',
  SaleActivateCondition = '/sale/:saleId?/activate-condition',
  SaleMessages = '/sale/:saleId/messages',
  SaleMessagesFeedback = '/sale/:saleId/messages/feedback-messages',
  SaleMessagesNotification = '/sale/:saleId/messages/notification',
  SaleStores = '/sale/:saleId?/stores',
  SaleBenefit = '/sale/:saleId/benefit',
  SaleEligibles = '/sale/:saleId/eligibles',
  SaleEligiblesProducts = '/sale/:saleId/eligibles/products',
  SaleEligiblesParticipants = '/sale/:saleId/eligibles/participants',
  SaleEligiblesPinCode = '/sale/:saleId/eligibles/pincode',
  SaleEligiblesPayment = '/sale/:saleId/eligibles/payment',
  SaleEligiblesAttributes = '/sale/:saleId/eligibles/attributes',
  SaleElegiblesParticipationAuthorization = '/sale/:saleId/eligibles/participation-authorization',
  SaleDelimitations = '/sale/:saleId/delimitations',
  SaleDelimitationsBudget = '/sale/:saleId/delimitations/budget',
  SaleDelimitationsPaticipantBlackList = '/sale/:saleId/delimitations/participant-blacklist',
  SaleDelimitationsParticipationLimit = '/sale/:saleId/delimitations/participation-limit',
  SaleAdvertisement = '/sale/:saleId/advertisement',
  SaleSummary = '/sale/:saleId/summary',
  ParticipationLimitPage = '/participation-limit-list',
  ParticipationLimitNewPage = '/participation-limit/new',
  ParticipationLimitDetailsPage = '/participation-limit/:participationLimitId',
  Company = '/company',
  CompanyEdit = '/company/edit/:companyId',
  CompanyCreate = '/company/create',
  CompanyCategory = '/company/category',
  CompanyCategoryInfo = '/company/category/:categoryId',
  StoreCreate = '/company/:companyId/store/create',
  StoreEdit = '/company/:companyId/store/edit/:storeId',
  ProductsPage = '/product',
  ProductCreate = '/product/create',
  ProductDetail = '/product/:productId',
  ProductsListPage = '/product-list',
  CardBinSegmentsPage = '/card-bin-segment',
}

export default RouteEnum
