import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './ParticipantSegmentImportActions'

export const initialState = {
    search: {
        pagination: {
            pageSize: 10,
            pageNumber: 1,
        },
        filter: {
            name: null,
            status: null,
            createDate: {
                start: null,
                end: null,
            },
        },
    },
    searchResponse: {
        numberOfRows: 0,
        data: [],
    },
}

const participantSegmentImportReducer = baseReducer(initialState, {
    [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
        return produce(state, draftState => {
            draftState.searchResponse = payload
        })
    },
    [Actions.SET_FILTER](state, { payload }) {
        return produce(state, draftState => {
            draftState.search.filter = payload
        })
    },
    [Actions.SET_PAGINATION](state, { payload }) {
        return produce(state, draftState => {
            draftState.search.pagination = payload
        })
    },
    [Actions.CLEAR]() {
        return initialState
    },
    [Actions.CLEAR_FILTER](state) {
        return produce(state, draftState => {
            draftState.search.filter = initialState.search.filter
        })
    },
})

export default participantSegmentImportReducer