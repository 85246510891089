import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleEditorActions'
import SaleEditorSteps from './models/EditorStepsEnum'

export const initialState = {
  saleId: null,
  steps: {
    [SaleEditorSteps.BasicInfo]: {
      status: 'wait',
      disabled: true,
    },
    [SaleEditorSteps.ActivateCondition]: {
      status: 'wait',
      disabled: true,
    },
    [SaleEditorSteps.Benefit]: {
      status: 'wait',
      disabled: true,
    },
    [SaleEditorSteps.Stores]: {
      status: 'wait',
      disabled: true,
    },
    [SaleEditorSteps.Products]: {
      status: 'wait',
      disabled: true,
    },
    [SaleEditorSteps.Participants]: {
      status: 'wait',
      disabled: true,
    },
    [SaleEditorSteps.FeedbackMessages]: {
      status: 'wait',
      disabled: true,
    },
    [SaleEditorSteps.Summary]: {
      status: 'wait',
      disabled: true,
    },
    [SaleEditorSteps.Payment]: {
      status: 'wait',
      disabled: true,
    },
  },
}

const saleStepsReducer = baseReducer(initialState, {
  [Actions.SET_STEP](state, { payload: { step, status, disabled } }) {
    return produce(state, draft => {
      draft.steps[step].status = status
      draft.steps[step].disabled = disabled
    })
  },
  [Actions.SET_SALE_ID](state, { payload }) {
    return produce(state, draft => {
      draft.saleId = payload
    })
  },
  [Actions.ENABLE_ALL_STEPS](state, action) {
    return produce(state, draft => {
      for (const step in SaleEditorSteps) {
        draft.steps[SaleEditorSteps[step]] = {
          status: 'processing',
          disabled: false,
        }
      }
    })
  },
  [Actions.ENABLE_ONLY_BASIC_INFO](state, action) {
    return produce(state, draft => {
      for (const step in SaleEditorSteps) {
        draft.steps[SaleEditorSteps[step]] = {
          status: 'wait',
          disabled: true,
        }
      }

      draft.steps[SaleEditorSteps.BasicInfo].status = 'processing'
      draft.steps[SaleEditorSteps.BasicInfo].false = false
    })
  },
})

export default saleStepsReducer
