import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

export async function get(saleId) {
  return EffectUtility.get(environment.managerApi.endpoints.sale.benefit.replace(':saleId', `${saleId}`))
}

export async function update(saleId, benefits) {
  return EffectUtility.put(environment.managerApi.endpoints.sale.benefit.replace(':saleId', `${saleId}`), benefits)
}

export async function getBasicInfoBySaleIds(salesId) {
  return EffectUtility.post(environment.managerApi.endpoints.sale.basicInfoList, salesId)
}
