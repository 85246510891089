import NotificationEnum from '../../../constants/NotificationEnum'
import * as ActionUtility from '../../../utilities/ActionUtility'
import { addOperationToAllAttribute, createJsonPatch } from '../../../utilities/JsonPatchUtility'
import * as Effects from './SalePaymentEffects'

export const REQUEST_PATCH = 'SaleEligiblePayment.REQUEST_PATCH'
export const REQUEST_PATCH_FINISHED = 'SaleEligiblePayment.REQUEST_PATCH_FINISHED'

export const REQUEST_GET = 'SaleEligiblePayment.REQUEST_GET'
export const REQUEST_GET_FINISHED = 'SaleEligiblePayment.REQUEST_GET_FINISHED'

export function patch(saleId, data) {
  const dataWithOperations = addOperationToAllAttribute(data, 'add')

  var jsonPatchRequest = createJsonPatch(dataWithOperations)

  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_PATCH,
      Effects.patch,
      {
        notification: {
          description: null,
          message: 'Pagamentos atualizados com sucesso',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      jsonPatchRequest
    )
  }
}

export function get(saleId) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET, Effects.get, saleId)
  }
}
