import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './AddressActions'

const initialState = {
  currentAddress: null,
}

const companyReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_ADDRESS_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.currentAddress = action.payload
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
})

export default companyReducer
