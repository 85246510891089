import environment from 'environment'
import * as EffectUtility from '../../utilities/EffectUtility'

export async function list() {
  return EffectUtility.get(environment.managerApi.endpoints.participantSegment.list)
}
export async function search(params) {
  return EffectUtility.post(environment.managerApi.endpoints.participantSegment.search, params)
}

export async function get(segmentId) {
  return EffectUtility.get(environment.managerApi.endpoints.participantSegment.get.replace(':segmentId', segmentId))
}

export async function create(data) {
  return EffectUtility.post(environment.managerApi.endpoints.participantSegment.create, data)
}

export async function update(segmentId, data) {
  return EffectUtility.put(environment.managerApi.endpoints.participantSegment.update.replace(':segmentId', segmentId), data)
}

export async function importParticipants(segmentId, file) {
  const formData = new FormData()
  formData.append('file', file)
  return EffectUtility.postFormData(environment.managerApi.endpoints.participantSegment.import.replace(':segmentId', segmentId), formData)
}

export async function importParticipantsMerge(file) {
  const formData = new FormData()
  formData.append('file', file)
  return EffectUtility.postFormData(environment.managerApi.endpoints.participantSegment.importMerge, formData)
}

export async function importParticipantsOverride(file) {
  const formData = new FormData()
  formData.append('file', file)
  return EffectUtility.postFormData(environment.managerApi.endpoints.participantSegment.importOverride, formData)
}
