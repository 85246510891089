import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './ParticipationAuthorizationBaseEffects'
import NotificationEnum from '../../constants/NotificationEnum'

export const REQUEST_GET_BY_ID = 'ParticipationAuthorizationBaseActions.REQUEST_GET_BY_ID'
export const REQUEST_GET_BY_ID_FINISHED = 'ParticipationAuthorizationBaseActions.REQUEST_GET_BY_ID_FINISHED'

export const REQUEST_CREATE = 'ParticipationAuthorizationBaseActions.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'ParticipationAuthorizationBaseActions.REQUEST_CREATE_FINISHED'

export const REQUEST_UPDATE = 'ParticipationAuthorizationBaseActions.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'ParticipationAuthorizationBaseActions.REQUEST_UPDATE_FINISHED'

export const REQUEST_SEARCH = 'ParticipationAuthorizationBaseActions.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'ParticipationAuthorizationBaseActions.REQUEST_SEARCH_FINISHED'

export const SET_FILTER = 'ParticipationAuthorizationBaseActions.SET_FILTER'
export const SET_PAGINATION = 'ParticipationAuthorizationBaseActions.SET_PAGINATION'
export const CLEAR = 'ParticipationAuthorizationBaseActions.CLEAR'
export const CLEAR_FILTER = 'ParticipationAuthorizationBaseActions.CLEAR_FILTER'

export const SET_CURRENT = 'ParticipationAuthorizationBaseActions.SET_CURRENT'
export const REQUEST_IDENTIFICATION_INFO = 'ParticipationAuthorizationBaseActions.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED = 'ParticipationAuthorizationBaseActions.REQUEST_IDENTIFICATION_INFO_FINISHED'

export function search() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.search,
      getState().ParticipationAuthorizationBase.search.filter,
      getState().ParticipationAuthorizationBase.search.pagination
    )
  }
}

export function identificationInfo() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_IDENTIFICATION_INFO, Effects.identificationInfo)
  }
}

export function setCurrentData(data) {
  return ActionUtility.createAction(SET_CURRENT_DATA, data)
}

export function create(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      Effects.create,
      {
        notification: {
          message: 'Nível/Categoria criado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      data
    )
  }
}

export function update(authorizationBaseId, data) {
  return async (dispatch, getState) => {
    ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effects.update,
      {
        notification: {
          description: null,
          message: 'Informações salvas com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      authorizationBaseId,
      data
    )
  }
}

export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)

export const clear = _ => ActionUtility.createAction(CLEAR)

export const clearFilter = _ => ActionUtility.createAction(CLEAR_FILTER)

export function setCurrent(item) {
  return ActionUtility.createAction(SET_CURRENT, item)
}
