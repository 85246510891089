import NotificationEnum from '../../../../constants/NotificationEnum'
import * as ActionUtility from '../../../../utilities/ActionUtility'
import * as ImportEffects from './ImportEffects'

export const REQUEST_IMPORT = 'CompanySubCategoryImport.REQUEST_IMPORT'
export const REQUEST_IMPORT_FINISHED = 'CompanySubCategoryImport.REQUEST_IMPORT_FINISHED'

export const REQUEST_SEARCH = 'CompanySubCategoryImport.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'CompanySubCategoryImport.REQUEST_SEARCH_FINISHED'

export const REQUEST_APPEND_IMPORT_DETAILS_BY_ID = 'CompanySubCategoryImport.REQUEST_APPEND_IMPORT_DETAILS_BY_ID'
export const REQUEST_APPEND_IMPORT_DETAILS_BY_ID_FINISHED = 'CompanySubCategoryImport.REQUEST_APPEND_IMPORT_DETAILS_BY_ID_FINISHED'

export const CLEAR = 'CompanySubCategoryImport.CLEAR'

export const SET_PAGINATION = 'CompanySubCategoryImport.SET_PAGINATION'
export const SET_FILTER = 'CompanySubCategoryImport.SET_FILTER'
export const CLEAR_FILTER = 'CompanySubCategoryImport.CLEAR_FILTER'
export const CLEAR_DETAIL_IMPORT_BY_ID = 'CompanySubCategoryImport.CLEAR_DETAIL_IMPORT_BY_ID'

export const importSubCategory = (file, categoryId) => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_IMPORT,
      ImportEffects.importEffect,
      {
        notification: {
          message: 'Arquivo importado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      { file, categoryId }
    )
  }
}

export const search = categoryId => {
  return async (dispatch, getState) => {
    const payload = { ...getState().company.subCategoryImport.search.request }
    // TODO: Melhorar esse status, pois no componente FilterList de importação de subcategoria, o status 0 não é gravado
    const filter = { ...payload.filter }
    filter.status = filter.status === 99999 ? 0 : filter.status
    payload.filter = filter
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH, ImportEffects.search, { payload, categoryId })
  }
}

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)
export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)
export const clearFilter = () => ActionUtility.createAction(CLEAR_FILTER)
export const clear = () => ActionUtility.createAction(CLEAR)
export const clearDetailImportById = id => ActionUtility.createAction(CLEAR_DETAIL_IMPORT_BY_ID, id)
