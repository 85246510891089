import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './CategoryActions'

export const initialState = {
  searchByNameResult: [],
}

const categoryReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_BY_NAME_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.searchByNameResult = payload
    })
  },
  [Actions.CLEAR_SEARCH_BY_NAME](state) {
    return produce(state, draftState => {
      draftState.searchByNameResult = initialState.searchByNameResult
    })
  },
})

export default categoryReducer
