import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './ParticipationAuthorizationActions'

export const initialState = {
  currentParticipationAuthorization: {
    participantIdentifier: null,
    authorizationBaseId: null,
    saleId: null,
    product: {
      manufacturerName: null,
      brandName: null,
      name: null,
      ncm: null,
      basePrice: 0,
      attributes: [
        {
          key: null,
          value: null,
        },
      ],
      type: null,
      code: null,
      chainId: null,
      category: null,
      active: true,
    },
    authorizationReference: null,
  },
  search: {
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    filter: {
      authorizationBaseId: null,
      participantIdentifier: null,
    },
  },
  searchResponse: {
    numberOfRows: 0,
    data: [],
  },
}

const ParticipationAuthorizationReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.searchResponse.data = payload.data
      draftState.searchResponse.numberOfRows = payload.numberOfRows
    })
  },
  [Actions.REQUEST_GET_BY_ID_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentParticipationAuthorization = payload
    })
  },
  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.filter = payload
    })
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.pagination = payload
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
  [Actions.CLEAR_FILTER](state) {
    return produce(state, draftState => {
      draftState.search.filter = initialState.search.filter
    })
  },
  [Actions.REQUEST_CREATE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentParticipationAuthorization.id = payload
    })
  },
  [Actions.SET_CURRENT](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentParticipationAuthorization = payload
    })
  },
})

export default ParticipationAuthorizationReducer
