import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

const feedbackMessagesEndpoints = environment.managerApi.endpoints.sale.feedbackMessages

export async function get(saleId) {
  return EffectUtility.get(feedbackMessagesEndpoints.get.replace(':saleId', saleId))
}

export async function update(saleId, feedbackMessages) {
  return EffectUtility.put(feedbackMessagesEndpoints.update.replace(':saleId', saleId), feedbackMessages)
}
