import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './ImportActions'

export const initialState = {
  search: {
    request: {
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
      filter: {
        name: null,
        status: null,
        startDateUTC: null,
        endDateUTC: null,
      },
    },
    response: {
      numberOfRows: 0,
      data: [],
    },
  },
}

const importReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.search.response = action.payload
    })
  },
  [Actions.SET_PAGINATION](state, action) {
    return produce(state, draftState => {
      draftState.search.request.pagination = action.payload
    })
  },
  [Actions.SET_FILTER](state, action) {
    return produce(state, draftState => {
      draftState.search.request.filter = action.payload
    })
  },
  [Actions.CLEAR_FILTER](state) {
    return produce(state, draftState => {
      draftState.search.request.filter = initialState.search.request.filter
    })
  },
  [Actions.CLEAR]() {
    return initialState
  },
  [Actions.CLEAR_DETAIL_IMPORT_BY_ID](state, action) {
    return produce(state, draftState => {
      draftState.details = draftState.details.filter(x => x.id !== action.payload)
    })
  },
})

export default importReducer
