import environment from 'environment'
import * as EffectUtility from '../../../utilities/EffectUtility'

const eligibleEndpoints = environment.managerApi.endpoints.sale.eligibles

export async function getParticipantSegments(saleId) {
  return EffectUtility.get(eligibleEndpoints.participants.get.replace(':saleId', `${saleId}`))
}

export async function updateParticipantSegments(saleId, data) {
  return EffectUtility.put(eligibleEndpoints.participants.update.replace(':saleId', `${saleId}`), data)
}

export async function getParticipationAuthorization(saleId) {
  return EffectUtility.get(eligibleEndpoints.participationAuthorization.get.replace(':saleId', `${saleId}`))
}

export async function updateParticipationAuthorization(saleId, data) {
  return EffectUtility.put(eligibleEndpoints.participationAuthorization.update.replace(':saleId', `${saleId}`), data)
}

export async function getPinCode(saleId) {
  return EffectUtility.get(eligibleEndpoints.pinCode.get.replace(':saleId', `${saleId}`))
}

export async function updatePinCode(saleId, data) {
  return EffectUtility.put(eligibleEndpoints.pinCode.update.replace(':saleId', `${saleId}`), data)
}
