import NotificationEnum from '../../constants/NotificationEnum'
import * as ActionUtility from '../../utilities/ActionUtility'
import { downloadFromBlob, getFileNameFromContentDisposition } from '../../utilities/FileDownloaderUtility'
import * as Effect from './CompanyEffects'
import * as NotificationActions from '../notification/NotificationActions'

export const REQUEST_CREATE_IMAGE = 'Company.REQUEST_CREATE_IMAGE'
export const REQUEST_CREATE_IMAGE_FINISHED = 'Company.REQUEST_CREATE_IMAGE_FINISHED'

export const REQUEST_UPDATE_IMAGE = 'Company.REQUEST_UPDATE_IMAGE'
export const REQUEST_UPDATE_IMAGE_FINISHED = 'Company.REQUEST_UPDATE_IMAGE_FINISHED'

export const REQUEST_DELETE_IMAGE = 'Company.REQUEST_DELETE_IMAGE'
export const REQUEST_DELETE_IMAGE_FINISHED = 'Company.REQUEST_DELETE_IMAGE_FINISHED'

export const REQUEST_SEARCH = 'Company.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'Company.REQUEST_SEARCH_FINISHED'

export const REQUEST_GET_BY_ID = 'Company.REQUEST_GET_BY_ID'
export const REQUEST_GET_BY_ID_FINISHED = 'Company.REQUEST_GET_BY_ID_FINISHED'

export const REQUEST_UPDATE = 'Company.REQUEST_UPDATE'
export const REQUEST_UPDATE_FINISHED = 'Company.REQUEST_UPDATE_FINISHED'

export const REQUEST_CREATE = 'Company.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'Company.REQUEST_CREATE_FINISHED'

export const REQUEST_CHANGE_STATUS = 'Company.REQUEST_CHANGE_STATUS'
export const REQUEST_CHANGE_STATUS_FINISHED = 'Company.REQUEST_CHANGE_STATUS_FINISHED'

export const CLEAR_CURRENT_COMPANY = 'Company.CLEAR_CURRENT_COMPANY'

export const CLEAR = 'Company.CLEAR'

export const SET_PAGINATION = 'Company.SET_PAGINATION'
export const CLEAR_PAGINATION = 'Company.CLEAR_PAGINATION'

export const SET_FILTER = 'Company.SET_FILTER'
export const CLEAR_FILTER = 'Company.CLEAR_FILTER'

export const REQUEST_REPORT = 'Company.REQUEST_REPORT'
export const REQUEST_REPORT_FINISHED = 'Company.REQUEST_REPORT_FINISHED'

export const search = () => {
  return async (dispatch, getState) => {
    const payload = getState().company.company.search.request
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SEARCH, Effect.search, payload)
  }
}

export const getById = id => {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_BY_ID, Effect.getById, id)
  }
}

export const update = (payload, id) => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE,
      Effect.update,
      {
        notification: {
          message: 'Empresa alterada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      { payload, id }
    )
  }
}

export const create = payload => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CREATE,
      Effect.create,
      {
        notification: {
          message: 'Empresa criada com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      payload
    )
  }
}

export const changeStatus = (id, status, payload) => {
  return async dispatch => {
    await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_CHANGE_STATUS,
      Effect.changeStatus,
      {
        notification: {
          message: 'Status alterado com sucesso.',
          type: NotificationEnum.Success,
        },
      },
      { id, status, payload }
    )
  }
}

export const createImage = (id, image, tag) => {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_CREATE_IMAGE, Effect.createImage, { id, image, tag })
  }
}

export const updateImage = (id, image, tag, imageId) => {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_UPDATE_IMAGE, Effect.updateImage, { id, image, tag, imageId })
  }
}

export const deleteImage = (id, imageId) => {
  return async dispatch => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_DELETE_IMAGE, Effect.deleteImage, { id, imageId })
  }
}

export const downloadReport = filter => {
  return async dispatch => {
    dispatch(NotificationActions.add('baixando arquivo...', 'Aguarde', NotificationEnum.Success))

    const reportFile = await ActionUtility.createThunkEffect(dispatch, REQUEST_REPORT, Effect.getReport, filter)
    const fileName = getFileNameFromContentDisposition(reportFile.headers)
    await downloadFromBlob(new Blob([reportFile.data], { type: reportFile.headers['content-type'] }), fileName)
  }
}

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)
export const clearPagination = () => ActionUtility.createAction(CLEAR_PAGINATION)
export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)
export const clearFilter = () => ActionUtility.createAction(CLEAR_FILTER)
export const clearCompany = () => ActionUtility.createAction(CLEAR_CURRENT_COMPANY)
export const clear = () => ActionUtility.createAction(CLEAR)
