import NotificationEnum from '../constants/NotificationEnum'
import * as NotificationActions from '../stores/notification/NotificationActions'

const errorNotificationMiddleware = () => store => next => action => {
  if (action.error) {
    const errorAction = action

    next(NotificationActions.add(errorAction.payload.message, 'Ops !', NotificationEnum.Error))
  }

  next(action)
}

export default errorNotificationMiddleware
