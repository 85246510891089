import axios from 'axios'
import environment from 'environment'
import HttpErrorResponseModel from '../models/HttpErrorResponseModel'
import { mergeDeep } from './ObjectUtility'
import { ASSIGNED_TENANT_HEADER_KEY, ASSIGNED_TENANT_STORAGE_KEY } from '../constants/AssignedTenantConstants'

import { msalInstance } from '../providers/AppProviders'
import { loginRequest } from '../contexts/AuthConfig'
import * as AuthUtility from './AuthUtility'
import { ProfileTypeKeys } from '../constants/ProfileTypeEnum'

const RequestMethod = {
  Get: 'GET',
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
  Options: 'OPTIONS',
  Head: 'HEAD',
  Patch: 'PATCH',
}

const axiosInstance = axios.create()
axiosInstance.defaults.baseURL = environment.managerApi.baseUrl
axiosInstance.defaults.headers.common['Ocp-Apim-Subscription-Key'] = environment.managerApi.subscriptionKey
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'

axiosInstance.interceptors.request.use(async config => {
  const idTokenClaims = msalInstance?.getAllAccounts()[0]?.idTokenClaims

  const token = await getToken()

  if (token) config.headers.Authorization = `Bearer ${token}`

  const isAbertoProfile = idTokenClaims && idTokenClaims['extension_profile'] === ProfileTypeKeys.Aberto
  const assignedTenant = localStorage.getItem(ASSIGNED_TENANT_STORAGE_KEY)

  if (isAbertoProfile && assignedTenant) config.headers[ASSIGNED_TENANT_HEADER_KEY] = JSON.parse(assignedTenant).tenantId

  return config
})

export async function get(url, config) {
  return request(RequestMethod.Get, url, config)
}

/**
 * Performs a GET operation along with queryString parameters.
 *
 * @param {endpoint}     endpoint     Endpoint.
 * @param {config}       config  Configurations.
 * @param {queryParams}  queryParams  QueryString parameters: [{"key": "value"}].
 */
export async function getWithQuery(url, config, queryParams) {
  const queryString = queryParams
    .map(queryParam => `${queryParam.key}=${queryParam.value}`)
    .reduce((qs, param) => {
      if (qs === '') return param

      return `${qs}&${param}`
    }, '')

  return request(RequestMethod.get, `${url}?${queryString}`, config)
}

export async function post(url, data) {
  const params = {
    data,
  }
  return request(RequestMethod.Post, url, params)
}

export async function postDataWithoutTransformation(url, data) {
  const params = {
    data: data,
  }
  return request(RequestMethod.Post, url, params)
}

export async function postFormData(url, formData) {
  const params = {
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  return request(RequestMethod.Post, url, params)
}

export async function putFormData(url, formData) {
  const params = {
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  return request(RequestMethod.Put, url, params)
}

export async function put(url, data) {
  const params = {
    data: { ...data },
  }
  return request(RequestMethod.Put, url, params)
}

export async function patch(url, data) {
  const params = {
    data,
  }
  return request(RequestMethod.Patch, url, params)
}

export async function jsonPatch(url, data) {
  const params = {
    data,
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
  }
  return request(RequestMethod.Patch, url, params)
}

export async function del(url) {
  return request(RequestMethod.Delete, url)
}

export async function request(method, url, params) {
  if (!url) console.error(`Received ${url} which is invalid for a endpoint url`)
  try {
    const accessToken = await getToken()
    const config = mergeDeep(params ?? {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    const axiosRequestConfig = { method, url, ...config }
    const [axiosResponse] = await Promise.all([axiosInstance.request(axiosRequestConfig), delay()])
    const { status, request } = axiosResponse

    if (axiosResponse.data.success === false) {
      return fillInErrorWithDefaults(
        {
          status,
          message: axiosResponse.data,
          errors: axiosResponse.data,
          url: request ? request.responseURL : url,
          raw: axiosResponse,
        },
        { url }
      )
    }

    return {
      ...axiosResponse,
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      const { status, statusText, data } = error.response
      const errors = data.hasOwnProperty('message') ? [data.message] : [statusText]

      return fillInErrorWithDefaults(
        {
          status,
          message: errors.filter(Boolean).join(' - '),
          errors,
          url: error.request.responseURL,
          raw: error.response,
        },
        { url }
      )
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      const { status, statusText, responseURL } = error.request

      return fillInErrorWithDefaults(
        {
          status,
          message: statusText,
          errors: [statusText],
          url: responseURL,
          raw: error.request,
        },
        { url }
      )
    }

    // Something happened in setting up the request that triggered an Error
    return fillInErrorWithDefaults({
      status: 0,
      message: error.message,
      errors: [error.message],
      url: url,
      raw: error,
    })
  }
}

async function getToken() {
  try {
    return await AuthUtility.getToken()
  } catch (error) {
    msalInstance.loginRedirect(loginRequest)
  }
}

function fillInErrorWithDefaults(error, request) {
  const model = new HttpErrorResponseModel()

  model.status = error.status || 0
  model.message = error.message || 'Não foi possível completar sua requisição'
  model.errors = error.errors.length ? error.errors : ['Não foi possível completar sua requisição']
  model.url = error.url || request.url
  model.raw = error.raw

  model.errors = model.errors.filter(Boolean)

  return model
}

function delay(duration = 250) {
  return new Promise(resolve => setTimeout(resolve, duration))
}

export default axiosInstance
