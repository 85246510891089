import { produce } from 'immer'
import { SaleAttribute } from '../../../models/sale-attributes/sale-attributes'

import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SaleAttributesActions'

export const initialState = {
  required: false,
  rules: [],
} as SaleAttribute

const saleAttributesReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_FINISHED](state: SaleAttribute, { payload }: any) {
    return produce(state, draft => {
      draft.required = payload.required
      draft.rules = payload.rules
    })
  },
  [Actions.CLEAN]() {
    return initialState
  },
  [Actions.UPDATE_STATE](state: SaleAttribute, { payload }: any) {
    return produce(state, draft => {
      draft.required = payload.required
      draft.rules = payload.rules
    })
  },
})

export default saleAttributesReducer
