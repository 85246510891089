import * as ActionUtility from '../../utilities/ActionUtility'
import * as Effects from './ParticipantSegmentImportEffects'

export const REQUEST_SEARCH = 'ParticipantSegmentImportActions.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'ParticipantSegmentImportActions.REQUEST_SEARCH_FINISHED'

export const SET_FILTER = 'ParticipantSegmentImportActions.SET_FILTER'
export const SET_PAGINATION = 'ParticipantSegmentImportActions.SET_PAGINATION'

export const CLEAR_FILTER = 'ParticipantSegmentImportActions.CLEAR_FILTER'
export const CLEAR = 'ParticipantSegmentImportActions.CLEAR'

export const search = _ => {
    return async (dispatch, getState) => {
        await ActionUtility.createThunkEffect(
            dispatch,
            REQUEST_SEARCH,
            Effects.search,
            getState().participantSegmentImport.search.filter,
            getState().participantSegmentImport.search.pagination
        )
    }
}

export const setFilter = filter => ActionUtility.createAction(SET_FILTER, filter)

export const setPagination = pagination => ActionUtility.createAction(SET_PAGINATION, pagination)

export const clearFilter = _ => ActionUtility.createAction(CLEAR_FILTER)

export const clear = _ => ActionUtility.createAction(CLEAR)
