import environment from 'environment'
import { SaleAttribute } from '../../../models/sale-attributes/sale-attributes'
import * as EffectUtility from '../../../utilities/EffectUtility'

const endpoint = environment.managerApi.endpoints.sale.eligibles.attributes

const get = async (saleId: string) => {
  return await EffectUtility.get(endpoint.get.replace(':saleId', saleId))
}
const update = async (saleId: string, data: SaleAttribute) => {
  return await EffectUtility.put(endpoint.update.replace(':saleId', saleId), data)
}

export { get, update }
