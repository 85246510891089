import { produce } from 'immer'
import baseReducer from '../../utilities/BaseReducer'
import * as Actions from './ForbiddenWordsAction'

export const initialState = {
  search: {
    request: {
      pagination: {
        pageSize: 10,
        pageNumber: 1,
      },
      filter: {},
    },
    response: {
      numberOfRows: 0,
      data: [],
    },
  },
  currentWord: {},
}

const ForbiddenWordsReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.search.response = action.payload
    })
  },
  [Actions.REQUEST_ENABLE_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.enable = action.payload
    })
  },
  [Actions.REQUEST_DISABLE_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.disable = action.payload
    })
  },
  [Actions.REQUEST_EDIT_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.edit = action.payload
    })
  },
  [Actions.REQUEST_GETBYID_FINISHED](state, action) {
    return produce(state, draftState => {
      draftState.currentWord = action.payload
    })
  },
  [Actions.CLEAN_CURRENT_WORD](state) {
    return produce(state, draftState => {
      draftState.currentWord = initialState.currentWord
    })
  },
  [Actions.SET_PAGINATION](state, action) {
    return produce(state, draftState => {
      draftState.search.request.pagination = action.payload
    })
  },
})

export default ForbiddenWordsReducer
