import { ActionUtility } from '@aberto/react-common'
import NotificationEnum from '../../../constants/NotificationEnum'
import HttpErrorResponseModel from '../../../models/HttpErrorResponseModel'
import * as Effects from './SaleNotificationEffect'

export const REQUEST_GET_NOTIFICATION = 'SaleNotification.REQUEST_GET_NOTIFICATION'
export const REQUEST_GET_NOTIFICATION_FINISHED = 'SaleNotification.REQUEST_GET_NOTIFICATION_FINISHED'

export function getNotification(id) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_NOTIFICATION, Effects.getNotification, id)
  }
}

export const REQUEST_UPDATE_NOTIFICATION = 'SaleNotification.REQUEST_UPDATE_NOTIFICATION'
export const REQUEST_UPDATE_NOTIFICATION_FINISHED = 'SaleNotification.REQUEST_UPDATE_NOTIFICATION_FINISHED'

export function update(saleId, notifications) {
  return async (dispatch, getState) => {
    const model = await ActionUtility.createThunkEffectWithMeta(
      dispatch,
      REQUEST_UPDATE_NOTIFICATION,
      Effects.updateNotification,
      {
        notification: {
          description: null,
          message: 'Notificação atualizada com sucesso',
          type: NotificationEnum.Success,
        },
      },
      saleId,
      notifications
    )
    const isError = model instanceof HttpErrorResponseModel
  }
}

export const REQUEST_GET_NOTIFICATION_TEMPLATE = 'SaleNotification.REQUEST_GET_NOTIFICATION_TEMPLATE'
export const REQUEST_GET_NOTIFICATION_TEMPLATE_FINISHED = 'SaleNotification.REQUEST_GET_NOTIFICATION_TEMPLATE_FINISHED'

export function getNotificationTemplate(type) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_NOTIFICATION_TEMPLATE, Effects.getNotificationTemplate, type)
  }
}

export const REQUEST_GET_NOTIFICATION_PROVIDER = 'SaleNotification.REQUEST_GET_NOTIFICATION_PROVIDER'
export const REQUEST_GET_NOTIFICATION_PROVIDER_FINISHED = 'SaleNotification.REQUEST_GET_NOTIFICATION_PROVIDER_FINISHED'

export function getNotificationProvider(type) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_NOTIFICATION_PROVIDER, Effects.getNotificationProvider, type)
  }
}

export const REQUEST_GET_TEMPLATE_BY_ID = 'SaleNotification.REQUEST_GET_TEMPLATE_BY_ID'
export const REQUEST_GET_TEMPLATE_BY_ID_FINISHED = 'SaleNotification.REQUEST_GET_TEMPLATE_BY_ID_FINISHED'

export function getTemplateById(id) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_TEMPLATE_BY_ID, Effects.getTemplateById, id)
  }
}
