import { produce } from 'immer'
import baseReducer from '../../../utilities/BaseReducer'
import * as Actions from './SalePaymentActions'

export const initialState = {
  cardBin: {
    required: false,
    segmentIds: [],
  },
  entryMode: {
    required: false,
    options: [],
  },
  type: {
    required: false,
    options: [],
  },
}

const salePaymentReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      for (let key in payload) {
        draftState[key] = payload[key]
      }
    })
  },
})

export default salePaymentReducer
